import { useContext, useEffect } from 'react';
import '../Workflow.scss';
import './index.scss';
import Step from '../template'
import { iCommLoggerForm, Wizard } from '../../../state/workflowSlice';
import { EStepKey } from '../../../Logic/Wokflow/stepLogic';
import { WizardStepProps } from '../stepType';
import Dropdown from '../../Generic/Form/DropDown';
import { MisEntry, MisLookupValue } from '../../../interfaces/ActionStartResponse';
import TextBox from '../../Generic/Form/TextBox';
import { BsPaperclip } from 'react-icons/bs';
import Attachment from '../../CreateNoteForm/attachment';
import { createAttachmentDraft, deleteFile, saveDraftFile } from '../../../Logic/GenericComms/createNote';
import { WizardContext } from '../../../contexts/wizard-context';
import { ECommType } from '../../../constants/OtherMediumType';

export function CommunicationLogger(props: WizardStepProps) {

    const { wizard, setWizard, onNext, onBack } = useContext(WizardContext)!;

    const commLoggerOptions: MisLookupValue[] = [
        { id: ECommType.FAX, name: "Facsimile", order: 1 },
        { id: ECommType.LETTER, name: "Letter", order: 2 },
        { id: ECommType.MEETING, name: "Meeting", order: 3 },
        { id: ECommType.PHONE, name: "Phone", order: 4 },
        { id: ECommType.TASK, name: "Task", order: 5 }
    ]

    function handleChange<K extends keyof iCommLoggerForm>(key: K, newValue: iCommLoggerForm[K]) {
        setWizard(wiz => {
            const newWiz: Wizard = {
                ...wiz!,
                otherMediumControl: {
                    ...wiz?.otherMediumControl,
                    [key]: newValue
                }
            };
            return newWiz;
        });
    }

    async function handleFileChange(e: any) {

        if (e.target.files && e.target.files.length > 0) {
            if (wizard?.otherMediumControl?.DraftID === undefined || wizard!.otherMediumControl.DraftID === 0) {
                const dId: number = await createAttachmentDraft(props.EnquiryId);
                const fileId: number = await saveDraftFile(e.target.files[0], dId);
                setWizard(wiz => (
                    {
                        ...wiz!,
                        otherMediumControl: {
                            ...wiz!.otherMediumControl,
                            DraftID: dId,
                            attachments: wiz?.otherMediumControl?.attachments?.length ?
                                [...wiz.otherMediumControl.attachments, { partId: fileId, f: e.target.files[0] }] :
                                [{ partId: fileId, f: e.target.files[0] }]
                        }
                    }
                ));
            }
            else {
                const fileId: number = await saveDraftFile(e.target.files[0], wizard!.otherMediumControl.DraftID);
                setWizard(wiz => (
                    {
                        ...wiz!,
                        otherMediumControl: {
                            ...wiz?.otherMediumControl,
                            DraftID: wiz!.otherMediumControl?.DraftID,
                            attachments: wiz?.otherMediumControl?.attachments ?
                                [...wiz.otherMediumControl.attachments, { partId: fileId, f: e.target.files[0] }] :
                                [{ partId: fileId, f: e.target.files[0] }]
                        }
                    }
                ));
            }
        }
    }

    function deleteAttachmentPart(partId: number) {
        deleteFile(wizard?.otherMediumControl?.DraftID!, partId).then(() => {
            setWizard(wiz => (
                {
                    ...wiz!,
                    otherMediumControl: {
                        ...wiz?.otherMediumControl,
                        attachments: wiz
                            ?.otherMediumControl
                            ?.attachments
                            ?.filter((att) => att.partId !== partId)
                    }
                }
            ))
        })
    }

    useEffect(() => {
        const initLogger: iCommLoggerForm = {
            DraftID: undefined,
            Type: ECommType.PHONE,
            Subject: wizard.workflow.data.EnquirerDetails.Subject,
            To: wizard.workflow.data.EnquirerDetails.FullName,
            attachments: []
        }

        if (wizard.otherMediumControl === undefined) {
            setWizard(wiz => (
                {
                    ...wiz!,
                    otherMediumControl: initLogger
                }
            ))
        }
    }, [props.EnquiryId]);

    const nextFunc = () => {
        if (wizard.otherMediumControl?.Type === undefined) {
            alert("Please choose the type of communication.");
            return;
        }
        setWizard(wiz => (
            {
                ...wiz!,
                workflow: {
                    ...wiz!.workflow,
                    data: {
                        ...wiz!.workflow.data,
                        OtherMedium: wiz!.otherMediumControl
                    }
                }
            }
        ));
        onNext();
    }

    const backFunc = () => {
        setWizard(wiz => ({
            ...wiz!,
            workflow: {
                ...wiz!.workflow,
                data: {
                    ...wiz!.workflow.data,
                    OtherMedium: undefined
                }
            }
        }));
        onBack();
    }

    return (
        <Step
            title="Communication Logger"
            backF={backFunc}
            step={EStepKey.COMMUNICATION_LOGGER}
            nextF={nextFunc}
            EnquiryId={props.EnquiryId}
            Popped={props.Popped}
            FullWidth={props.FullWidth}
        >
            <div className="comm-logger-att-area">
                <div className="g-tab-selected g-default-tab-padding" style={{ marginLeft: "0px" }}>Note Composer</div>
                <label htmlFor="create-note-attachment" className="g-btn-icon-shadow"><i><BsPaperclip /></i></label>
                <input type="file" onChange={handleFileChange} className="d-none" name="attachment" id="create-note-attachment" />
            </div>
            <>{(wizard?.otherMediumControl?.attachments && wizard.otherMediumControl.attachments.length > 0) &&
                <div className="comm-logger-attachments">
                    {wizard.otherMediumControl.attachments?.map((att, i) => <Attachment
                        key={`comm-logger-${i}-att`}
                        attachment={att}
                        onAttDelete={(partId) => { deleteAttachmentPart(partId) }}
                    />)}
                </div>
            }</>
            <Dropdown
                MisField={{
                    id: 273284,
                    name: "communication-logger-priority-dropdown",
                    label: "Type",
                    defaultValue: ECommType.PHONE + "",
                    displayOrder: 0,
                    fieldType: "Dropdown",
                    lookupValues: commLoggerOptions
                }}
                MisEntry={{
                    fieldID: wizard!.otherMediumControl?.Type ?? 0,
                    value: commLoggerOptions.find(op => op.id === wizard!.otherMediumControl?.Type)?.id.toString() ?? ""
                }}
                returnFunc={(entry: MisEntry) =>
                    handleChange("Type",
                        entry.value !== undefined && entry.value !== "" ?
                            +entry.value :
                            undefined)
                }
                Valid={wizard!.otherMediumControl?.Type !== undefined}
                excludeQuickFind={true}
                firstCol={2}
            />
            <TextBox
                id={"communication-logger-subject"}
                label={"To"}
                value={wizard!.otherMediumControl?.To ?? ""}
                onChange={(value: string) => handleChange("To", value)}
                maxLength={50}
                firstCol={2}
            />
            <TextBox
                id={"communication-logger-subject"}
                label={"Subject"}
                value={wizard!.otherMediumControl?.Subject ?? ""}
                onChange={(value: string) => handleChange("Subject", value)}
                maxLength={50}
                firstCol={2}
            />
            <textarea
                className="comm-logger-text-body"
                onChange={(e) => handleChange("Body", e.target.value)}
                value={wizard!.otherMediumControl?.Body ?? ""}
            />
        </Step>
    );
}
