import { Fragment, useEffect, useState } from "react"
import Step from '../template'
import AvancedSearch from "../../AdvancedSearch";
import { BsChevronRight } from "react-icons/bs";
import { GetSearchResults } from "../../../Logic/Search";
import { GetMap } from "../../../utilities/localStore/calls";
import EnquiryWrapper from "./enquiryWrapper";
import Enquiries from "../../Enquiries";
import { SortEnquiriesParams, sortByField, sortOwner, sortStatus } from "../../../utilities/sort";
import { iEnquiry } from "../../../interfaces/iEnquiry";
import { AdvancedSearchPM } from "../../../interfaces/AdvancedSearch/AdvancedSearchPM";
import { EStepKey } from "../../../Logic/Wokflow/stepLogic";
import { EEnquiryAction } from "../../../interfaces/wizard";
import { WizardStepProps } from "../stepType";
import React from "react";
import { WizardContext } from "../../../contexts/wizard-context";

const Index = (props: WizardStepProps) => {
	const allEnquiries = GetMap("Enquiries");
    let {wizard, onNext, setWizard, onBack} = React.useContext(WizardContext)!;
    wizard = JSON.parse(JSON.stringify(wizard));
    const initialAccordions = [
		{id: 0, active: true, disabled: false},
		{id: 1, active: false, disabled: true}
	]

	const [accordions, setAccordions] = useState(initialAccordions);
	const [activeTab, setActiveTab] = useState(0);
	const [activeEnquiry, setActiveEnquiry] = useState(0);

	const [searchResults, setSearchResults] = useState<number[]>([]);

	let enSort: SortEnquiriesParams = {orderBy: null}
	const [sortBy, setSortBy] = useState<SortEnquiriesParams>(enSort)

	function openAccordion(accs: any[], id: number) {
		return accs.slice().map(acc => {
			if (acc.id === id) {
				return {...acc, active: true, disabled: false}
			}
			else {return {...acc, active: false}}
		});
	}

	const onSearch = async (query: AdvancedSearchPM) => {
        let results = await GetSearchResults(query);
		results = {...results, Results: results.Results.filter(v => v!==wizard.EnquiryId)}
		wizard.workflow.LinkToEnquiry.searchResults = results
		wizard.workflow.data.ExistingEnquiryID = 0

		setSearchResults(results.Results);
		setAccordions(accs => openAccordion(accs, 1));
		setActiveEnquiry(0);
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.workflow.LinkToEnquiry.searchResults = results;
            currentState.workflow.data.ExistingEnquiryID = 0
          return currentState;
        }) ;
    }

	function loadQuery() {
		return wizard.workflow.LinkToEnquiry.searchResults?.Query
    };

	function onSortButton(orderBy: string) {
		if (sortBy?.orderBy === orderBy) {
			enSort = {orderBy: orderBy, desc: !sortBy?.desc}
		}
		else enSort = {orderBy: orderBy}

		let enquiries: iEnquiry[] | undefined = searchResults?.map((id, i) => allEnquiries.get(id+"").Enquiry)
		if (enquiries !== undefined && enSort.orderBy) {
			if (enSort.orderBy === "owner") {
				enquiries = sortOwner(enquiries, enSort.desc)
			}
			else if (enSort.orderBy === "status") {
				enquiries = sortStatus(enquiries, enSort.desc)
			}
			else {
				enquiries = sortByField(enquiries, enSort.orderBy, enSort.desc)
			}
			const ids = enquiries.map(e => e.id)
			setSearchResults(ids);
		}
		setSortBy(enSort)
	}

	function onEnquiryClick(enquiryId: number) {
		wizard.workflow.data.ExistingEnquiryID = enquiryId;
		// SaveWorkflowToStorage(wizard);
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.workflow.data.ExistingEnquiryID = enquiryId;
          return currentState;
        }) ;
		setActiveEnquiry(enquiryId)
		setActiveTab(1)
	}

	function onEnquiryDoubleClick(enquiryId: number) {
		onEnquiryClick(enquiryId)
		nextFunc();
	}

	function persistQuery(persistQ: AdvancedSearchPM) {
		wizard.workflow.LinkToEnquiry.searchResults = {
			Query: persistQ,
			Results: searchResults,
		}
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.workflow.LinkToEnquiry.searchResults = {
                Query: persistQ,
                Results: searchResults,
            };
          return currentState;
        }) ;
    }

    function setInitialQuery(initQuery: AdvancedSearchPM) {
        initQuery.EmailAddress.Enabled = true;
        initQuery.EmailAddress.Value = wizard.workflow.data.EnquirerDetails.Email;

        initQuery.EnquirerName.Enabled = true;
        initQuery.EnquirerName.Value = wizard.workflow.data.EnquirerDetails.FullName;

        const enquiry: iEnquiry = allEnquiries.get(props.EnquiryId.toString()).Enquiry;
        initQuery.Mailbox.Enabled = true;
        initQuery.Mailbox.Value = enquiry.mailbox;
    }

	useEffect(() => {
 
		if (wizard.workflow.LinkToEnquiry.searchResults) {
			setSearchResults(wizard.workflow.LinkToEnquiry.searchResults.Results);
		}
		if (wizard.workflow.LinkToEnquiry.searchResults?.Results?.length) {
			setAccordions(accs => openAccordion(accs, 1));
		}
		
		setActiveEnquiry(wizard.workflow.data.ExistingEnquiryID ? wizard.workflow.data.ExistingEnquiryID : 0);
	
		return () => { }
	}, [props.EnquiryId])

	const nextFunc = () => {
		if (wizard.workflow.data.ExistingEnquiryID && wizard.workflow.data.ExistingEnquiryID > 0) {
			wizard.workflow.data.EnquiryAction = EEnquiryAction.LinkToExisting;
            setWizard(currentState=>{
                currentState = JSON.parse(JSON.stringify(currentState));
                currentState.workflow.data.EnquiryAction = EEnquiryAction.LinkToExisting;
              return currentState;
            }) ;
            onNext()
		}
		else {
			alert("Please choose an enquiry to link")
		}
	}

	const backFunc = () => {
		 onBack()
	}
 
 
	function handleFirstAccordion() {
		setAccordions(accs => openAccordion(accs, 0));
		setActiveEnquiry(0)
		setActiveTab(0)
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.workflow.data.ExistingEnquiryID = 0
          return currentState;
        }) ;
	}

	return (
		<Fragment>
			<Step
				title="Search for and Select an Enquiry"
				backF={backFunc}
				nextF={activeEnquiry > 0 ? nextFunc : undefined}
				step={EStepKey.LINK_TO_ENQUIRY_STEP}
				EnquiryId={props.EnquiryId}
                Popped = {props.Popped}
                FullWidth = {props.FullWidth}
			>
				<div className={"accord my-2" + (accordions.find(acc => acc.id===0)?.active  ? " active" : "")}>
					<div className="accord-head" onClick={handleFirstAccordion}>
						<i><BsChevronRight className="accord-arrow"/></i>
						<b className="required col-xs-6 mx-2">Search Enquiries</b>
					</div>
					<div className="accord-content">
						<div className="search-fields">
							<AvancedSearch
								onClose={() => { }}
								onSearch={onSearch}
								loadQuery={loadQuery}
								persistQuery={persistQuery}
                                queryInitInterceptor={setInitialQuery}
							/>
						</div>
					</div>
				</div>
				{!accordions.find(acc => acc.id===1)?.disabled && <div className={"accord my-2" + (accordions.find(acc => acc.id===1)?.active ? " active" : "")}>
					<div className="accord-head" onClick={() => setAccordions(accs => openAccordion(accs, 1))}>
						<i><BsChevronRight className="accord-arrow"/></i>
						<b className="required col-xs-6 mx-2">Search Results</b>
					</div>
					<div className="accord-content">
						<div className="enquiries-bar-nav">
							<div className="select-enq limit-text mb-2">Select the enquiry to link the communication to</div>

							<div className="buttons-sect">
								<button className={"g-default-tab-padding g-tab"+(activeTab===0 ? "-selected" : "")} onClick={() => setActiveTab(0)}>Enquiry List</button>
								{activeEnquiry > 0 && <button className={"g-default-tab-padding g-tab"+(activeTab===1 ? "-selected" : "")} onClick={()=>  setActiveTab(1)}>Selected Enquiry</button>}
							</div>
						</div>
						<div className="enquiries-result">
							{activeTab === 0 && <>
								{searchResults ? <Enquiries
									es={
										searchResults.map((id, i) => allEnquiries.get(id + "").Enquiry)
									}
									onSortButton={onSortButton}
									activeEnquiry={activeEnquiry}
									onEnquiryClick={onEnquiryClick}
									onEnquiryDoubleClick={onEnquiryDoubleClick}
									ownerHidden={false}
									sortBy={sortBy}
									hasActivateWiz={false}
									/> :
									<div style={{ padding: "5px 10px", fontSize: "9pt" }}>No results found</div>
								}
							</>}
							{activeTab === 1 && <>
							{activeEnquiry > 0 && <EnquiryWrapper
							FullWidth={false}
							enquiry={allEnquiries.get(activeEnquiry + "")!.Enquiry} />}
							</>}
						</div>
					</div>
				</div>}
			</Step>

 
		</Fragment>
	)
}

export default Index;