import './email.scss';
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from '../../state/store'
import Preview from './preview';
import Enquiry from '../Enquiries/enquiry/enquiry'
import { GetWorkflowFromStorage, Wizard } from '../../state/workflowSlice';
import { iEnquiry } from '../../interfaces/iEnquiry';
import { ToGenericComm } from '../../interfaces/iGenericComm';
import { useState } from 'react';
import React from 'react';
import { getLastCommunication, setLastCommunication } from '../../Logic/LastTouched/comm';
import { getSessionValue, saveSessionValue } from '../../utilities/localStore/calls';
import { setActiveEnquiry } from '../../state/activeEnquiry';
import { getEnquiryById } from '../../interfaces/enquiryList';
import { iLastTouched } from '../../interfaces/lastTouched';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../../error-boundary/errorFallback';
import { defaultReset, logErrorToService } from '../../error-boundary/logerror';

type switchProps = {
    FullWidth: boolean,
    enquiry: iEnquiry,
    interface?: string,
    hasActivateWiz: boolean
}

function Switch(props: switchProps) {

    const dispatch = useAppDispatch()

    function changeCommToView(commId: number) {
        setcommToView(ToGenericComm(
            props.enquiry.communications.find(x => x.id == commId)!
        ));
        setLastCommunication(props.enquiry.id, commId);
    }

    const [commToView, setcommToView] = useState(useSelector((state: RootState) => state.commToView));
    if (props.enquiry.communications?.find(x => x.id == commToView.id) == undefined && props.enquiry.communications?.length > 0) {
        setcommToView(ToGenericComm(props.enquiry.communications[0]));
    }


    const wizard = GetWorkflowFromStorage(props.enquiry?.id);
    let previewComponent = <Preview comm={commToView} FullWidth={props.FullWidth} enquirerName={props.enquiry?.enquirerName} activeEnquiryId={props.enquiry.id} />

    if (wizard != undefined) {
        let localAttachments = JSON.parse(localStorage.getItem("localAttachments") || '[]');
        let localMessage = localAttachments.find((x: { messageId: string; }) => parseInt(x.messageId) == wizard.lastCommunication?.id)

        if (localMessage != undefined) {
            let comm = ToGenericComm(wizard?.lastCommunication!);
            comm.body = localMessage.body;
            comm.attachments = localMessage.attachments;
            previewComponent = <Preview comm={comm} FullWidth={props.FullWidth} enquirerName={props.enquiry?.enquirerName} activeEnquiryId={props.enquiry.id} />
        }
    }


    React.useEffect(() => {
        if (!(props.enquiry.communications?.find(x => x.id == commToView.id) == undefined && props.enquiry.communications?.length > 0)) {
            const activelist: string[] = JSON.parse(getSessionValue("ActiveListEnquiries"))
            if (activelist.find(id => props.enquiry.id.toString() == id)) {
                if (Object.keys(props.enquiry).length > 0) {
                    let lastCommId = getLastCommunication(props.enquiry.id);
                    let lastComm = props.enquiry.communications.find(x => x.id == lastCommId)
                    if (lastCommId > 0 && lastComm != undefined) {
                        setcommToView(ToGenericComm(lastComm));
                    }
                    else if (lastCommId == 0) {
                        if (props.enquiry.communications?.length) {
                            lastCommId = props.enquiry.communications[0].id
                            lastComm = props.enquiry.communications[0]
                            setcommToView(ToGenericComm(lastComm))
                        }
                    }
                }
            }
            else {
                if (activelist?.length > 0) {
                    const enq: iEnquiry = getEnquiryById(+activelist[0]);
                    dispatch(setActiveEnquiry(+activelist[0]))
                    if (enq?.communications?.length > 0) {
                        const commT = enq?.communications?.[0]
                        if (commT) {
                            setcommToView(ToGenericComm(commT))
                        }
                    }
                }

                else {
                    let LastTouched: iLastTouched = JSON.parse(getSessionValue("LastTouched"))
                    let activeWizardEnqs: Wizard[] = JSON.parse(getSessionValue("Workflows"))

                    if (LastTouched.lastActiveListType == "Resolved") {
                        let updated = false
                        LastTouched.MyEnquiries.EnquiryLists = LastTouched.MyEnquiries.EnquiryLists.map(li => {
                            if (li.Name == "Resolved") {
                                setLastCommunication(li.EnquiryId, 0)
                                updated = true
                                return {
                                    ...li,
                                    EnquiryId: 0,
                                    CommId: 0
                                }
                            }
                            return li
                        })
                        if (updated) {
                            dispatch(setActiveEnquiry(0))
                        }
                        saveSessionValue("LastTouched", LastTouched)
                    }
                    else if (LastTouched.lastActiveListType == "Unresolved") {
                        let updated = false
                        LastTouched.MyEnquiries.EnquiryLists = LastTouched.MyEnquiries.EnquiryLists.map(li => {
                            if (li.Name == "Unresolved") {
                                setLastCommunication(li.EnquiryId, 0)
                                updated = true
                                return {
                                    ...li,
                                    EnquiryId: 0,
                                    CommId: 0
                                }
                            }
                            return li
                        })
                        if (updated) {
                            dispatch(setActiveEnquiry(0))
                        }
                        saveSessionValue("LastTouched", LastTouched)
                    }
                    else if (!isNaN(+LastTouched.lastActiveListType)) {
                        LastTouched.Queue.EnquiryLists = LastTouched.Queue.EnquiryLists.map(li => {
                            if (li.Name == LastTouched.Queue.ActiveList && !(activeWizardEnqs.find(w => li.EnquiryId == w.EnquiryId))) {
                                setLastCommunication(li.EnquiryId, 0)
                                dispatch(setActiveEnquiry(0))
                                return {
                                    ...li,
                                    EnquiryId: 0,
                                    CommId: 0
                                }
                            }
                            return li
                        })
                        saveSessionValue("LastTouched", LastTouched)
                    }
                }
            }
        }
    }, [props.enquiry.id])

    return (
        <>
            {
                (Object.keys(props.enquiry).length > 0) &&
                <div className='enquiry-history'>

                    <div className='enquiry-history--enquiry'>
                        <ErrorBoundary
                            FallbackComponent={ErrorFallback}
                            onReset={defaultReset} 
                        >
                            <Enquiry
                                key={"key_" + props.enquiry?.id}
                                e={props.enquiry}
                                interface={props.interface}
                                commOnclick={(e: number) => { changeCommToView(e) }}
                                activeCommId={commToView.id}
                                hasActivateWiz={props.hasActivateWiz}
                            />
                        </ErrorBoundary>
                    </div>

                    {
                        props.enquiry.communications.length > 0 ? previewComponent : null
                    }
                </div>
            }
        </>
    )
}

export default Switch;