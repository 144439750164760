import { iPreference } from "../interfaces/preference"
let SidebarExpanded = false;
/**
 * Side effect called when vertical bar is dragged
 */
export function persistOnResize(amount: number) {
    const enquiryBurgerMenu = document.getElementById("enquiry-drpdown")
    if (!enquiryBurgerMenu) return
    enquiryBurgerMenu.style.left = (amount+45)+"px"
}
export function adjustHamburgerMenu(expanded: boolean) {
        SidebarExpanded = expanded;
        let amount: number;
        var enquiryBurgerMenu = document.getElementById("enquiry-drpdown")
        const preference: iPreference = JSON.parse(localStorage.getItem("preference") || "null")
        const percent = preference?.interface.LeftPane;
        const burgerParent = document.getElementById("MyEnquiriesHeader")
        const parentWidth = burgerParent?.offsetWidth!;
        amount = (percent / 100.0) * parentWidth + ((expanded) ? 176 : 28)
        if (!enquiryBurgerMenu) return
        enquiryBurgerMenu.style.left = amount + "px"    
}
export function burgerMenuMatchLeftPanePos() {
     
    const resize = document.getElementById("resize")!
    const enquiryBurgerMenu = document.getElementById("enquiry-drpdown")
    if (!enquiryBurgerMenu) return
    let amount: number;
    if (resize && resize.style.left) {
        amount = +resize.style.left.substring(0, resize.style.left.length-2);
    }
    else {
        const preference: iPreference = JSON.parse(localStorage.getItem("preference") || "null")
        const percent = preference?.interface.LeftPane;
        const paneParent = document.getElementById("mainPane")
        const burgerParent = document.getElementById("MyEnquiriesHeader")
        if (!paneParent) {
            enquiryBurgerMenu.style.position = "fixed"
            return
        }
        const parentWidth = burgerParent?.offsetWidth!;
        amount = (percent / 100.0) * parentWidth + ((SidebarExpanded) ? 176 : 28)
    }
    enquiryBurgerMenu.style.left = amount+"px"
}