 
import Tabs from '../../MyEnquiry/tabs';
import { Col, Row } from 'react-bootstrap';
import './index.scss';
import SearchQuery from '../../SearchResults/Query'
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import QueuesTopRow from '../../QueueLists/TopRow';
function SecondRow(props:any){
  const SiteInformation = useSelector((state: RootState) => state.SiteInformation);
return(
    <>
        <Row className="secondRow">
        {
        SiteInformation.queuesListVisible && <QueuesTopRow FullWidth={props.FullWidth}></QueuesTopRow>
      }

      {
        SiteInformation.enquiriesListVisible &&  <Tabs FullWidth={props.FullWidth}></Tabs>
      }
       {
        SiteInformation.searchResultsVisible && <SearchQuery FullWidth={props.FullWidth}></SearchQuery>
      }
        </Row>
      </>
)

};
export default SecondRow;