export interface AdvancedSearchRequest {
    EnquiryReference?: string | undefined;
    OtherReference?: string | undefined;
    OtherReferenceClause: SearchClause;
    EnquirerName?: string | undefined;
    EnquirerNameClause: SearchClause;
    EmailAddress?: string | undefined;
    EmailAddressClause: SearchClause;
    Subject?: string | undefined;
    SubjectClause: SearchClause;
    Body?: string | undefined;
    BodyClause: SearchClause;
    Owner?: number | undefined;
    EnquiryStatus?: number | undefined;
    Mailbox?: number | undefined;
    ReceivedAfter?: string | undefined;
    ReceivedBefore?: string | undefined;
    ReturnFirst: number;
}

export enum SearchClause {
    StartsWith = 1,
    Contains = 2
}