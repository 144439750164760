import { useContext } from 'react';
import { WizardContext } from '../../../contexts/wizard-context';
import { EStepKey } from '../../../Logic/Wokflow/stepLogic';
import { WizardStepProps } from '../stepType';
import Step from '../template'
import TextBox from '../../Generic/Form/TextBox';
import { BsPaperclip } from 'react-icons/bs';
import { createAttachmentDraft, deleteFile, saveDraftFile } from '../../../Logic/GenericComms/createNote';
import Attachment from '../../CreateNoteForm/attachment';
import { iExplanatoryNote, iExplanatoryForm } from '../../../interfaces/ExplanatoryNote';

export function ExplanatoryNote(props: WizardStepProps) {

    const { wizard, setWizard, onNext, onBack } = useContext(WizardContext)!;

    const nextFunc = () => {
        setExplanatoryNoteData({ ...wizard.explanatoryNoteControl });
        onNext();
    }

    const setExplanatoryNoteData = (note: iExplanatoryNote | undefined) => {
        setWizard(wiz => (
            {
                ...wiz,
                workflow: {
                    ...wiz.workflow,
                    data: {
                        ...wiz.workflow.data,
                        Note: note
                    }
                }
            }
        ))
    }

    function deleteAttachmentPart(partId: number) {
        deleteFile(wizard.explanatoryNoteControl?.DraftID!, partId).then(() => {
            setWizard(wiz => (
                {
                    ...wiz!,
                    explanatoryNoteControl: {
                        ...wiz.explanatoryNoteControl,
                        attachments: wiz
                            ?.explanatoryNoteControl
                            ?.attachments
                            ?.filter((att) => att.partId !== partId)
                    }
                }
            ))
        })
    }

    async function handleFileChange(e: any) {

        if (e.target.files && e.target.files.length > 0) {
            if (wizard.explanatoryNoteControl?.DraftID === undefined || wizard.explanatoryNoteControl.DraftID === 0) {
                const dId: number = await createAttachmentDraft(props.EnquiryId);
                const fileId: number = await saveDraftFile(e.target.files[0], dId);
                setWizard(wiz => (
                    {
                        ...wiz!,
                        explanatoryNoteControl: {
                            ...wiz.explanatoryNoteControl,
                            DraftID: dId,
                            attachments: wiz.explanatoryNoteControl?.attachments?.length ?
                                [...wiz.explanatoryNoteControl.attachments, { partId: fileId, f: e.target.files[0] }] :
                                [{ partId: fileId, f: e.target.files[0] }]
                        }
                    }
                ));
            }
            else {
                const fileId: number = await saveDraftFile(e.target.files[0], wizard.explanatoryNoteControl.DraftID);
                setWizard(wiz => (
                    {
                        ...wiz,
                        explanatoryNoteControl: {
                            ...wiz.explanatoryNoteControl,
                            DraftID: wiz.explanatoryNoteControl?.DraftID,
                            attachments: wiz.explanatoryNoteControl?.attachments ?
                                [...wiz.explanatoryNoteControl.attachments, { partId: fileId, f: e.target.files[0] }] :
                                [{ partId: fileId, f: e.target.files[0] }]
                        }
                    }
                ));
            }
        }
    }

    const setExplanatoryValue = <K extends keyof iExplanatoryForm>(key: K, newValue: iExplanatoryForm[K]) => {
        setWizard(wiz => (
            {
                ...wiz,
                explanatoryNoteControl: wiz.explanatoryNoteControl ? {
                    ...wiz.explanatoryNoteControl,
                    [key]: newValue
                } : {
                    [key]: newValue
                }
            }
        ));
    }

    const backFunc = () => {
        setExplanatoryNoteData(undefined);
        onBack();
    }

    return (
        <Step
            step={EStepKey.EXPLANATORY_NOTE}
            title={"Explanatory Note for Assigning Enquiry"}
            EnquiryId={props.EnquiryId}
            Popped={false}
            backF={backFunc}
            nextF={nextFunc}
        >
            <div className="comm-logger-att-area">
                <div className="g-tab-selected g-default-tab-padding" style={{ marginLeft: "0px" }}>Note Composer</div>
                <label htmlFor="create-note-attachment" className="g-btn-icon-shadow"><i><BsPaperclip /></i></label>
                <input type="file" onChange={handleFileChange} className="d-none" name="attachment" id="create-note-attachment" />
            </div>
            <>{(wizard.explanatoryNoteControl?.attachments && wizard.explanatoryNoteControl.attachments.length > 0) &&
                <div className="comm-logger-attachments">
                    {wizard.explanatoryNoteControl.attachments?.map((att, i) => <Attachment
                        key={`comm-logger-${i}-att`}
                        attachment={att}
                        onAttDelete={(partId) => { deleteAttachmentPart(partId) }}
                    />)}
                </div>
            }</>
            <TextBox
                id={"explanatory-note-subject"}
                label={"Subject"}
                value={wizard.explanatoryNoteControl?.Subject ?? ""}
                onChange={(newValue: string) => {
                    setExplanatoryValue('Subject', newValue);
                }}
                maxLength={50}
                firstCol={2}
            />
            <textarea
                className="comm-logger-text-body"
                onChange={(e) => {
                    setExplanatoryValue('Body', e.target.value);
                }}
                value={wizard.explanatoryNoteControl?.Body ?? ""}
            />
        </Step>
    )
}
