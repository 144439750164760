import { callApi, iRequest,Post,Get } from "../../utilities/apiCall";
import { iLastTouched } from "../../interfaces/lastTouched";
import { iQueue, iQueues, setQState } from "../../state/queueSlice";
import store, { RootState } from "../../state/store";
import { setEmailVisible } from "../../state/secondColSlice";
import { setQueuesVisibility } from "../../state/siteSlice";
import { switchFirstTab } from "../../state/tabRow";
import { setItemEnabled } from "../../utilities/loading";
import { sortComms, sortQueues } from "../../utilities/sort";
import { iCommunication } from "../../interfaces/iEnquiry";
import { saveEnquiryLists } from "../../interfaces/enquiryList";
import {  GetMap, SaveMap, saveSessionValue } from "../../utilities/localStore/calls";
import { setActiveEnquiry } from "../../state/activeEnquiry";
import { useSelector } from "react-redux";
import {AllocateEnquiriesRequest,PrioritiseEnquiriesRequest,RemoveEnquiriesRequest} from "../../interfaces/iMailbox";
import { iUser } from "../../interfaces/iUser";
export async function getQueuesInfo() {
    let iLastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
    if(iLastTouched.Queue.EnquiryLists.length==0){
        alert("no queues assigned to this user");
    }else{
        iLastTouched.Queue.ActiveList = iLastTouched.Queue.ActiveList==0? iLastTouched.Queue.EnquiryLists[0].Name: iLastTouched.Queue.ActiveList;
        iLastTouched.lastActiveListType = iLastTouched.Queue.ActiveList==0? iLastTouched.Queue.EnquiryLists[0].Name +"": iLastTouched.Queue.ActiveList + "";
        const result =  await getUnresolvedEnquiriesByQueueGlobal(iLastTouched.Queue.ActiveList).then((results) => {
                let EnquiryId =   iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList) ?
                (iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.EnquiryId == 0 ?
                    (results.enquiries.length > 0 ? results.enquiries.find((x: { communications: string | any[]; }) => x.communications.length > 0)!.id : 0) : iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.EnquiryId
                ) : iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.EnquiryId

                if (window.innerWidth > 1066) {
                    results.enquiries.length > 0 ? EnquiryId = EnquiryId : EnquiryId = 0;
                  }
                 
                if (EnquiryId > 0) {
                    store.dispatch(setEmailVisible(true));
                    if (iLastTouched.Queue.ActiveList > 0) {
                        iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.EnquiryId = EnquiryId;
                        iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.CommId = 0;
                    }
                }
               saveSessionValue("LastTouched",iLastTouched);
                store.dispatch(setActiveEnquiry(EnquiryId));
             store.dispatch(setQueuesVisibility());
                store.dispatch(switchFirstTab("Mailboxes"));
            });
    }
}

export async function getUnresolvedEnquiriesByQueueGlobal(mailboxId: number, id: string = ""): Promise<any> {
    setItemEnabled(id, false)
    var promise = new Promise<any>((resolve, reject) => {
        if (document.getElementById("loader")) {
            (document.getElementById("loader") as HTMLElement)!.style.display = "block";
        }
        let request: iRequest = {
            url: "Enquiries/Queue/" + mailboxId.toString(),
            method: "GET",
            headers: [],
            body: "",
            responseType: "json"
        }
        callApi(request).then((resp) => {
            let enQuiryMap = GetMap("Enquiries");
            let ActiveList: string[] = [];
            let response = sortQueues(resp.enquiries).map(
                (enq: any) => {
                    sortComms(enq.communications);
                    enQuiryMap.set(enq.id.toString(),{"CachedAt": new Date(), "Enquiry":enq});
                    ActiveList.push(enq.id.toString());
                    return enq
                });
            SaveMap("Enquiries",enQuiryMap);
            saveSessionValue("ActiveListEnquiries",ActiveList);
            setItemEnabled(id, true)
            resolve({"enquiries":response});
        }).catch((err) => {
            reject(err);
        });

    })
    return promise;
}

export function changeQ(item: iQueue) {
    let iLastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
    iLastTouched.Queue.ActiveList = item.id;
    sessionStorage.setItem("LastTouched",JSON.stringify(iLastTouched));
  
    if(document.getElementById("SelectedQ")!=undefined){(document.getElementById("SelectedQ") as HTMLElement).innerText = item.name}
    if(document.getElementById("SelectedQCount")!=undefined) {(document.getElementById("SelectedQCount") as HTMLElement).innerText = item.count.toString() };
    try {
        getUnresolvedEnquiriesByQueueGlobal(item.id).then((results)=>{
       
            store.dispatch(setActiveEnquiry(iLastTouched.Queue.EnquiryLists.find(x=>x.Name==item.id)?.EnquiryId==0?  
            results.enquiries[0].id! : iLastTouched.Queue.EnquiryLists.find(x=>x.Name==item.id)?.EnquiryId))

           
        })
       
    }
    catch (err) {
        console.log(err);
    }
}

export async function GetMailboxUsers(mailboxid: string): Promise<iUser[]> {
    const result: iUser[] = (await Get("Users?mailboxId=" + mailboxid +"&filterAssignable=true")).users;
    return result.sort((uA, uB) => {
        const aVal = `${uA.firstname} ${uA.lastname}`.toLowerCase();
        const bVal = `${uB.firstname} ${uB.lastname}`.toLowerCase();
        return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
    });
}