import { BsX } from "react-icons/bs";
import { iAttachment } from ".";
import { getFileIcon, getFormatFileSize } from "../Generic/file";

type props = {
    attachment: iAttachment,
    onAttDelete: (partId: number) => void
}
function Attachment(props: props) {
    
    return <div key={props.attachment.partId} className="note-attachment">
        {getFileIcon(props.attachment.f.type, props.attachment.f.name)}
        <div className="label limit-text">{`${props.attachment.f.name} (${getFormatFileSize(props.attachment.f.size)})`}</div>
        <i onClick={() => props.onAttDelete(props.attachment.partId!)} className="x"><BsX /></i>
    </div>
}

export default Attachment;