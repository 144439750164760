 
import { iLastTouched } from "../../interfaces/lastTouched";
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../state/store';
import { useEffect, useState } from 'react';
import { getEnquiryById } from '../../interfaces/enquiryList';
import { setTabVisibility } from '../../state/tabRow';
import { setWorkflowVisible } from '../../state/secondColSlice';
import { setShowEnquiry, setShowWizard } from '../../state/restrictedViewSlice';
import { getUnresolvedEnquiriesByQueueGlobal } from '../../Logic/Enquiries/Queue';
import { setActiveEnquiry } from '../../state/activeEnquiry';
import DropdownBasic from '../Generic/DropdownBasic';
import { iEnquiries } from '../../interfaces/iEnquiry';
import { ActivateWizardModule } from '../../Logic/Enquiries/activateWizard';
import { getSessionValue, saveSessionValue } from '../../utilities/localStore/calls';
import { resetSort } from '../../utilities/sort';
import { EnquiryBurgerMenu } from "../Menu";
import { burgerMenuMatchLeftPanePos } from "../../Logic/BurgerMenu";
import { useWindowResize } from "../../hooks/useWindowResize";
import { BsArrowClockwise } from 'react-icons/bs';
import { showHideLoader } from "../../utilities/loading";
import useInterval from "../../hooks/useInterval";
function TopRow(props:any){
    const dispatch = useAppDispatch()
    const tabState = useSelector((state: RootState) => state.tabRow.tabs).find(x=>x.active==true);
    const RestrictedWidthState = useSelector((state: RootState) => state.restrictedView);
    const activeEnquiry = useSelector((state: RootState) => state.activeEnquiry);
    const [enabled, setEnabled] = useState(true);
    
    let eNQ = getEnquiryById(tabState?.enquiryId!);

    let iLastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
    let Lists:string[] =  JSON.parse(getSessionValue("ActiveListEnquiries")) ;
    const [DropdownLabel,setDropDownLabel] = useState(iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.Label + ` (${Lists!.length})`);
    useInterval(() => refreshInterface(), 60 * 1000);
    function back() {
        dispatch(setTabVisibility(10.35));
            dispatch(setWorkflowVisible(false));
            dispatch(setShowEnquiry(false))
    }
    async function refreshInterface() {
        let iLastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
       
        const results = await getUnresolvedEnquiriesByQueueGlobal(iLastTouched.Queue.ActiveList);
        let EnquiryId = iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList) ?
            (iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.EnquiryId == 0 ?
                (results.enquiries.length > 0 ? results.enquiries.find((x: { communications: string | any[]; }) => x.communications.length > 0)!.id : 0) : iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.EnquiryId
            ) : iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.EnquiryId

        if (iLastTouched.Queue.ActiveList > 0) {
            iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.EnquiryId = EnquiryId;
            iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.CommId = 0;
        }
        saveSessionValue("LastTouched", iLastTouched);
        dispatch(setActiveEnquiry(0));           
    }
function changeQ(id: number,label:string) {
    resetSort()
    let iLastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
    iLastTouched.Queue.ActiveList = id;
    sessionStorage.setItem("LastTouched", JSON.stringify(iLastTouched));
    try {
        getUnresolvedEnquiriesByQueueGlobal(id).then((results: iEnquiries) => {

            let lastTouchedEnquiry = iLastTouched.Queue.EnquiryLists.find(x => x.Name === id)?.EnquiryId ?? 0;

            if (lastTouchedEnquiry === 0 && results.enquiries.length > 0) {
                lastTouchedEnquiry = results.enquiries[0].id;
            }
            else if (lastTouchedEnquiry !== 0 && results.enquiries.findIndex(x => x.id === lastTouchedEnquiry) === -1) {
                lastTouchedEnquiry = 0;
            }
            if (iLastTouched.Queue.ActiveList > 0) {
				iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.EnquiryId = lastTouchedEnquiry;
				iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.CommId = 0;
			}
            saveSessionValue("LastTouched",iLastTouched);
            dispatch(setActiveEnquiry(lastTouchedEnquiry));
            Lists =  JSON.parse(getSessionValue("ActiveListEnquiries")) ;
            setDropDownLabel(label + ` (${Lists!.length})`);
        })
    }
    catch (err) {
        console.log(err);
    }
}

async function displayActiveEnquiry(){
    if(activeEnquiry.id>11){
         await new ActivateWizardModule().showEnquiry(activeEnquiry.id);
    }
 }
    useWindowResize(burgerMenuMatchLeftPanePos)
    useEffect(burgerMenuMatchLeftPanePos, [])
useEffect(() => {
    
    async function retrieveData() {
        let iLastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
        const RetrieverDataProcess = async () => {
            const results = await getUnresolvedEnquiriesByQueueGlobal(iLastTouched.Queue.ActiveList);
            let EnquiryId =   iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList) ?
            (iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.EnquiryId == 0 ?
                (results.enquiries.length > 0 ? results.enquiries.find((x: { communications: string | any[]; }) => x.communications.length > 0)!.id : 0) : iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.EnquiryId
            ) : iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.EnquiryId

            if (iLastTouched.Queue.ActiveList > 0) {
				iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.EnquiryId = EnquiryId;
				iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.CommId = 0;
			}
            saveSessionValue("LastTouched",iLastTouched);
            dispatch(setActiveEnquiry(EnquiryId));
        }
        RetrieverDataProcess();
    };
    if(activeEnquiry.id==0 || activeEnquiry.id==-1){
        retrieveData()
    }
},[activeEnquiry.id]);


    
return(
    <>
          <div className="enquiries-navigation" id="MyEnquiriesHeader"> 
       {
    ( (props.FullWidth == false && RestrictedWidthState.showEnquiry==true) && tabState?.enquiryId! < 11) && 
            <>
            <div className="col-xs-5">
                <div className="enquiries-tabs">
                    
                
                </div>
            </div>
            <div style={{display:"flex", alignItems:"center"}} className="col-xs-7">
                    <button className="g-tab g-default-tab-padding" onClick={()=>{back()}}>Enquiry List</button>
                    <button className="g-tab-selected g-default-tab-padding" >Selected Enquiry</button>
                    <EnquiryBurgerMenu
                        key={"burger-menu-dropdown"}
                        enquiryId={activeEnquiry.id}
                    />
                </div>
            </>
   }
{
(activeEnquiry.id>11 &&  props.FullWidth==false && tabState?.enquiryId!>11) &&

      <>
      <div className="col-xs-5">
          <div className="enquiries-tabs">
              
          Enquiry Wizard - {eNQ.enquiryReference + " - " + eNQ.subject}
          </div>
      </div>
      <div className="col-xs-7">
          {RestrictedWidthState.showWizard &&<>
              <button className="g-tab g-default-tab-padding " onClick={()=>{dispatch(setShowWizard(false))}}>Selected Enquiry</button>
              <button className="g-tab-selected g-default-tab-padding" >Active Wizard</button></>
          }
          {!RestrictedWidthState.showWizard &&<>
              <button className="g-tab-selected g-default-tab-padding" >Selected Enquiry</button>
              <button className="g-tab g-default-tab-padding " onClick={()=>{dispatch(setShowWizard(true))}}>Active Wizard</button></>
          }
          </div>
      </>
   }
    {
           ( props.FullWidth==true || activeEnquiry.id<11 ||(props.FullWidth == false && !RestrictedWidthState.showEnquiry)) &&

                <div className="col-xs-5"  >
                        
                        <div className="queueRow" id="QueuesHeader">
                            <button className="mailbox-refresh-button"  title="Refresh" onClick={
                                async () => {
                                    if (enabled) {
                                        setEnabled(false);
                                        refreshInterface();
                                        setTimeout(() => {
                                            setEnabled(true);
                                        }, 5000);
                                    }
                                    else {
                                        showHideLoader("flex");
                                        setTimeout(() => {
                                            showHideLoader("none");
                                        }, 1000);
                                    }
                                }
                            } ><BsArrowClockwise style={{cursor: "pointer", rotate: "90deg", width: "14px", height: "14px" }} ></BsArrowClockwise></button>
                             <DropdownBasic id={22} label={DropdownLabel} >
                            {
                                iLastTouched.Queue.EnquiryLists.map((item,index)=>
                                <li key={item.Name}><button onClick={() => changeQ(item.Name, item.Label.replace(/ /g, "\u00a0"))}>{(item.Label.replace(/ /g, "\u00a0") )}</button></li>    
                            )}
                            </DropdownBasic>
                        </div>
            </div>
            }
                { 
                     (props.FullWidth==false && !RestrictedWidthState.showEnquiry)  &&
                       <div style={{display:"flex", alignItems:"center"}} className="col-xs-7" >
                            <button className="g-tab-selected g-default-tab-padding">Enquiry List</button>
                            <button className="g-tab g-default-tab-padding" onClick={()=>{displayActiveEnquiry()}}>Selected Enquiry</button>
                            <EnquiryBurgerMenu
                                key={"burger-menu-dropdown"}
                                enquiryId={activeEnquiry.id}
                            />
                        </div>
                }
            {props.FullWidth == true && <div id="enquiry-drpdown" style={{ position: "absolute" }}>
                <EnquiryBurgerMenu
                    key={"burger-menu-dropdown"}
                    enquiryId={activeEnquiry.id}
                />
            </div>}
                </div>
      </>
)

};
export default TopRow;

