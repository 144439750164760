import { AdvancedSearchPM } from "../interfaces/AdvancedSearch/AdvancedSearchPM";
import { AdvancedSearchRequest, SearchClause } from "../interfaces/AdvancedSearch/AdvancedSearchRequest";
import { AdvancedSearchResults } from "../interfaces/AdvancedSearch/AdvancedSearchResults";
import { SearchFieldHistory } from "../interfaces/AdvancedSearch/SearchFieldHistory";
import { SearchFieldPM } from "../interfaces/AdvancedSearch/SearchFieldPM";
import { iCommunication } from "../interfaces/iEnquiry";
import { iUser } from "../interfaces/iUser";
import { Post } from "../utilities/apiCall";
import { GetMap, SaveMap, getSessionValue, saveSessionValue } from "../utilities/localStore/calls";
import { sortComms } from "../utilities/sort";

export async function GetSearchResults(query: AdvancedSearchPM) {
    var request = MapToRequest(query);
    var response = await Post("Enquiries/AdvancedSearch", request);
    var searchResults: AdvancedSearchResults = {
        Query: query,
        Results: [],
    }
    if(response.enquiries.length>0){
        let enQuiryMap = GetMap("Enquiries");
        let SearchResultsList: number[] = [];
        response.enquiries.map((enq: any) => {
            sortComms(enq.communications);
            enQuiryMap.set(enq.id.toString(),{"CachedAt": new Date(), "Enquiry":enq});
            SearchResultsList.push(enq.id);
        })
        searchResults.Results = SearchResultsList;
        SaveMap("Enquiries",enQuiryMap);
    }
    return searchResults;
}

function MapToRequest(pm: AdvancedSearchPM): AdvancedSearchRequest {

    var request: AdvancedSearchRequest = {
        OtherReferenceClause: MapClause(pm.OtherReference.IsContains),
        EnquirerNameClause: MapClause(pm.EnquirerName.IsContains),
        EmailAddressClause: MapClause(pm.EmailAddress.IsContains),
        SubjectClause: MapClause(pm.Subject.IsContains),
        BodyClause: MapClause(pm.Body.IsContains),
        ReturnFirst: parseInt(pm.ReturnFirst.Value),
    };

    if (pm.EnquiryReference.Enabled) {
        request.EnquiryReference = pm.EnquiryReference.Value;
    }

    if (pm.OtherReference.Enabled) {
        request.OtherReference = pm.OtherReference.Value;
    }

    if (pm.EnquirerName.Enabled) {
        request.EnquirerName = pm.EnquirerName.Value;
    }

    if (pm.EmailAddress.Enabled) {
        request.EmailAddress = pm.EmailAddress.Value;
    }

    if (pm.Subject.Enabled) {
        request.Subject = pm.Subject.Value;
    }

    if (pm.Body.Enabled) {
        request.Body = pm.Body.Value;
    }

    if (pm.Owner.Enabled) {
        if (pm.Owner.Value?.id !== undefined && typeof pm.Owner.Value?.id === "number") {
            request.Owner = pm.Owner.Value.id;
        }
    }

    if (pm.EnquiryStatus.Enabled) {
        request.EnquiryStatus = parseInt(pm.EnquiryStatus.Value);
    }

    if (pm.Mailbox.Enabled) {
        request.Mailbox = parseInt(pm.Mailbox.Value);
    }

    if (pm.ReceivedAfter.Enabled) {
        request.ReceivedAfter = pm.ReceivedAfter.Value;
    }

    if (pm.ReceivedBefore.Enabled) {
        request.ReceivedBefore = pm.ReceivedBefore.Value;
    }

    return request;
}

function MapClause(isContains: boolean | undefined): SearchClause {
    return isContains ? SearchClause.Contains : SearchClause.StartsWith;
}

function GetNewSearchFieldHistory(): SearchFieldHistory {
    return {
        EnquiryReference: new Array<string>(),
        OtherReference: new Array<string>(),
        EnquirerName: new Array<string>(),
        EmailAddress: new Array<string>(),
        Subject: new Array<string>(),
        Body: new Array<string>(),
        Owner: new Array<iUser>(),
    };
}

export function GetSearchFieldHistory(): SearchFieldHistory {
    let history = JSON.parse(getSessionValue("searchFieldHistory"));

    if (history == undefined) {
        history = GetNewSearchFieldHistory();
    }

    return history;
}

export function UpdateSearchFieldHistory(pm: AdvancedSearchPM) {

    var history = GetSearchFieldHistory();

    UpdateFieldHistory(history.EnquiryReference, pm.EnquiryReference);
    UpdateFieldHistory(history.OtherReference, pm.OtherReference);
    UpdateFieldHistory(history.EnquirerName, pm.EnquirerName);
    UpdateFieldHistory(history.EmailAddress, pm.EmailAddress);
    UpdateFieldHistory(history.Subject, pm.Subject);
    UpdateFieldHistory(history.Body, pm.Body);
    
    UpdateOwnerHistory(history.Owner, pm.Owner.Value);

    saveSessionValue("searchFieldHistory", history);
}



function UpdateFieldHistory(history: string[], field: SearchFieldPM) {

    if (field.Value === undefined)
        return;

    let stringVal = field.Value as string;
    if (stringVal == undefined)
        return;

    if (stringVal.replace(/\s/g, '').length === 0) // is empty or white space
        return;
        
    const existingIndex = history.indexOf(stringVal);
    if (existingIndex > -1) {
        history.splice(existingIndex, 1);
    }

    history.splice(0, 0, stringVal);
}

function UpdateOwnerHistory(history: iUser[], value: iUser) {
    if (value === undefined) {
        return;
    }

    let existingIndex = history.findIndex(x => x.id === value.id);
    if (existingIndex > -1) {
        history.splice(existingIndex, 1);
    }

    history.splice(0, 0, value);
}