import { useSelector } from "react-redux";
import { RootState } from '../../state/store'
import Enquiry from '../Enquiries/index';
import './index.scss'
import { Col, Row } from "react-bootstrap";
import { getPreference, iPreference, savePrefence } from "../../interfaces/preference";
import { useContext, useEffect, useState } from "react";
import { iEnquiry } from "../../interfaces/iEnquiry";
import { GetMap, getSessionValue, saveSessionValue } from "../../utilities/localStore/calls";
import { iLastTouched } from "../../interfaces/lastTouched";
import { SortEnquiriesParams, sortOwner, sortStatus, sortByField } from "../../utilities/sort";
import { ActivateWizardModule } from "../../Logic/Enquiries/activateWizard";
import { GlobalModalContext } from "../../contexts/GlobalModalContext";

function MyEnquiries({FullWidth}:{ FullWidth:boolean}) {
    const [busyLoading ,setLoading]=useState(true);
    const [allEnquiries ,setAllenquiries]=useState<Array<iEnquiry>>([]);  
    const activeEnquiry = useSelector((state: RootState) => state.activeEnquiry);
    const showModal = useContext(GlobalModalContext);

    useEffect(() => {
        if((activeEnquiry.id>0 || activeEnquiry.id==-1) ){
            const allEnquiries:[] = JSON.parse(getSessionValue("ActiveListEnquiries"));
            const ActiveList = GetMap("Enquiries");
            if(allEnquiries!=null){
            let Enquiries;
             Enquiries = allEnquiries.map(
                (item, index) => {
                    let e:any = ActiveList.get(item);
                    return e.Enquiry;
                }
       
            );
            setAllenquiries(Enquiries);}
            setLoading(false);
     }
    },[activeEnquiry.id])
    let preference:iPreference =  getPreference();
    if(preference==null || preference== undefined){
        preference = {
                interface: {
                    MailboxMenuVisible:true,
                    MyEnquiriesQueueStatusVisible:false,
                    LeftPane:50,
                    RightPane:50
                }
            }
            savePrefence(preference);
    }

    const enqModule = new ActivateWizardModule();
    const onEnquiryClick = (id: number) => {
        enqModule.btClick(id, () => showModal({ type: "EnquiryLocked" }));
    }

    useEffect(() => {
        let LastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
        const activeList = LastTouched.MyEnquiries.ActiveList
        const lastSort = LastTouched.MyEnquiries.EnquiryLists.find(l => l.Name == activeList)?.orderBy!;
        const lastOrderBy = lastSort?.orderBy
        if (lastOrderBy) {
            if (lastOrderBy  == "owner") {
                setAllenquiries(es => sortOwner(es, lastSort.desc) )
            }
            else if (lastOrderBy == "status") {
                setAllenquiries(es => sortStatus(es, lastSort.desc) )
            }
            else {
                setAllenquiries(es => sortByField(es, lastOrderBy, lastSort.desc) )
            }
        }
        setSortBy(lastSort);
    }, [activeEnquiry.id])
    const keydownHandle = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event == null)
            return;
        if (event.key != "ArrowUp" && event.key != "ArrowDown")
            return;

        const activeEnquiryIndex = allEnquiries.findIndex(x => x.id === activeEnquiry.id);
        let nextEnquiry = 0;
        let nextEnquiryIndex;
        if (event.key == "ArrowUp") {
            nextEnquiryIndex = activeEnquiryIndex > 0 ? activeEnquiryIndex - 1 : activeEnquiryIndex;
        }
        else {
            nextEnquiryIndex = activeEnquiryIndex < allEnquiries.length - 1 ? activeEnquiryIndex + 1 : activeEnquiryIndex;
        }
        let listMenuHeaderDiv: HTMLElement = document.getElementById('listMenuHeader')!;
        listMenuHeaderDiv.scrollTop = nextEnquiryIndex * 10;
        nextEnquiry = allEnquiries[nextEnquiryIndex].id;
        if (activeEnquiry.id != nextEnquiry) {
            enqModule.click = 0;
            onEnquiryClick(nextEnquiry);
        }
    }
    let enSort: SortEnquiriesParams
    const [sortBy, setSortBy] = useState<SortEnquiriesParams>();
    const handleHeaderClick = (fieldName: string) => {
        let LastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
        const activeList = LastTouched.MyEnquiries.ActiveList
        const lastSort = LastTouched.MyEnquiries.EnquiryLists.find(l => l.Name == activeList)?.orderBy!;
        const lastOrderBy = lastSort?.orderBy

        if (lastOrderBy == fieldName) {
            LastTouched.MyEnquiries.EnquiryLists.find(l => l.Name == activeList)!.orderBy = {orderBy: fieldName, desc: !lastSort.desc}
            enSort = {orderBy: fieldName, desc: !lastSort.desc };
        }
        else {
            LastTouched.MyEnquiries.EnquiryLists.find(l => l.Name == activeList)!.orderBy = {orderBy: fieldName}
            enSort = {orderBy: fieldName };
        }

        const order = enSort?.orderBy;
        if (order) {
            if (order == "owner") {
                setAllenquiries(es => sortOwner(es, enSort.desc) )
            }
            else if (order == "status") {
                setAllenquiries(es => sortStatus(es, enSort.desc) )
            }
            else {
                setAllenquiries(es => sortByField(es, order, enSort.desc) )
            }
        }
        sessionStorage.setItem("LastTouched", JSON.stringify(LastTouched));
        setSortBy(enSort)
        const ids = allEnquiries.map(e => e.id+"")
        saveSessionValue("ActiveListEnquiries",ids);
    }

    return (
        <Row style={{margin:"0px",padding:"0px" ,width:"100%",}}>
            
            <Col className="enquiries-list"   style={{margin:"0px",padding:"0px", }} id="NewSecList">
            
                <div id="listMenuHeader" tabIndex={0} onKeyDown={keydownHandle} style={{margin:"0px",padding:"0px"}} className={preference.interface.MyEnquiriesQueueStatusVisible? "showMenuCol":"hideMenuCol"}>
                    <div   id="listMenu" >to be completed<br/>ted<br/>ted<br/>ted<br/>ted<br/>ted<br/>ted<br/>ted<br/></div>
                        {
                        (busyLoading == false && allEnquiries.length > 0) && <Enquiry
                            es={allEnquiries}
                            onSortButton={handleHeaderClick}
                            activeEnquiry={activeEnquiry.id}
                            ownerHidden={true}
                            onEnquiryClick={onEnquiryClick}
                            onEnquiryDoubleClick={onEnquiryClick}
                            sortBy={sortBy}
                            hasActivateWiz={true}
                        />
                        }
                        {
                          (busyLoading==false &&  allEnquiries.length==0) && <div style={{padding:"15px",fontSize:"9pt"}}>No Results</div>
                        }
                    </div>
             
            </Col>
        </Row>
    );
}

export default MyEnquiries;