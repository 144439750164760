import { Delete, callApi, iRequest } from "../utilities/apiCall"
import { GetMap, getSessionValue, saveSessionValue } from "../utilities/localStore/calls"
import { iEnquiry,iEnquiries } from "./iEnquiry"
import { iLastTouched } from "../interfaces/lastTouched";
import { setItemEnabled } from "../utilities/loading";
import { AdvancedSearchResults } from "./AdvancedSearch/AdvancedSearchResults";
import { getUnresolvedEnquiriesByQueueGlobal } from "../Logic/Enquiries/Queue";
import { getResolvedEnquiriesGlobal, getUnresolvedEnquiriesGlobal } from "../Logic/Enquiries/My";
import { GetSearchResults } from "../Logic/Search";
import { AdvancedSearchPM } from "./AdvancedSearch/AdvancedSearchPM";

export interface Enquiries {
    enquiries: Array<EnquiryList>
}

export interface EnquiryList {
    title: string,
    enquiries: Array<iEnquiry>
}

export const getEnquiryLists = (): Enquiries => {

    return JSON.parse(getSessionValue("EnquiryLists"))

}

export const getEnquiries = (): Array<iEnquiry> => {

    const allEnquiries = GetMap("Enquiries");
   return Array.from(allEnquiries.values()).map(x=> x.Enquiry);
 

}

export const getActiveEnquiries =(): iEnquiries =>{
 

        const ActiveList:[] = JSON.parse(getSessionValue("ActiveListEnquiries"));
        const allEnquiries = GetMap("Enquiries");
        let Enquiries = [];
        if(ActiveList){
         Enquiries = ActiveList.map(
            (item, index) => {
                let e:any = allEnquiries.get(item);
                return e.Enquiry;
            }
    
        );}
        return {"enquiries":Enquiries}
 
}

export   const  refreshList  = async (userId: number, updateState?:boolean): Promise<any> => {
    let iLastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
    const listName = iLastTouched.lastActiveListType
    if (listName == 'Unresolved') {
        return  await getUnresolvedEnquiriesGlobal(userId) ;
    }
    else if (listName == 'Resolved') {
        return await getResolvedEnquiriesGlobal(userId)
       
    }
    else if(listName == 'Results'){
        const sQuery:AdvancedSearchPM =  JSON.parse(getSessionValue("advancedSearchQuery")) ; 
        const results = await GetSearchResults(sQuery);
        saveSessionValue("advancedSearchResults", results);
        return results;
    }
    else if (!Number.isNaN(listName)) {
        return await getUnresolvedEnquiriesByQueueGlobal(Number(listName))
    }

    return new Promise<any>((resolve, reject) => reject());
}

export const getActiveList = (actList: string) => {
    let iLastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);

    if (actList == "Mailboxes") {
        return iLastTouched.Queue.ActiveList + ""
    }
    else if (actList == "My Enquiries") {
        return iLastTouched.MyEnquiries.ActiveList
    }
    return ""
}

export const getLastActiveList = () => {
    let iLastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
    const actList = iLastTouched.lastActiveListType
    if (actList == "Mailboxes") {
        return iLastTouched.Queue.ActiveList + ""
    }
    else if (actList == "My Enquiries") {
        return iLastTouched.MyEnquiries.ActiveList
    }
    return ""
}

export const getActiveEnquiryId = (queuesListVisible: boolean) => {
    const allEnquiries = getEnquiryLists();
    let iLastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
    let ActiveEnquiryId: number = 0;
    if (queuesListVisible == true) {
        if (iLastTouched.Queue.ActiveList > 0) {
            ActiveEnquiryId = iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.EnquiryId;
            if (ActiveEnquiryId == 0) {
                if (allEnquiries.enquiries.length > 0) {
                    ActiveEnquiryId = allEnquiries.enquiries.find(x => x.title != "Unresolved" && x.title != "resolved")?.enquiries.find(z => z.communications.length > 0)!.id!;
                }
            }
        }
    }
    else {
        if (iLastTouched.MyEnquiries.ActiveList == "Unresolved") {
            ActiveEnquiryId = iLastTouched.MyEnquiries.EnquiryLists.find(x => x.Name == "Unresolved")!.EnquiryId;
        }
        else if (iLastTouched.MyEnquiries.ActiveList == "Resolved") {
            ActiveEnquiryId = iLastTouched.MyEnquiries.EnquiryLists.find(x => x.Name == "Resolved")!.EnquiryId;
        }
    }
    return ActiveEnquiryId;
}

export const saveEnquiryLists = (data: EnquiryList) => {

    let allLists = getEnquiryLists();
    if (allLists == undefined) {
        allLists = {
            enquiries: [{
                title: data.title,
                enquiries: data.enquiries
            }
            ]
        }
    } else {
        if (allLists.enquiries.findIndex(x => x.title == data.title) > -1) {

            allLists.enquiries = allLists.enquiries.filter(x => x.title != data.title);
        }
        allLists.enquiries.push(data);

    }
    saveSessionValue("EnquiryLists", allLists);
}

export const addEnquiryToLocalList = (enquiry: iEnquiry, listName: string) => {
    // get list from storage
    let enquiryLists: Enquiries = getEnquiryLists()

    const listIndex = enquiryLists.enquiries.findIndex(list => list.title == listName)
    if (listIndex >= 0) {
        // filter the enquiries with enquiry
        let enquiryList = enquiryLists.enquiries[listIndex]
        enquiryList.enquiries = enquiryList.enquiries.filter(enq => enq.id != enquiry.id)
        enquiryList.enquiries.push(enquiry)
        // save to storage
        saveEnquiryLists( enquiryList )
    }
}



export async function activateEnquiryWizard(enquiryId: number, id: string = ""): Promise<any> {
    setItemEnabled(id, false)
    if (document.getElementById("loader")) {
        (document.getElementById("loader") as HTMLElement)!.style.display = "block";
    }
    var promise = new Promise<any>((resolve, reject) => {

        let request: iRequest = {
            url: `Enquiry/${enquiryId}/Action`,
            method: "POST",
            headers: [{ key: "Content-Type", value: "application/json" }],
            body: "{}",
            responseType: "json"
        }
        callApi(request).then((resp) => {
            setItemEnabled(id, true)

            resolve(resp);
        }).catch((err) => {
            reject(err);
            setItemEnabled(id, true)
        });

    })
    return promise;
}
export async function deactivateEnquiryWizard(enquiryId: number, id: string = ""): Promise<any> {
    setItemEnabled(id, false)
    if (document.getElementById("loader")) {
        (document.getElementById("loader") as HTMLElement)!.style.display = "block";
    }
    
    try {
        await Delete(`Enquiry/${enquiryId}/Action`);
    } catch { } // Do nothing as enquiry is probably no longer locked anyway.
    
    setItemEnabled(id, true);
}

export const getEnquiryById = (id: number): iEnquiry => {
    let Enquiry: iEnquiry = {} as iEnquiry;
    const ActiveList = GetMap("Enquiries");
    let e:any = ActiveList.get(id.toString());
    Enquiry = e? e.Enquiry: {}
    return Enquiry;
}

export const scrollToView = (elementId: string, scrollParentId: string) => {

    let elementContainer = document.getElementById(scrollParentId)!;
    let elementHtml: HTMLElement = document.getElementById(elementId)!;
    if (elementContainer != null && elementHtml != null) {
        if (!isWithinParentView(elementHtml, elementContainer)) {
            if (elementHtml.offsetTop > 400) {
                elementContainer.scrollTop = elementHtml.offsetTop - 400;
            }
            else {
                elementContainer.scrollTop = 0;
            }
        }
    }
}

const getMaxScroll = (container: HTMLElement) => {
    return container.scrollHeight - container.clientHeight;
}

export const isWithinParentView = (elt: HTMLElement, parent: HTMLElement) => {
    const eltRec = elt.getBoundingClientRect();
    const parRec = parent.getBoundingClientRect();

    const eltAbove = eltRec.bottom < parRec.top
    const eltBelow = eltRec.top > parRec.bottom

    return !(eltAbove || eltBelow)
}