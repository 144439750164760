 
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import './tabNavigations.scss';
import { Row } from 'react-bootstrap';
import Tab from './tab';
import { iTabRow } from '../../../state/tabRow';
function TabNavigations(props: any) {
    const tabsState = useSelector((state: RootState) => state.tabRow.tabs);
    const tabs = props.tabs ? props.tabs.map((item: iTabRow, i: number)=>  <Tab FullWidth={props.FullWidth} label={item.label} enquiryId={item.enquiryId} active={item.active} key={item.enquiryId}></Tab>  ) : tabsState.map((item,i)=>  <Tab FullWidth={props.FullWidth} label={item.label} enquiryId={item.enquiryId} active={item.active} key={item.enquiryId}></Tab>  )
    return (
        <>
          <Row className="tabRow">
            {tabs}
        </Row>
        </>
    )
};
export default TabNavigations;