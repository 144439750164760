import { useEffect, useRef, useState } from "react";
import ConfirmDialog from "../../Generic/ConfirmDialog";
import { FindDialogProps } from "../../Generic/FindDialog";
import { iEnquiry } from "../../../interfaces/iEnquiry";
import { ToGenericComm, iGenericComm } from "../../../interfaces/iGenericComm";
import Communication from "../../Enquiries/communication/communication";
import { startVDrag } from "../../../Logic/Resize/vertical";
import { BsExclamationLg, BsFileEarmark, BsPaperclip } from "react-icons/bs";
import Preview from "../../Email/preview";

type attachMessageProps = {
    onConfirm: (selectedValue: number[]) => void,
    onCancel: Function,
    Enquiry: iEnquiry;
}

function AttachMessage(props: attachMessageProps){
    const [HistoryList, setHistoryList] = useState<JSX.Element[]>([]);
    const [genCommToView, setGenCommToView] = useState<iGenericComm|undefined>(undefined);
    const [activeComm, setActiveComm] = useState<number>((props.Enquiry.communications.filter(x=> x.type=="Email")[0].id));
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        let HistoryList1;
        if (props.Enquiry.communications.length > 0) {
            HistoryList1 = props.Enquiry.communications.filter(x=>x.type=="Email").map(
                (item, index) =>
                    <Communication id={item.id} type={item.type} dateStamp={item.dateStamp} email={item.email} 
                    comm={ToGenericComm(item)}  enquieryId={props.Enquiry.id} isCompletedEnquiry={false} key={"com_" + item.id} 
                     onClick={()=>{setActiveComm((item.id!))}} activeCommId={activeComm}
                   />
            );
            var topP = document.getElementById("commListHistory")!;
            topP.style.overflowX = "auto"
            let sub = 120;
            switch(props.Enquiry.communications.length){
                case 1:
                    topP.style.height="25px";
                    sub= 25;
                break;
                case 2:
                    topP.style.height="48px";
                    sub= 48;
                break;
                case 3:
                    topP.style.height="72px";
                    sub= 72;
                break;
            }
      
            setHistoryList(HistoryList1);
            let comm = props.Enquiry.communications.find(x => x.id == activeComm)!;
            if(comm){ setGenCommToView(ToGenericComm(comm));}
        }
    }, [activeComm])
 

    const firstTabElement = useRef<HTMLInputElement>(null);
    const startVDragging =   (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        startVDrag(e, HistoryList.length)
    }

    const selectAllMessages=()=>{
        if(inputRef.current?.checked){
            setGenCommToView(undefined);
            setActiveComm(0);
        }else{
            let activeComId = (props.Enquiry.communications.filter(x=> x.type=="Email")[0].id)
            setActiveComm(activeComId);
            setGenCommToView(ToGenericComm(props.Enquiry.communications.find(x => x.id == activeComId)!));
        }
    }
const selectMessage=()=>{
    if(inputRef.current?.checked){
        let allComms = props.Enquiry.communications.filter(x=>x.type=="Email").map((item,index)=>{
            return item.email!.id!
        })
        props.onConfirm(allComms);
    }
    else{
        props.onConfirm([props.Enquiry.communications.find(x=>x.id==activeComm)!.email!.id!]);
    }
    
}
    return (
        <>
            <ConfirmDialog title="Insert Messages from Enquiry" onConfirm={() =>  {selectMessage()}} onCancel={() =>  {props.onCancel()}}>
                <div style={{width:"700px", maxHeight:"550px"}}>
                <span tabIndex={0} onFocus={() => firstTabElement.current?.focus()} />
                <table className="comm-list"   cellPadding={0} cellSpacing={0}>
                    <thead  className={HistoryList.length > 4 ? "tHeaddWithScroll comm-list--header" : "tHeadd comm-list--header"}>
                        <tr style={{ height: "100%", width:"100%" }} className="noScrollBar">
                                <th style={{ width: "15px", textAlign: "center",  paddingLeft: "4px" }}><i style={{width:"100%"}}><BsExclamationLg style={{height:"20px"}}/></i></th>
                                <th  style={{ width: "11px", textAlign: "center", padding:"0px",margin:"0px" }}><i style={{height:"20px" }}><BsFileEarmark style={{height:"20px"}}/></i></th>
                                <th  style={{ width: "15px", textAlign: "center", height: "20px", paddingRight: "4px" }}><i style={{width:"100%"}}><BsPaperclip style={{height:"20px"}}/></i></th>

                                <td  className="limit-text" style={{ width: "12%" }}>Received</td>
                                <td  className="limit-text" style={{ width: "20%" }}>From</td>
                                <td  className="limit-text" style={{ width: "20%" }}>To</td>
                                <td  className="limit-text" style={{ width: "35%" }}>Subject</td>
                                <td  className="limit-text" style={{ width: "7%", paddingRight: "10px", marginRight:"5px" }}>Size</td>
                            </tr>
                        </thead>
                    <tbody className='comm-list--list' data-testid='enquiry-communication-list1' id="commListHistory">
                        {HistoryList} 
                    </tbody>
              <tfoot>
                <tr style={{width:"100%"}}>
                    <td colSpan={8} className="details comm-expand-btn">Items: {props.Enquiry.communications.length}</td>
                </tr>
                <tr style={{height:"3px"}}>
                    <td colSpan={8} className={HistoryList.length > 4 ? "commResizeWait" : "commResize"} style={{height:"3px"}} onMouseDown={(event) => startVDragging(event)}></td>
                </tr>
                </tfoot>
        </table>
              </div>

              <div style={{width:"700px",height:"400px", overflow:"auto" ,marginTop:"5px",border:"1px solid #EDEDED"}}>
               {
                  genCommToView!=null && <Preview comm={genCommToView!} FullWidth={true} enquirerName={props.Enquiry.enquirerName} activeEnquiryId={props.Enquiry.id}/>
               } 
               </div>
                 <div style={{position:"absolute",marginTop:"15px"}}>
                   <label  className="g-label" ><input ref={inputRef} type="checkbox" className="g-Checkbox-default" style={{marginTop:"2px"}} onClick={selectAllMessages}/>  Select all messages</label>
                </div>
            </ConfirmDialog>
            <span tabIndex={0} onFocus={() => firstTabElement.current?.focus()} />
        </>
    );
}

export default AttachMessage