import { getValue, saveValue } from "../utilities/localStore/calls"


export interface iPreference{
    
    interface:{
        MailboxMenuVisible:boolean,
        MyEnquiriesQueueStatusVisible:boolean,
        LeftPane:number,
        RightPane:number
    }
}


export const getPreference = ():iPreference =>{

    let preference:iPreference =  JSON.parse(getValue("preference")) ;
    if(preference==null || preference== undefined){
        preference = {
                interface: {
                    MailboxMenuVisible:true,
                    MyEnquiriesQueueStatusVisible:false,
                    LeftPane:50,
                    RightPane:50
                }
            }
            savePrefence(preference);
    }

    return preference ;

}

export const savePrefence = (data: iPreference) =>{

    saveValue("preference",data);
}