import React from "react";
import Confirm from "../Generic/ConfirmDialog";
import FormNumberRow from '../../components/Generic/Form/Numeric/';
import { getEnquiryById } from '../../interfaces/enquiryList';
import {PrioritiseEnquiriesRequest} from "../../interfaces/iMailbox";
import { Post,Get } from "../../utilities/apiCall";
import { iEnquiry } from "../../interfaces/iEnquiry"
import { RootState, useAppDispatch } from '../../state/store'
 
export interface Props {
    enquiries: Array<iEnquiry>,
    onCancel: () => void,
    onCreated: () => void,
    onError: () => void
}
function ChangeEnquiryPriority(props: Props) {
    const [NewValue, SetNewValue] = React.useState((props.enquiries[0]).priority.toString());

function isValidWorkItem(): boolean
    {
        if ((+NewValue < 1) || (+NewValue>255)){
        return false;
         }
        return true;
    }
    function handleChange(value:string)
    {
        if (value!="")
        {
            SetNewValue(value);
        }
    }
    async function Submit()
    {
        if (NewValue != "") {
            let prioritiseEnquiriesRequest: PrioritiseEnquiriesRequest = {
                MailboxID: props.enquiries[0].mailbox,
                Priority: +NewValue,
                Enquiries: props.enquiries.map(enq=>enq.id)
            }
            await ChangeEnquiryPriorityResult(prioritiseEnquiriesRequest);
            props.onCreated();
        } 
    }  
    return (<>
       <Confirm
            title="Change Enquiry Priority"
            onCancel={props.onCancel}
            onConfirm={Submit}
            isValid={true}
        >
            <div style={{fontWeight:"bold"}}>Set the priority of the selected enquiries to:</div>
            <div style={{width:"80px",marginTop:"5px"}}>
            <FormNumberRow  label="" requiredIdentifier={true} value={props.enquiries[0].priority} isNotValid={!isValidWorkItem()}  onChange={(newValue) => { handleChange(newValue) }} key={"mis-field-Items"} id={"15"}  maxLength={3} min={1}></FormNumberRow>
            </div>
            <div>1 = Highest Priority</div>
            <div>255 = Lowest Priority</div>
        </Confirm>
         
    </>)
}

export default ChangeEnquiryPriority;
async function ChangeEnquiryPriorityResult(prioritisenquirydetails: PrioritiseEnquiriesRequest) {

    var response = await Post("Mailboxes/" + prioritisenquirydetails.MailboxID + "/Enquiries/Prioritise", prioritisenquirydetails);
    return response;
}