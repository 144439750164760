import { useSelector } from "react-redux";
import { iEnquiry } from '../../../interfaces/iEnquiry';
import './index.scss';
import { Row, Col } from "react-bootstrap";
import Sidebar from '../sidebar/sidebar'
import Topbar from '../topBar/topBar'
import DefaultView from './defaultLayout'
import WorkflowLayout from './workflowLayout'
import Tabs from '../tabRow/tabNavigations' 
import { RootState } from "../../../state/store";
import { getEnquiryById} from "../../../interfaces/enquiryList";
import { ErrorBoundary } from 'react-error-boundary'
import {ErrorFallback} from '../../../error-boundary/errorFallback'
import {defaultReset} from '../../../error-boundary/logerror'
function FullWidth() {
  const activeEnquiry = useSelector((state: RootState) => state.tabRow.tabs).find(i=> i.active==true);
  let enQ: iEnquiry = getEnquiryById(activeEnquiry?.enquiryId!);
   function Showmenu() {
    const box = document.querySelector('.NavItemsE') as HTMLElement | null;
    box?.classList.toggle("expanded");
    alert(111);
  }
 

  function closeAttach() {
    var allElements = document.querySelectorAll('*[id^="mAttach-"]');
    allElements.forEach((item) => { item.className = "MainAttachmentOptionsContainer invisible" });
  }


  
  return (

    <>
<div className="layout">
        <div className="aside">
        <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={defaultReset} 
                    >
          <Sidebar FullWidth={true}/>
          </ErrorBoundary>
 
        </div>
        <div className="main-layout">
          <div className="top-bar">
          <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={defaultReset} 
                    >
            <Topbar FullWidth={true} />
            </ErrorBoundary>
          </div>
          <div className="paddingForRows">
          <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={defaultReset} 
                    >
                <Tabs FullWidth={true}></Tabs>
                </ErrorBoundary>
          </div>
          <div className="main-content">
            {/* ----------main content---------- */}
            
            <div style={ {padding: "0px 0px 0px 12px"}}>
            {
                <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={defaultReset} 
                    >
                {(enQ==undefined || Object.keys(enQ).length==0) ? 
                    <DefaultView></DefaultView>     : 
                    <WorkflowLayout Enquiry={enQ} FullWidth={true} Popped={false} interface="tab"></WorkflowLayout>}
                    </ErrorBoundary>
            }
            </div>
          </div>
          
        
        </div>
      </div>

    </>
  );
}

export default FullWidth;