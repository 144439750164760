export const  saveValueAsync  = async (key:string, value:any): Promise<any> =>  {
    return new Promise<any>((resolve, reject) => {
        try{
            resolve(localStorage.setItem(key,JSON.stringify(value)))
        }
        catch(err:any){
                reject(err);
        }
    })

}


export const  getValueAsync  = async (key:string): Promise<any> =>  {
    return new Promise<any>((resolve, reject) => {
        try{
            resolve(localStorage.getItem(key))
        }
        catch(err:any){
                reject(err);
        }
    })

}

export const  getValue  =   (key:string):string => {
     return localStorage.getItem(key)!
}

export const  saveValue  =   (key:string, value:any) => {
    try{
        localStorage.setItem(key,JSON.stringify(value));
    }
    catch(error){
        alert(error);
        return false;
    }
}

export const  getSessionValue  =   (key:string):string => {
    return sessionStorage.getItem(key)!
}

export const removeSessionValue = (key: string): string => {
    const value = getSessionValue(key)
    sessionStorage.removeItem(key)
    return value
}

export const  saveSessionValue  =   (key:string, value:any) => {
   try{
    sessionStorage.setItem(key,JSON.stringify(value));
   }
   catch(error){
       alert(error);
       return false;
   }
}

export const SaveMap = (name:string, value: Map<any,any>)=>{
    saveSessionValue(name,Object.fromEntries(value));
}

export const GetMap = (name:string,)=>{
    const obj = JSON.parse(getSessionValue(name));
    return(obj? new Map(Object.entries(obj)): new Map());
}