import { useRef } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import { EnquiryMenuProps } from "../../interfaces/menu";
import { EnquiryMenuItems } from ".";

export interface Props extends EnquiryMenuProps {
    x: number,
    y: number,
    onHide: () => void
}

export function EnquiryContextMenu(props: Props) {

    const menuRef = useRef<HTMLDivElement>(null)
    useOutsideClick(menuRef, props.onHide);

    return (
        <div
            ref={menuRef}
            style={{position:"absolute", top: props.y, left: props.x }}
        >
            <EnquiryMenuItems onHide={props.onHide} enquiryId={props.enquiryId} />
        </div>
    )
}
