import CommPreview from '../../Email/switch'
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { iEnquiry } from '../../../interfaces/iEnquiry';
import { getEnquiries, getEnquiryById } from '../../../interfaces/enquiryList';
import { inWizard } from '../../../Logic/Enquiries/activateWizard';

function SecondCol() {
    const secondCol = useSelector((state: RootState) => state.secondCol);
	const activeEnquiry = useSelector((state: RootState) => state.activeEnquiry);
    let enq: iEnquiry = getEnquiryById(activeEnquiry.id);
    if (enq == undefined) {
        enq = getEnquiries()[0];
    }
    return (
 <>

        <div className="secondColContainer" id="secondColContainer">
        
            <div style={{ paddingRight: "2px", flex: "9 1 0", display: "flex", flexDirection: "column", height:"100%" }}>
                {(secondCol.emailVisible && Object.keys(enq).length > 0) ?
                    <div className="email-preview-col">
                            <CommPreview
                                FullWidth={true}
                                enquiry={enq}
                                hasActivateWiz={true}
                            />
                    </div>
                : null}
            </div>
        </div>    
        </>
    )

};
export default SecondCol;