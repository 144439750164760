import { createSlice } from '@reduxjs/toolkit'
import { iGenericComm } from '../interfaces/iGenericComm';

export const initialState: iGenericComm = {
    id: 0,
    type: "Email",
    from: "",
    to: "",
    subject: "",
    body: "",
    hasAttachments: false,
    attachments: [],
    date: "1999-10-21 21:05:06"
}

export const commSlice = createSlice({
    name: 'getComm',
    initialState,
    reducers: {
        setCommToView: (state, action) => {
            state.id = action.payload.id;
            state.type = action.payload.type;
            state.incoming = action.payload.incoming;
            state.from = action.payload.from;
            state.to = action.payload.to;
            state.cc = action.payload.cc;
            state.bcc = action.payload.bcc;
            state.priority = action.payload.priority;
            state.subject = action.payload.subject;
            state.body = action.payload.body;
            state.hasAttachments = action.payload.hasAttachments;
            state.attachments = action.payload.attachments;
            state.unread = action.payload.unread;
            state.unsent = action.payload.unsent;
            state.date = action.payload.date;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setCommToView } = commSlice.actions

export default commSlice