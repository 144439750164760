import './Workflow.scss';
import { StepProps } from './stepType'
import { ChevronRight } from 'react-feather';
import { useEffect, useRef, useState } from 'react';
import { closeConnection } from '../../utilities/userProcessing';
import {  Wizard, actionEnquiryProcess, setEnquiryId } from '../../state/workflowSlice';
import { removeNewEnquiryId } from '../../Logic/Enquiries/activateWizard';
import { refreshList } from '../../interfaces/enquiryList';
import { useAppDispatch } from '../../state/store';
import { removeTab } from '../../state/tabRow';
import { setEmailVisible, setWorkflowPopped, setWorkflowVisible } from '../../state/secondColSlice';
import { setShowEnquiry } from '../../state/restrictedViewSlice';
import Confirm from '../Generic/ConfirmDialog';
import { setActiveEnquiry } from '../../state/activeEnquiry';
import { iEmail } from '../../interfaces/emailModel';
import { deleteItem, deleteEnquiryMailDrafts, getItem } from '../../utilities/localStore/indexDBCalls';
import { UploadMailBody } from '../../Logic/Mail/parts';
import { setLastCommunication } from '../../Logic/LastTouched/comm';
import { iLastTouched } from '../../interfaces/lastTouched';
import { getSessionValue } from '../../utilities/localStore/calls';
import { getNextStep } from '../../Logic/Wokflow/stepLogic';
import { WizardContext } from '../../contexts/wizard-context';
import React from 'react';
import { deleteAttachmentDraft } from '../../Logic/GenericComms/createNote';

function Template(props: StepProps) {
    const dispatch = useAppDispatch()
    const startRef = useRef<HTMLButtonElement>(null)
    let {wizard} = React.useContext(WizardContext)!;
    wizard = JSON.parse(JSON.stringify(wizard));
    
    const cancelFunc = async () => {
       
        if (props.Popped == true) {
            showConfirmDialogue(wizard.EnquiryId,
                () => {
                    closeConnection(wizard?.workflow.data.EnquiryId).then((res) => {
                        window.opener.postMessage(
                            {
                                msg: "cancel",
                                EnquiryId: wizard?.workflow.data.EnquiryId
                            }, '*')
                        // remove other medium draft
                        if (wizard.otherMediumControl?.DraftID !== undefined && wizard.otherMediumControl?.DraftID !== 0) {
                            deleteAttachmentDraft(wizard.otherMediumControl?.DraftID)
                                .then(() => window.close());
                        }
                        else
                            window.close();
                    }).catch((err) => {
                        window.opener.postMessage(
                            {
                                msg: "cancel",
                                EnquiryId: wizard?.workflow.data.EnquiryId
                            }, '*')
                        window.close();
                    })
                    return;
                }, true);
        }
        else {
            showConfirmDialogue(wizard.EnquiryId,
                () => {
                    closeConnection(wizard?.workflow.data.EnquiryId).then(() => {
                        // remove other medium draft
                        if (wizard.otherMediumControl?.DraftID !== undefined && wizard.otherMediumControl?.DraftID !== 0) {
                            deleteAttachmentDraft(wizard.otherMediumControl?.DraftID);
                        }
                        removeNewEnquiryId(wizard?.workflow.data.EnquiryId)
                        refreshList(wizard.workflow.data.UserId).then(() => {
                            dispatch(setEnquiryId(1));
                            dispatch(removeTab(wizard?.workflow.data.EnquiryId));
                            dispatch(setWorkflowPopped(false));
                            dispatch(setWorkflowVisible(false));
                            if (props.FullWidth == false) { dispatch(setShowEnquiry(false)); }
                            return;
                        })
                    }).catch((err) => {
                        console.log(err)
                    })
                }, true);
        }
    };

    const [dialogueShown, setDialogueShown] = useState({
        enquiryId: 0,
        okFunc: Function(),
        isShown: false
    });
    const showConfirmDialogue = (enquiryId: number, okFunc: Function, isShown: boolean) => {
        setDialogueShown({
            enquiryId,
            okFunc,
            isShown
        })
    }


    const finishFunc = async () => {
        const wiz: Wizard = JSON.parse(JSON.stringify(wizard));

        for (let { action, draft } of wizard.mailDrafts) {
            if (draft) {
                const ed: iEmail = await getItem("MailDrafts", wizard?.workflow.data.EnquiryId +"_"+action);
                if (ed) {
                    await UploadMailBody(wizard.workflow.data.EnquiryId, ed.maildraftId, ed).then((parts) => {
                        wiz.workflow.data.Drafts.push(draft!);
                    });
                }
            }
        }

        actionEnquiryProcess(wiz.workflow.data, props.Popped).then((resp) => {
            setLastCommunication(wizard.EnquiryId, 0);
            deleteEnquiryMailDrafts(wizard?.workflow.data.EnquiryId);
            afterAction();
        }).catch(
            (err) => {
                alert("an error occured");
                console.log(err);
                (document.getElementById("loader") as HTMLElement).style.display = "none";
            }
        );
    }

    function afterAction() {
        dispatch(setShowEnquiry(false));
        let LastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
        let nextEnquiryId = 0;
        const ActiveList: [] = JSON.parse(getSessionValue("ActiveListEnquiries"));
        nextEnquiryId = ActiveList.length > 0 ? parseInt(ActiveList.at(0)!) : 0;
        if (LastTouched.lastActiveListType == "Unresolved") {
            LastTouched.MyEnquiries.EnquiryLists.find(x => x.Name == "Unresolved")!.EnquiryId = nextEnquiryId;
        }
        else if (LastTouched.lastActiveListType == "Resolved") {
            LastTouched.MyEnquiries.EnquiryLists.find(x => x.Name == "Resolved")!.EnquiryId = nextEnquiryId;
        } else if (LastTouched.lastActiveListType == "Results") {
            LastTouched.SearchResults.EnquiryLists.find(x => x.Name == "Results")!.EnquiryId = nextEnquiryId;
        }
        else if (!Number.isNaN(LastTouched.lastActiveListType)) {
            LastTouched.Queue.EnquiryLists.find(x => x.Name.toString() == LastTouched.lastActiveListType)!.EnquiryId = nextEnquiryId;
        }
        sessionStorage.setItem("LastTouched", JSON.stringify(LastTouched));
        dispatch(setActiveEnquiry(nextEnquiryId));
        dispatch(setEmailVisible(true));
        dispatch(removeTab(wizard?.workflow.data.EnquiryId));
        dispatch(setWorkflowVisible(false));
        if (props.FullWidth == false) { dispatch(setShowEnquiry(false)); }
        if (props.Popped) {
            window.opener.postMessage(
                {
                    msg: "afterAction",
                    EnquiryId: nextEnquiryId,
                    UserId: wizard?.workflow.data.UserId
                }, '*') // cancel will cause the interface to refresh
        }
    }

    return (
        <>
        <div className={`step activeStep active`} style={{ backgroundColor: "#fff" }}>
            <div className="step--header" >
                <i className='chevron'><ChevronRight className="Chevron"></ChevronRight></i>
                <div className="flex-fill enqTitle">
                    {props.title}<br />
                </div>

                <div className="buttonRow">
                    <span tabIndex={0} onFocus={() => startRef.current?.focus()} />
                    {props.backF !== undefined && <button ref={startRef} className="g-btn g-btn-alternate" type="button" onClick={props.backF}>
                        Back
                    </button>}

                    {props.nextF !== undefined && <button className="g-btn g-btn-action" type="button" onClick={props.nextF} >
                       Next
                    </button>}
                    {getNextStep(props.step, wizard) == null && <button className="g-btn g-btn-action" type="button" onClick={ finishFunc} >
                       Finish
                    </button>}
                    {<button className="g-btn g-btn-alternate" type="button" onClick={cancelFunc} >
                        <label>Cancel</label>
                    </button>}
                </div>

            </div>
            <div className={"show"} style={{ backgroundColor: "#fff" }}>
                <div style={{ padding: "10px", height:"100%", overflow:"hidden", display: "flex", flexDirection:"column", paddingLeft: "28px", backgroundColor: "#fff" }}>
                    {props.children}
                </div>
                <span tabIndex={0} onFocus={() => startRef.current?.focus()} />
            </div>
        </div>
        
        {dialogueShown.isShown && <Confirm
            title={"Warning"}
            onCancel={() => showConfirmDialogue(0, dialogueShown.okFunc, false)}
            onConfirm={() => dialogueShown.okFunc()}
            messageType={"warn"}
        >{<>All processing done in the wizard will be lost if you close it at this point.             Are you sure that you wish to close this enquiry?'</>}</Confirm>}</>

    );
}

export default Template;
