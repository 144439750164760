import { Fragment, useEffect, useState } from 'react';
import '../Workflow.scss';
import Step from '../template'
import { useAppDispatch } from '../../../state/store'
import {  iAlarmData, iAlarmControl } from '../../../state/workflowSlice'
import { EStepKey } from '../../../Logic/Wokflow/stepLogic';
import { WizardStepProps } from '../stepType';
import { updateTime, EDay, addDays, getNextWeekDateOnDay, toDate, toTime, ceilHours, toDateTime } from '../../../utilities/date';
import TextBox from '../../Generic/Form/TextBox';
import Time from '../../Generic/Form/Time';
import './index.scss';
import { AddHours } from '../../../Logic/DateTimeFormatters';
import { WizardContext } from '../../../contexts/wizard-context';
import React from 'react';

function Diarise(props: WizardStepProps) {

    let {wizard, onNext, setWizard, onBack} = React.useContext(WizardContext)!;
    wizard = JSON.parse(JSON.stringify(wizard));
    const [isQuicky, setisQuicky] = useState<boolean>();
    const [alarmEnabled, setAlarmEnabled] = useState(false);
    const [currentQuicky, setCurrentQuicky] = useState<EDay>();
    const [enquiryExpiry, setEnquiryExpiry] = useState("");
    const [alarmSettings, setAlarmSettings] = useState<iAlarmControl>({
        alarmAtDate: "",
        alarmAtTime: "",
        subject: ""
    });

    useEffect(() => {
        let exp: string;
        if (wizard.workflow.data.PendTo) {
            const Exp = new Date(wizard.workflow.data.PendTo)
            Exp.setSeconds(0, 0);
            exp = toDateTime(Exp + "");
        } else {
            exp = initEnquiryExpiry();
        }
        setEnquiryExpiry(exp);
        let sett: iAlarmControl;
        let enabled: boolean;
        if (wizard.newAlarm !== undefined && (!isNaN(new Date(wizard.newAlarm.alarmAtDate + "T" + wizard.newAlarm.alarmAtTime).valueOf()))) {
            sett = wizard.newAlarm;
            enabled = wizard.newAlarmControls?.alarmEnabled!
        }
        else {
            if (wizard.CurrentAlarm !== undefined) {
                enabled = wizard.CurrentAlarm.enabled;
                const Alarmat = new Date(wizard.CurrentAlarm.alarmAt)
                Alarmat.setSeconds(0, 0);
                const alarmat = toDateTime(Alarmat + "");
                sett = {
                    subject: wizard.CurrentAlarm.subject,
                    alarmAtDate: toDate(alarmat),
                    alarmAtTime: toTime(alarmat),
                    notes: wizard.CurrentAlarm.notes ?? ""
                }
            }
            else {
                enabled = false;
                const initDateTime = initReminder()
                sett = {
                    alarmAtDate: toDate(initDateTime),
                    alarmAtTime: toTime(initDateTime),
                    subject: wizard.workflow.data.EnquirerDetails.Subject,
                    notes: ""
                }
            }
        }
        setAlarmSettings(sett);
        setAlarmEnabled(enabled);
        const quicky = wizard.newAlarmControls?.isQuicky ?? false
        setisQuicky(quicky);
        setCurrentQuicky(wizard.newAlarmControls?.selectedQuicky);
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.newAlarmControls = {
                isQuicky: quicky,
                selectedQuicky: wizard.newAlarmControls?.selectedQuicky,
                alarmEnabled: wizard.newAlarmControls?.alarmEnabled ?? false
            }
            currentState.newAlarm = sett;
            currentState.workflow.data.PendTo = exp;
          return currentState;
        }) ;
    }, [props.EnquiryId]);

    const nextFunc = () => {
        const expiryDate = new Date(enquiryExpiry);
        if (isNaN(expiryDate.valueOf())) {
            alert("Enter a valid enquiry expiry date");
            return;
        }
        if (expiryDate <= new Date()) {
            alert("Expiry date must be in future");
            return;
        }

        if (alarmEnabled) {
            if (alarmSettings.subject && alarmSettings.subject.length > 50) {
                alert("Reminder subject must not exceed 50 characters.");
                return;
            }
            const alarmDateTime = new Date(alarmSettings.alarmAtDate + "T" + alarmSettings.alarmAtTime);
            if (isNaN(alarmDateTime.valueOf())) {
                alert("Enter a valid alarm reminder and time");
                return;
            }
            const newAlarm: iAlarmData = {
                alarmAt: alarmSettings.alarmAtDate + "T" + alarmSettings.alarmAtTime,
                subject: alarmSettings.subject,
                notes: alarmSettings.notes
            }
            if (new Date(newAlarm.alarmAt) <= new Date()) {
                alert("Reminder alarm must be in future.");
                return;
            }
            setWizard(currentState=>{
                    currentState = JSON.parse(JSON.stringify(currentState));
                    currentState.newAlarmControls!.alarmEnabled = alarmEnabled;
                    currentState.newAlarm = alarmSettings;
                    currentState.workflow.data.Alarm = newAlarm;
                    currentState.workflow.data.PendTo = enquiryExpiry;
              return currentState;
            }) ;
        }
        else {
            setWizard(currentState=>{
                currentState = JSON.parse(JSON.stringify(currentState));
                currentState.workflow.data.Alarm = undefined;
                return currentState;
            }) ;
        }
        onNext();
    }

    const backFunc = () => {
        onBack();
    }
   
    const handleAlarmCheckBox = (newValue: boolean) => {
        setAlarmEnabled(newValue);
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.newAlarmControls!.alarmEnabled = newValue;
            return currentState;
        }) ;
    }

    const handleSubjectChange = (newValue: string) => {
        setAlarmSettings(sett => ({ ...sett, subject: newValue }))
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.newAlarm!.subject = newValue;
            return currentState;
        }) ;
    }

    const handleNotesChange = (newValue: string) => {
        setAlarmSettings(sett => ({ ...sett, notes: newValue }))
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.newAlarm!.notes = newValue;
            return currentState;
        }) ;
    }

    const handleEpirationChange = (newValue: string) => {
        setEnquiryExpiry(newValue);
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.workflow.data.PendTo = newValue;
            return currentState;
        }) ;
    }

    function handleTimeReminderChange(newValue: string) {
        setAlarmSettings(sett => ({
            ...sett,
            alarmAtTime: newValue
        }));
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.newAlarm!.alarmAtTime = newValue;
            return currentState;
        }) ;
    }

    function handleDateReminderChange(newValue: string) {
        setAlarmSettings(sett => ({
            ...sett,
            alarmAtDate: newValue
        }))

        if (isQuicky || isQuicky === undefined) {
            setisQuicky(false);
        }

        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.newAlarm!.alarmAtDate = newValue;
            return currentState;
        }) ;
    }

    function handleQuickyDateChange(day: EDay): void {
        let today = new Date();
        today = updateTime(toTime(alarmSettings.alarmAtDate + "T" + alarmSettings.alarmAtTime), today + "");
        setCurrentQuicky(day);
        if (!isQuicky) {
            setisQuicky(true);
        }
        let newDate: Date;
        if (day === EDay.TOMMORROW) {
            newDate = addDays(1, today);
        }
        else {
            newDate = getNextWeekDateOnDay(day, today);
        }
        setAlarmSettings(sett => ({
            ...sett,
            alarmAtDate: toDate(newDate + "")
        }))

        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.newAlarmControls = {
                isQuicky: true,
                selectedQuicky: day,
                alarmEnabled
            }
            return currentState;
        }) ;
    }

    return (

        <Fragment>
            <Step
                title="Enquiry Expiry and Pend Alarm Settings"
                backF={backFunc}
                step={EStepKey.DIARISE}
                nextF={nextFunc}
                EnquiryId={props.EnquiryId}
                Popped = {props.Popped}
                FullWidth = {props.FullWidth}
            >
                <div className="row rowMarginControl" key='pend-enquiry-expiration'>
                    <div className="col-sm-3">
                        <label className='g-label'>
                            Enquiry Expiration
                        </label>
                    </div>
                    <div className="col-sm-9">
                        <input
                            type="datetime-local"
                            className="g-TextBox-default"
                            onChange={(e) => handleEpirationChange(e.target.value)}
                            value={enquiryExpiry}
                            step={60}
                        />
                    </div>
                </div>

                <div className="row rowMarginControl">
                    <div className='col-sm-9'>
                        <input
                            id={'expiry-has-set-reminder'}
                            type="checkbox"
                            onChange={(e) => handleAlarmCheckBox(e.currentTarget.checked)}
                            checked={alarmEnabled}
                            className='g-Checkbox-default'
                        />
                        <label htmlFor={'expiry-has-set-reminder'} className='g-label'>Set a Reminder Alarm</label>
                    </div>
                </div>

                {alarmEnabled && <div className='alarm-settings row'>
                    <div className="alarm-prompt col-sm-8 d-flex flex-column">
                        <TextBox
                            id={'alarm-prompt-text-row'}
                            value={alarmSettings.subject}
                            onChange={handleSubjectChange}
                            maxLength={50}
                            firstCol={0}
                        />
                        <textarea
                            className="g-TextArea-default"
                            style={{ minWidth: "auto", height: "286px" }}
                            onChange={(e) => handleNotesChange(e.target.value)}
                            value={alarmSettings?.notes}
                        />
                    </div>
                    <div className="date-prompt col-sm-4 d-flex flex-column">
                        <Time
                            id={'alarm-settings-time-prompt'}
                            value={alarmSettings.alarmAtTime ?? ""}
                            onChange={handleTimeReminderChange}
                            maxLength={50}
                            firstCol={0}
                        />

                        <div className="list-float">
                            <div className="d-flex list-float-label">
                                <input
                                    onChange={(e) => setisQuicky(!e.currentTarget.checked)}
                                    type="radio"
                                    name="alarm-settings-date-pick"
                                    id="alarm-settings-date-pick-custom"
                                    checked={isQuicky !== undefined && !isQuicky}
                                />
                                <label htmlFor="alarm-settings-date-pick-custom" className='mx-1'>Custom</label>
                            </div>
                            <div className="list-float-content">
                                <div className="row rowMarginControl" key='alarm-settings-date-prompt'>
                                    <div className="col-sm-12">
                                        <input
                                            type="date"
                                            className="g-TextBox-default"
                                            style={{ borderWidth: "0px" }}
                                            onChange={(e) => handleDateReminderChange(e.target.value)}
                                            value={alarmSettings.alarmAtDate}
                                            step={1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='list-float'>
                            <div className="d-flex list-float-label">
                                <input
                                    onChange={(e) => setisQuicky(e.currentTarget.checked)}
                                    type="radio"
                                    name="alarm-settings-date-pick"
                                    id="alarm-settings-date-pick-quickies"
                                    checked={isQuicky !== undefined && isQuicky}
                                />
                                <label htmlFor="alarm-settings-date-pick-quickies" className='mx-1'>Quick Days</label>
                            </div>

                            <div className="quickies list-float-content">
                                <div className="quicky">
                                    <input type="radio"
                                        name="alarm-settings-quickies-pick"
                                        id="alarm-settings-quickies-pick-tomorrow"
                                        onChange={() => handleQuickyDateChange(EDay.TOMMORROW)}
                                        checked={isQuicky === true && currentQuicky === EDay.TOMMORROW}
                                    />
                                    <label htmlFor="alarm-settings-quickies-pick-tomorrow" className='mx-1'>Tomorrow</label>
                                </div>
                                <div style={{ textDecoration: "underline", margin: "2px 10px" }}>Next Week</div>
                                <div className="quicky">
                                    <input
                                        type="radio"
                                        name="alarm-settings-quickies-pick"
                                        id="alarm-settings-quickies-pick-mon"
                                        onChange={() => handleQuickyDateChange(EDay.MONDAY)}
                                        checked={isQuicky === true && currentQuicky === EDay.MONDAY}
                                    />
                                    <label htmlFor="alarm-settings-quickies-pick-mon" className='mx-1'>Monday</label>
                                </div>
                                <div className="quicky">
                                    <input
                                        type="radio"
                                        name="alarm-settings-quickies-pick"
                                        id="alarm-settings-quickies-pick-tue"
                                        onChange={() => handleQuickyDateChange(EDay.TUESDAY)}
                                        checked={isQuicky === true && currentQuicky === EDay.TUESDAY}
                                    />
                                    <label htmlFor="alarm-settings-quickies-pick-tue" className='mx-1'>Tuesday</label>
                                </div>
                                <div className="quicky">
                                    <input
                                        type="radio"
                                        name="alarm-settings-quickies-pick"
                                        id="alarm-settings-quickies-pick-wed"
                                        onChange={() => handleQuickyDateChange(EDay.WEDNESDAY)}
                                        checked={isQuicky === true && currentQuicky === EDay.WEDNESDAY}
                                    />
                                    <label htmlFor="alarm-settings-quickies-pick-wed" className='mx-1'>Wednesday</label>
                                </div>
                                <div className="quicky">
                                    <input
                                        type="radio"
                                        name="alarm-settings-quickies-pick"
                                        id="alarm-settings-quickies-pick-thu"
                                        onChange={() => handleQuickyDateChange(EDay.THURSDAY)}
                                        checked={isQuicky === true && currentQuicky === EDay.THURSDAY}
                                    />
                                    <label htmlFor="alarm-settings-quickies-pick-thu" className='mx-1'>Thursday</label>
                                </div>
                                <div className="quicky">
                                    <input
                                        type="radio"
                                        name="alarm-settings-quickies-pick"
                                        id="alarm-settings-quickies-pick-fri"
                                        onChange={() => handleQuickyDateChange(EDay.FRIDAY)}
                                        checked={isQuicky === true && currentQuicky === EDay.FRIDAY}
                                    />
                                    <label htmlFor="alarm-settings-quickies-pick-fri" className='mx-1'>Friday</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </Step>
        </Fragment>
    );
}

function initEnquiryExpiry(): string {
    let expirationDate = new Date();
    expirationDate = addDays(1, expirationDate);
    expirationDate = ceilHours(expirationDate);
    return toDateTime(expirationDate + "");
}

function initReminder(): string {
    let reminder = new Date();
    reminder.setSeconds(0, 0);
    reminder = AddHours(reminder, 1);
    return toDateTime(reminder + "");
}

export default Diarise;
