import React from "react";
import Confirm from "../Generic/ConfirmDialog";
import { iUser } from "../../interfaces/iUser";
import FindDialog, { FindOption } from '../Generic/FindDialog';
import {RemoveEnquiriesRequest} from "../../interfaces/iMailbox";
import { useEffect} from "react";
import { getEnquiryById } from '../../interfaces/enquiryList';
import { Post } from "../../utilities/apiCall";
import { iEnquiry } from "../../interfaces/iEnquiry";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from '../../state/store'

export interface Props {
    enquiries: Array<iEnquiry>,
    onCancel: () => void,
    onCreated: () => void,
    onError: () => void,
    UserList?: iUser[] | undefined,
}

 function RemoveEnquiries(props: Props) {  
    const userID = useSelector((state: RootState) => state.UserInformation).userID;
    const dispatch = useAppDispatch();
    async function UpdateEnquiryStatus(){
        let removeEnquiriesRequest: RemoveEnquiriesRequest = {
                MailboxID: props.enquiries[0].mailbox,
                Enquiries: props.enquiries.map(enq => enq.id)
            }
             
      await RemoveEnquiriesResult(removeEnquiriesRequest);
      props.onCreated();
    }
    return (<><Confirm title="Warning"
        onCancel={() => props.onCancel()}
        onConfirm={() => { UpdateEnquiryStatus() }}
    >
        <>Are you sure you want to remove the enquiry/enquiries from the list?</>
        </Confirm> <></>
 </>
 )
}
export default RemoveEnquiries;

async function RemoveEnquiriesResult(removeenquiriesdetails: RemoveEnquiriesRequest) {
    var response = await Post("Mailboxes/" + removeenquiriesdetails.MailboxID + "/Enquiries/Remove", removeenquiriesdetails);
    return response;
}
