import { stringList } from "aws-sdk/clients/datapipeline";
import { iCommunication } from "./iEnquiry";
import { isNullOrWhitespace } from "../utilities/string";

export interface iGenericComm {
    id: number;
    type: string
    incoming?: boolean;
    from: string;
    to: string;
    cc?: string;
    bcc?: string;
    priority?: number;
    subject: string;
    body: string,
    size?: number | undefined;
    hasAttachments: boolean;
    attachments: Array<{ id: string, name: string, mimeType: string, size: number }>,
    unread?: boolean | undefined;
    unsent?: boolean | undefined;
    date: string;
}

export class mailComm implements iGenericComm {
    constructor(comm: iCommunication) {
        this.id = comm.id;
        this.type = comm.type;
        this.date = comm.dateStamp;
        this.incoming = comm.email?.incoming;
        this.from = (isNullOrWhitespace(comm.email!.from.name) ? comm.email!.from.address : comm.email!.from.name!)
        this.to = comm.email!.to.map((item)=>{
            return (isNullOrWhitespace(item.name) ? item.address: item.name)
        }
        ).join(", ") ;
        this.cc = comm.email!.cc.map((item)=>{
            return (isNullOrWhitespace(item.name) ? item.address: item.name)
        }
        ).join(", ") ;
        this.bcc = comm.email!.bcc.map((item)=>{
            return (isNullOrWhitespace(item.name) ? item.address: item.name)
        }
        ).join(", ") ;;
        this.priority = comm.email?.priority;
        this.subject = comm.email?.subject!
        this.body = comm.email?.body ?? "";
        this.size = comm.contentLength;
        this.hasAttachments = JSON.parse(comm.email?.hasAttachments ?? "false");
        this.attachments = [];
        this.unread = JSON.parse(comm.email?.unread ?? "");
        this.unsent = JSON.parse(comm.email?.unsent ?? "");
    }

    id: number;
    type: string;
    incoming?: boolean | undefined;
    from: string;
    to: string;
    cc?: string | undefined;
    bcc?: string | undefined;
    priority?: number | undefined;
    subject: string;
    body: string;
    size?: number | undefined;
    hasAttachments: boolean;
    attachments: { id: string; name: string; mimeType: string; size: number; }[];
    unread: boolean;
    unsent: boolean;
    date: string;
}

export class noteComm implements iGenericComm {
    constructor(comm: iCommunication) {
        this.id = comm.id;
        this.type = comm.type;
        this.date = comm.dateStamp;
        this.from = comm.note?.from?.firstname + " " + comm.note?.from?.lastname;
        this.to = comm.note?.to?.firstname + " " + comm.note?.to?.lastname;
        this.priority = comm.note?.priority;
        this.subject = comm.note?.subject ?? "";
        this.body = comm.note?.body?.replace(/(?:\r\n|\r|\n)/g, '<br />') ?? "";
        this.size = comm.contentLength;
        this.hasAttachments = comm.note?.hasAttachments ?? false;
        this.attachments = [];
    }

    id: number;
    type: string;
    from: string;
    to: string;
    priority?: number | undefined;
    subject: string;
    body: string;
    size?: number | undefined;
    hasAttachments: boolean;
    attachments: { id: string; name: string; mimeType: string; size: number; }[];
    date: string;

}

export class mediaComm implements iGenericComm {
    constructor(comm: iCommunication) {
        this.id = comm.id;
        this.type = comm.type;
        this.date = comm.dateStamp;
        this.from = comm.media?.from?.firstname + " " + comm.media?.from?.lastname;
        this.to = comm.media?.to ?? "";
        this.subject = comm.media?.subject ?? ""
        this.body = comm.media?.body?.replace(/(?:\r\n|\r|\n)/g, '<br />') ?? "";
        this.size = comm.contentLength;
        this.hasAttachments = comm.media?.hasAttachments ?? false;
        this.attachments = [];
    }

    id: number;
    type: string;
    from: string;
    to: string;
    subject: string;
    body: string;
    hasAttachments: boolean;
    size?: number | undefined;
    attachments: { id: string; name: string; mimeType: string; size: number; }[];
    date: string;

}

export function ToGenericComm(comm: iCommunication) {
    if (comm.type == "Email")
        return new mailComm(comm);

    if (comm.type == "Note")
        return new noteComm(comm);

    return new mediaComm(comm);
}