import { Key } from "react-feather";
import { getLastTouched, saveLastTouched } from "./misc";
import { getActiveEnquiries } from "../../interfaces/enquiryList";
import { GetMap, SaveMap } from "../../utilities/localStore/calls";



export const setLastCommunication = (EnquiryId:number, CommId: number, Position:number = 0)=>{
    let LastsComms = GetMap("LastTouchedComms");
    if(LastsComms.size==0){
        LastsComms.set(EnquiryId,[{"Id":CommId, "ScrollPosition":Position}]);
    }
    else if(LastsComms.size>0){
    let commList =  LastsComms.get(EnquiryId.toString());
        if(commList!=undefined){
         commList = commList.filter((x: { Id: number; })=> x.Id!=CommId) // put the comm at the begining of the list
         commList.unshift({"Id":CommId, "ScrollPosition":Position});
         LastsComms.set(EnquiryId,commList);
        }
        else{
            LastsComms.set(EnquiryId,[{"Id":CommId, "ScrollPosition":Position}]);
        }
    }
    SaveMap("LastTouchedComms",LastsComms);
 }

export const setLastCommunicationOld = (EnquiryId:number, CommId: number)=>{
   let iLastTouched = getLastTouched() ;
    Object.keys(iLastTouched).flat().forEach((item,index)=> {
    if(item!="lastActiveListType"){
    if(iLastTouched[item].EnquiryLists.find((x: any) => x.Name == iLastTouched.lastActiveListType)!=undefined){
        /*
        if(iLastTouched[item].EnquiryLists.find((x: any) => x.Name == iLastTouched.lastActiveListType)!.EnquiryId!=EnquiryId){
        
        }else{
            iLastTouched[item].EnquiryLists.find((x: any) => x.Name == iLastTouched.lastActiveListType).CommId = 0;
        }
*/
        iLastTouched[item].EnquiryLists.find((x: any) => x.Name == iLastTouched.lastActiveListType).CommId = CommId
     }
    }
  
    }) ;
    saveLastTouched(iLastTouched);
}


export const setLastCommunicationScrollPosition = (EnquiryId:number, CommId: number, Position:number)=>{
    let iLastTouched = getLastTouched() ;
     Object.keys(iLastTouched).flat().forEach((item,index)=> {
     if(item!="lastActiveListType"){
     if(iLastTouched[item].EnquiryLists.find((x: any) => x.Name == iLastTouched.lastActiveListType)!=undefined){
         if(iLastTouched[item].EnquiryLists.find((x: any) => x.Name == iLastTouched.lastActiveListType)!.EnquiryId==EnquiryId){
             iLastTouched[item].EnquiryLists.find((x: any) => x.Name == iLastTouched.lastActiveListType).scrollPosition =Position
         }else{
        
             iLastTouched[item].EnquiryLists.find((x: any) => x.Name == iLastTouched.lastActiveListType).scrollPosition = 0;
         }
      }
     }
   
     }) ;
     saveLastTouched(iLastTouched);
 }



export const getLastCommunication = (EnquiryId:number)=>{


    let LastsComms = GetMap("LastTouchedComms");
    if(LastsComms.size==0){
       return 0;
    }
    else if(LastsComms.size>0){
        let commList =  LastsComms.get(EnquiryId.toString())
        if(commList!=undefined){
            return commList[0].Id;
             
        }else{
            return 0;
        }
    
    }
 
}

export const getLastCommunicationOld = (EnquiryId:number)=>{
    let iLastTouched = getLastTouched() ;
    let CommId = 0;
    Object.keys(iLastTouched).flat().forEach((item,index)=> {
        if(item!="lastActiveListType"){
            iLastTouched[item].EnquiryLists.find((x: any)=>{
                if(x.EnquiryId == EnquiryId.toString()){
                    CommId = x.CommId?  x.CommId:0;
                    //return CommId;
                }
            });
    }});
// check to make sure that the comm in the active enquiry.
if(CommId>0){
    const allEnquiries = GetMap("Enquiries");
    const enq = allEnquiries.get(EnquiryId.toString())
    if(enq.Enquiry.communications.find((x: { id: number; })=> x.id==CommId)==undefined){
        CommId = 0;
    }
}

    return CommId
}

export const getLastCommunicationPosition = (EnquiryId:number)=>{
    let LastsComms = GetMap("LastTouchedComms");
    if(LastsComms.size==0){
       return 0;
    }
    else if(LastsComms.size>0){
        let commList =  LastsComms.get(EnquiryId.toString())
        if(commList!=undefined){
            return commList[0].ScrollPosition;
             
        }else{
            return 0;
        }
    
    }
 
}