
import Enquiry from '../Enquiries/index';
import { Col, Row } from "react-bootstrap";
import { AdvancedSearchResults } from "../../interfaces/AdvancedSearch/AdvancedSearchResults";
import { GetMap, getSessionValue, saveSessionValue } from '../../utilities/localStore/calls';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../state/store';
import { useContext, useEffect, useState } from 'react';
import { iLastTouched } from "../../interfaces/lastTouched";
import { setActiveEnquiry } from '../../state/activeEnquiry';
import { ActivateWizardModule } from '../../Logic/Enquiries/activateWizard';
import { SortEnquiriesParams, sortOwner, sortStatus, sortByField } from '../../utilities/sort';
import { GlobalModalContext } from '../../contexts/GlobalModalContext';
function Results(props: any) {
    const activeEnquiry = useSelector((state: RootState) => state.activeEnquiry);
    const dispatch = useAppDispatch();
    const showModal = useContext(GlobalModalContext);

    const reducedResults = (JSON.parse(getSessionValue("advancedSearchResults")!))
        ?.Results
        ?.map((x: number) => x.toString())
        ?.reduce((x: string, y: string) => x + "-" + y, "") ?? "";

    const [Enquiries, setEnquiries] = useState(new Array<any>())

    useEffect(()=>{
        if(activeEnquiry.id==0){
            let iLastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
            dispatch(setActiveEnquiry(iLastTouched.SearchResults.EnquiryLists[0].EnquiryId));
        }

    },[  activeEnquiry.id])

    useEffect( () => {
        const allEnquiries = GetMap("Enquiries");
        const searchResults: AdvancedSearchResults = JSON.parse(getSessionValue("advancedSearchResults")!);
        let enqs = (searchResults!= null && searchResults.Results.length > 0) ? searchResults.Results.map(
            (item: any) =>
                allEnquiries.get(item.toString()).Enquiry
        ) : []

        let LastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
        const lastSort = LastTouched.SearchResults.EnquiryLists[0].orderBy!;
        const lastOrderBy = lastSort?.orderBy

        if (lastOrderBy) {
            if (lastOrderBy == "owner") {
                enqs = sortOwner(enqs, enSort.desc);
            }
            else if (lastOrderBy == "status") {
                enqs = sortStatus(enqs, enSort.desc);
            }
            else {
                enqs = sortByField(enqs, lastOrderBy, enSort.desc);
            }
        }

        setSortBy(lastSort);
        setEnquiries(enqs);
        saveSessionValue("ActiveListEnquiries", enqs.map(enQ => enQ.id.toString()))
    }, [reducedResults]);

    const enqModule = new ActivateWizardModule();
    const onEnquiryClick = (id: number) => {
        enqModule.btClick(id, () => showModal({ type: "EnquiryLocked" }));
    }
    let LastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
    let enSort: SortEnquiriesParams = LastTouched.SearchResults.EnquiryLists[0].orderBy!;
    const [sortBy, setSortBy] = useState<SortEnquiriesParams>(enSort);
    
    const handleHeaderClick = (fieldName: string) => {
        let LastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
        let lastSort = LastTouched.SearchResults.EnquiryLists[0].orderBy!;
        const lastOrderBy = lastSort?.orderBy

        if (lastOrderBy == fieldName) {
            LastTouched.SearchResults.EnquiryLists[0].orderBy = {orderBy: fieldName, desc: !lastSort.desc}
            enSort = {orderBy: fieldName, desc: !lastSort.desc };
        }
        else {
            LastTouched.SearchResults.EnquiryLists[0].orderBy = {orderBy: fieldName}
            enSort = {orderBy: fieldName };
        }

        const order = enSort?.orderBy;
        if (order) {
            if (order == "owner") {
                setEnquiries(es => sortOwner(es, enSort.desc) )
            }
            else if (order == "status") {
                setEnquiries(es => sortStatus(es, enSort.desc) )
            }
            else {
                setEnquiries(es => sortByField(es, order, enSort.desc) )
            }
            LastTouched.SearchResults.EnquiryLists[0].orderBy = {orderBy: order, desc: enSort.desc}
        }
        sessionStorage.setItem("LastTouched", JSON.stringify(LastTouched));
        setSortBy(enSort)
    }

    return (
        <Row style={{margin:"0px",padding:"0px",width:"100%"}}>
            
            <Col className="enquiries-list"   style={{margin:"0px",padding:"0px"}} id="NewSecList">

            <Row style={{margin:"0px",padding:"0px",}}>
                    <Col className="col-md-12"  style={{margin:"0px",padding:"0px"}} >
                        {
                            Enquiries.length > 0 ? <Enquiry
                            es={Enquiries}
                            onSortButton={handleHeaderClick}
                            activeEnquiry={activeEnquiry.id}
                            onEnquiryClick={onEnquiryClick}
                            onEnquiryDoubleClick={onEnquiryClick}
                            ownerHidden={false}
                            sortBy={sortBy}
                            hasActivateWiz={true}
                            /> : <div style={{padding:"15px",fontSize:"9pt"}}>No results found</div>
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default Results;