import * as React from 'react';
import { useContext, useEffect } from 'react';
import { useAppDispatch, RootState } from '../../state/store'
import { useSelector } from "react-redux";
 
import { iLastTouched } from "../../interfaces/lastTouched";
import './Queues.css';
import Enquiry from '../Enquiries/index';
import { getActiveEnquiries, getActiveEnquiryId } from '../../interfaces/enquiryList';
import { iEnquiry } from '../../interfaces/iEnquiry';
import { setActiveEnquiry } from '../../state/activeEnquiry';
import { Col, Row } from 'react-bootstrap';
import { SortEnquiriesParams, sortByField, sortOwner, sortStatus } from '../../utilities/sort';
import { ActivateWizardModule } from '../../Logic/Enquiries/activateWizard';
import { saveSessionValue } from '../../utilities/localStore/calls';
import { GlobalModalContext } from '../../contexts/GlobalModalContext';
import { isDeepStrictEqual } from 'util';

function QueueLists(props:any) {
    const activeEnquiry = useSelector((state: RootState) => state.activeEnquiry);
    const dispatch = useAppDispatch()
    const [allEnquiries ,setAllenquiries]=React.useState<Array<iEnquiry>>([]);
    const User = useSelector((state: RootState) => state.UserInformation);
    const showModal = useContext(GlobalModalContext);

 
    useEffect(() => {
        setAllenquiries(getActiveEnquiries().enquiries);
            if((activeEnquiry.id<1 ) || props.FullWidth==false){
                let iLastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
                let lastEnquiryId = iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.EnquiryId == 0 ? 
                    (getActiveEnquiries().enquiries.length>0? getActiveEnquiries().enquiries[0].id : 0) :
                    getActiveEnquiries().enquiries.find(x=> x.id == iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.EnquiryId)!=undefined?
                    iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.EnquiryId : (getActiveEnquiries().enquiries.length>0? getActiveEnquiries().enquiries[0].id : 0)
                    dispatch(setActiveEnquiry(lastEnquiryId));
            }
    }, [activeEnquiry.id])
   
    const keydownHandle = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event == null)
            return;
        if (event.key != "ArrowUp" && event.key != "ArrowDown")
            return;

        const activeEnquiry = getActiveEnquiryId(true);
        const activeEnquiryIndex = allEnquiries.findIndex(x => x.id === activeEnquiry);
        let nextEnquiry = 0;
        let nextEnquiryIndex;
        if (event.key == "ArrowUp") {
            nextEnquiryIndex = activeEnquiryIndex > 0 ? activeEnquiryIndex - 1 : activeEnquiryIndex;
        }
        else {
            nextEnquiryIndex = activeEnquiryIndex < allEnquiries.length - 1 ? activeEnquiryIndex + 1 : activeEnquiryIndex;
        }
        nextEnquiry = allEnquiries[nextEnquiryIndex].id;
        let listMenuHeaderDiv: HTMLElement = document.getElementById('listMenuHeader')!;
        listMenuHeaderDiv.scrollTop = nextEnquiryIndex * 10;
        if (activeEnquiry != nextEnquiry) {
            setActiveEnquiry(nextEnquiry);
            onEnquiryClick(nextEnquiry);
        }        
    }
    
    const enqModule = new ActivateWizardModule();
    const onEnquiryClick = (id: number) => {
        enqModule.click = 0;
        enqModule.btClick(id, () => showModal({ type: "EnquiryLocked" }));
    }

    let enSort: SortEnquiriesParams
    const [sortBy, setSortBy] = React.useState<SortEnquiriesParams>();
    const handleHeaderClick = (fieldName: string) => {
        let LastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
        let lastSort = LastTouched.Queue.EnquiryLists.find(l => l.Name == LastTouched.Queue.ActiveList)?.orderBy!;
        const lastOrderBy = lastSort?.orderBy

        if (lastOrderBy == fieldName) {
            LastTouched.Queue.EnquiryLists.find(l => l.Name == LastTouched.Queue.ActiveList)!.orderBy = {orderBy: fieldName, desc: !lastSort.desc}
            enSort = {orderBy: fieldName, desc: !lastSort.desc };
        }
        else {
            LastTouched.Queue.EnquiryLists.find(l => l.Name == LastTouched.Queue.ActiveList)!.orderBy = {orderBy: fieldName}
            enSort = {orderBy: fieldName };
        }

        const order = enSort?.orderBy;
        if (order) {
            if (order == "owner") {
                setAllenquiries(es => sortOwner(es, enSort.desc) )
            }
            else if (order == "status") {
                setAllenquiries(es => sortStatus(es, enSort.desc) )
            }
            else {
                setAllenquiries(es => sortByField(es, order, enSort.desc) )
            }

            if (LastTouched.lastActiveListType == "Results") {
                LastTouched.SearchResults.EnquiryLists[0].orderBy = {orderBy: order, desc: enSort.desc}
            }
            const ids = allEnquiries.map(e => e.id+"")
            saveSessionValue("ActiveListEnquiries",ids);
        }
        sessionStorage.setItem("LastTouched", JSON.stringify(LastTouched));
        setSortBy(enSort)
    }

    useEffect(() => {
        document.getElementById("listMenuHeader")?.focus();
        let LastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
        let lastSort = LastTouched.Queue.EnquiryLists.find(l => l.Name == LastTouched.Queue.ActiveList)?.orderBy!;
        const lastOrderBy = lastSort?.orderBy

        if (lastOrderBy) {
            if (lastOrderBy  == "owner") {
                setAllenquiries(es => sortOwner(es, lastSort.desc) )
            }
            else if (lastOrderBy == "status") {
                setAllenquiries(es => sortStatus(es, lastSort.desc) )
            }
            else {
                setAllenquiries(es => sortByField(es, lastOrderBy, lastSort.desc) )
            }
        }
        setSortBy(lastSort);
    }, [activeEnquiry.id])

  
    return (
        <Row style={{margin:"0px",padding:"0px" ,width:"100%",}}>
            
        <Col className="enquiries-list"   style={{margin:"0px",padding:"0px", }} id="NewSecList">
                <div id="listMenuHeader" onKeyDown={keydownHandle}  style={{ margin: "0px", padding: "0px" }} tabIndex={0} className="hideMenuCol">
           
          
                    <Enquiry 
                es={allEnquiries}
                onSortButton={handleHeaderClick}
                activeEnquiry={activeEnquiry.id}
                onEnquiryClick={onEnquiryClick}
                onEnquiryDoubleClick={onEnquiryClick}
                ownerHidden={false}
                sortBy={sortBy}
                     hasActivateWiz={true}
                            
            />
            </div>
            </Col>
        </Row>
    );
}

export default QueueLists;
