import { ECommType } from "../../constants/OtherMediumType";
import Statuses from "../../interfaces/Statuses";
import { getEnquiryById } from "../../interfaces/enquiryList";
import { iEnquiry } from "../../interfaces/iEnquiry";
import { EEnquiryAction } from "../../interfaces/wizard";
import { Wizard } from "../../state/workflowSlice";
import { toAnalogueTime } from "../../utilities/date";
import { FormatDate } from "../DateTimeFormatters";
import { isNullOrWhitespace } from '../../utilities/string';
export enum EStepKey {
    ENQUIRY_TRACKER_STEP = "enquiryTracker",
    MIS_STEP = "MIS",
    ENQUIRY_ACTION_STEP = "enquiryAction",
    ENQUIRER_DETAILS_STEP = "enquirerDetails",
    RESPOND_BY_EMAIL_STEP = "respondByEmail",
    LINK_TO_ENQUIRY_STEP = "linkToEnquiry",
    CORRESPOND_BY_EMAIL_STEP = "correspondByEmail",
    WIZARD_SUMMARY_STEP = "finishStep",
    DIARISE = "diarise",
    FEEDBACK = "Feedback",
    INTERNAL_FEEDBACK = "internalFeedback",
    FOWARD_EMAIL = "forwardEmail",
    ESCALATE_EMAIL = "escalateEmail",
    COMMUNICATION_LOGGER = "communicationLogger",
    SELECT_EMAIL = "selectEmail",
    ASSIGN_OTHER_USER = "assignOtherUser",
    EXPLANATORY_NOTE = "explanatoryNote"
}

export interface iStep {
    key: EStepKey,
    title: string,
    getNext: (wizard: Wizard) => iStep | null,
    summarise: (wizard: Wizard) => string[]
}

export type TStep = {
    [stepKey in EStepKey]: iStep;
};

const STEPS: TStep = {
    [EStepKey.ENQUIRY_TRACKER_STEP]: {
        key: EStepKey.ENQUIRY_TRACKER_STEP,
        title: 'Enquiry Tracker',
        getNext: (wizard: Wizard) => {
            if (wizard.workflow.data.EnquiryTracker == "link to existing enquiry") {
                return STEPS.linkToEnquiry;
            }
            else if (wizard.workflow.data.EnquiryTracker === "assign enquiry to another mailbox user") {
                return STEPS.assignOtherUser;
            }
            else {
                return STEPS.enquirerDetails;
            }
        },
        summarise: (wizard: Wizard) => {
            const summary: string[] = [];

            if (wizard
                .workflow
                .data
                .EnquiryTracker === "new enquiry")
                summary.push("Create a new Enquiry.");
            // else if (wizard
            //     .workflow
            //     .data
            //     .EnquiryTracker === "assign enquiry to another mailbox user") // not in scope
            return summary;
        }
    },

    [EStepKey.ENQUIRER_DETAILS_STEP]: {
        key: EStepKey.ENQUIRER_DETAILS_STEP,
        title: 'Enquiry Details',
        getNext: (wizard: Wizard) => {
            return (wizard.MISModel &&
                wizard
                    .workflow
                    .data
                    .MISData
                    ?.length) ?
                STEPS.MIS :
                STEPS.enquiryAction;
        },
        summarise: (wizard: Wizard) => []
    },

    [EStepKey.MIS_STEP]: {
        key: EStepKey.MIS_STEP,
        title: 'MIS',
        getNext: (wizard: Wizard) => {
            return STEPS.enquiryAction;
        },
        summarise: (wizard: Wizard) => []
    },

    [EStepKey.ENQUIRY_ACTION_STEP]: {
        key: EStepKey.ENQUIRY_ACTION_STEP,
        title: 'Enquiry Action',
        getNext: (wizard: Wizard) => {
            let hasEmail = isNullOrWhitespace(
                wizard
                    .workflow
                    .data
                    .EnquirerDetails
                    .Email
            );
            switch (wizard.workflow.data.EnquiryAction) {
                case EEnquiryAction.RespondByEmail:
                    return STEPS.respondByEmail;
                case EEnquiryAction.CorrespondByEmail:
                    return STEPS.correspondByEmail;
                case EEnquiryAction.CorrespondByOtherMedium:
                case EEnquiryAction.RespondByOtherMedium:
                    return STEPS.communicationLogger;
                case EEnquiryAction.RequestInternalFeedback:
                    return wizard.includeFeedbackRIF && hasEmail ? STEPS.Feedback : STEPS.internalFeedback;
                case EEnquiryAction.ForwardToAnotherDepartment:
                    return wizard.includeFeedbackForward && hasEmail ? STEPS.Feedback : STEPS.forwardEmail;
                case EEnquiryAction.EscalateToManager:
                    return wizard.includeFeedbackEscalate && hasEmail ? STEPS.Feedback : STEPS.escalateEmail;
                case EEnquiryAction.Pend:
                    return wizard.includeFeedbackPend ? STEPS.Feedback : STEPS.diarise;
                case EEnquiryAction.MakeNew:
                case EEnquiryAction.Split:
                    return STEPS.selectEmail;
                default:
                    return STEPS.finishStep;
            }
        },
        summarise: (wizard: Wizard) => {
            const summary: string[] = [];
            summary.push(`Action "${ENQUIRY_ACTION_NAMES[wizard.workflow.data.EnquiryAction!]}" selected.`);

            let completeSummary = 'Enquiry will be moved to your "My Completed Enquiries" list.';
            let pendingSummary = 'Enquiry will be displayed in your "My Unresolved Enquiries" list until completed.';

            switch (wizard.workflow.data.EnquiryAction) {
                case EEnquiryAction.Complete:
                case EEnquiryAction.RespondByEmail:
                case EEnquiryAction.RespondByOtherMedium:
                case EEnquiryAction.ForwardToAnotherDepartment:
                    summary.push(completeSummary);
                    break;
                case EEnquiryAction.CorrespondByEmail:
                case EEnquiryAction.Pend:
                case EEnquiryAction.RequestInternalFeedback:
                case EEnquiryAction.EscalateToManager:
                case EEnquiryAction.CorrespondByOtherMedium:
                    summary.push(pendingSummary);
                    break;
                case EEnquiryAction.MakeNew:
                case EEnquiryAction.Split:
                    var enquiry = getEnquiryById(wizard.EnquiryId);
                    if (Statuses.IsCompleted(enquiry.status)) {
                        summary.push(completeSummary);
                    } else {
                        summary.push(pendingSummary);
                    }
                    break;
            }
            return summary;
        }
    },

    [EStepKey.RESPOND_BY_EMAIL_STEP]: {
        key: EStepKey.RESPOND_BY_EMAIL_STEP,
        title: 'Compose Email',
        getNext: (wizard: Wizard) => {
            return STEPS.finishStep;
        },
        summarise: (wizard: Wizard) => {
            return [
                `Create email to [${wizard.mailDrafts.find(x => x.action === 3)?.draft.to.map(a => a.address)}].`
            ];
        }
    },

    [EStepKey.LINK_TO_ENQUIRY_STEP]: {
        key: EStepKey.LINK_TO_ENQUIRY_STEP,
        title: 'link to an existing Enquiry',
        getNext: (wizard: Wizard) => {
            return STEPS.finishStep;
        },
        summarise: (wizard: Wizard) => {
            return [
                `Attach this email to the Enquiry with the reference ${getEnquiryById(
                    wizard
                        .workflow
                        .data
                        .ExistingEnquiryID!).enquiryReference}.`
            ];
        }
    },

    [EStepKey.CORRESPOND_BY_EMAIL_STEP]: {
        key: EStepKey.CORRESPOND_BY_EMAIL_STEP,
        title: "Correspond to Enquirer by Email",
        getNext: (wizard: Wizard) => {
            return STEPS.diarise;
        },
        summarise: (wizard: Wizard) => {
            return [
                `Create email to [${wizard.mailDrafts.find(x => x.action === 2)?.draft.to.map(a => a.address)}].`
            ];
        }
    },

    [EStepKey.WIZARD_SUMMARY_STEP]: {
        key: EStepKey.WIZARD_SUMMARY_STEP,
        title: "Finished",
        getNext: (wizard: Wizard) => {
            return null;
        },
        summarise: (wizard: Wizard) => []
    },

    [EStepKey.DIARISE]: {
        key: EStepKey.DIARISE,
        title: "Enquiry Expiry and Pend Alarm Settings",
        getNext: (wizard: Wizard) => {
            return STEPS.finishStep;
        },
        summarise: (wizard: Wizard) => {
            return [
                wizard.workflow.data.Alarm ?
                    `Create a reminder alarm that will sound on ${FormatDate(
                        wizard
                            .workflow
                            .data
                            .Alarm
                            .alarmAt + ""
                    )} ${toAnalogueTime(
                        wizard
                            .workflow
                            .data
                            .Alarm
                            .alarmAt + ""
                    )}.` :
                    "No reminder alarm will be set."
            ];
        }
    },
    [EStepKey.FEEDBACK]: {
        key: EStepKey.FEEDBACK,
        title: "Enquiry Feedback Email",
        getNext: (wizard: Wizard) => {
            switch (wizard.workflow.data.EnquiryAction) {
                case EEnquiryAction.RequestInternalFeedback:
                    return STEPS.internalFeedback;
                case EEnquiryAction.ForwardToAnotherDepartment:
                    return STEPS.forwardEmail;
                case EEnquiryAction.EscalateToManager:
                    return STEPS.escalateEmail;
                case EEnquiryAction.Pend:
                    return STEPS.diarise;
                default:
                    throw new Error("EnquiryAction is unsupported for feedback email.");
            }
        },
        summarise: (wizard: Wizard) => {
            return [
                `Create feedback email to [${wizard.mailDrafts.find(x => x.action === 11)?.draft.to.map(a => a.address)}].`
            ];
        }
    },
    [EStepKey.INTERNAL_FEEDBACK]: {
        key: EStepKey.INTERNAL_FEEDBACK,
        title: "Request Internal Feedback Email",
        getNext: (wizard: Wizard) => {
            return STEPS.diarise;
        },
        summarise: (wizard: Wizard) => {
            return [
                `Create email to [${wizard.mailDrafts.find(x => x.action === 5)?.draft.to.map(a => a.address)}].`
            ];
        }
    },
    [EStepKey.FOWARD_EMAIL]: {
        key: EStepKey.FOWARD_EMAIL,
        title: "Forward Enquiry to another Department Email",
        getNext: (wizard: Wizard) => {
            return STEPS.finishStep;
        },
        summarise: (wizard: Wizard) => {
            return [
                `Create email to [${wizard.mailDrafts.find(x => x.action === 6)?.draft.to.map(a => a.address)}].`
            ];
        }
    },
    [EStepKey.ESCALATE_EMAIL]: {
        key: EStepKey.ESCALATE_EMAIL,
        title: "Escalate to Manager Email",
        getNext: (wizard: Wizard) => {
            return STEPS.diarise;
        },
        summarise: (wizard: Wizard) => {
            return [
                `Create email to [${wizard.mailDrafts.find(x => x.action === 7)?.draft.to.map(a => a.address)}].`
            ];
        }
    },

    [EStepKey.COMMUNICATION_LOGGER]: {
        key: EStepKey.COMMUNICATION_LOGGER,
        title: 'Communication Logger',
        getNext: (wizard: Wizard) => {
            if (wizard.workflow.data.EnquiryAction === EEnquiryAction.CorrespondByOtherMedium) {
                return STEPS.diarise;
            }
            return STEPS.finishStep;
        },
        summarise: (wizard: Wizard) => {
            const summary: string[] = [];
            if (wizard.otherMediumControl?.Type !== undefined) {
                const typeMap: { [commType in ECommType]: string } = {
                    [ECommType.FAX]: "Facsimile",
                    [ECommType.LETTER]: "Letter",
                    [ECommType.MEETING]: "Meeting",
                    [ECommType.PHONE]: "Phone Call",
                    [ECommType.TASK]: "Task"
                }
                return [`Create a ${typeMap[wizard.otherMediumControl.Type]} Summary.`];
            }
            return summary;
        }
    },

    [EStepKey.SELECT_EMAIL]: {
        key: EStepKey.SELECT_EMAIL,
        title: 'Select email to make new enquiry',
        getNext: (wizard: Wizard) => {
            return STEPS.finishStep;
        },
        summarise: (wizard: Wizard) => [
            "Create a new Enquiry."
        ]
    },

    [EStepKey.ASSIGN_OTHER_USER]: {
        key: EStepKey.ASSIGN_OTHER_USER,
        title: 'assign enquiry to another mailbox user',
        getNext: (wizard: Wizard) => {
            return STEPS.explanatoryNote;
        },
        summarise: (wizard: Wizard) => {
            return [
                `Assign this enquiry to ${wizard.otherUserControl?.firstname} ${wizard.otherUserControl?.lastname}.`
            ];
        }
    },

    [EStepKey.EXPLANATORY_NOTE]: {
        key: EStepKey.EXPLANATORY_NOTE,
        title: 'Explanatory Note for Assigning Enquiry',
        getNext: (wizard: Wizard) => {
            return STEPS.finishStep;
        },
        summarise: (wizard: Wizard) => {
            return [
                "Attach an explanatory note for the assignment to another user."
            ];
        }
    },
}

export const getNextStep = (key: EStepKey, wizard: Wizard): EStepKey | null => {
    return STEPS[key].getNext(wizard)?.key ?? null
}

/**
 * Gets the summary of the wizard's current step
 * @returns Summary of the wizard's current step
 */
export const getSummary = (wizard: Wizard): string [] => {
    return STEPS[wizard.workflow.currentStep].summarise(wizard);
}

export const ENQUIRY_ACTION_NAMES = {
    [EEnquiryAction.LinkToExisting]: "",
    [EEnquiryAction.AssignOtherUser]: "",

    [EEnquiryAction.CorrespondByEmail]: "Correspond To Enquirer by Email",
    [EEnquiryAction.CorrespondByOtherMedium]: "Correspond with other Medium",

    [EEnquiryAction.RespondByEmail]: "Respond to Enquirer by Email",
    [EEnquiryAction.RespondByOtherMedium]: "Respond to Enquirer with other Medium",

    [EEnquiryAction.Complete]: "Complete Enquiry",

    [EEnquiryAction.RequestInternalFeedback]: "Request Internal Feedback",
    [EEnquiryAction.ForwardToAnotherDepartment]: "Forward Enquiry to another Department",
    [EEnquiryAction.EscalateToManager]: "Escalate Enquiry to Manager",
    [EEnquiryAction.Pend]: "Pend Enquiry",

    [EEnquiryAction.MakeNew]: "Make a New Enquiry",
    [EEnquiryAction.Split]: "Split Enquiry"
}

export function initialiseSteps(e: iEnquiry, wizard: Wizard): void {
    wizard.workflow.history = []
    if (e.status === Statuses.Received.ID) {
        wizard.workflow.currentStep = EStepKey.ENQUIRY_TRACKER_STEP
        return;
    }

    if ((e.enquirerName.trim() === '' && e.enquirerEmail.trim() === '')) {
        wizard.workflow.currentStep = EStepKey.ENQUIRER_DETAILS_STEP
        return;
    }

    if (wizard.MISModel !== undefined) {
        wizard.workflow.currentStep = EStepKey.MIS_STEP;
        wizard.workflow.history = [
            {
                stepKey: EStepKey.ENQUIRER_DETAILS_STEP,
                summary: getSummary(wizard)
            }
        ]
        return;
    }

    if (wizard.MISModel === undefined) {
        wizard.workflow.currentStep = EStepKey.ENQUIRY_ACTION_STEP;
        wizard.workflow.history = [
            {
                stepKey: EStepKey.ENQUIRER_DETAILS_STEP,
                summary: getSummary(wizard)
            }
        ]
        
        return;
    }
}