import { EPriority } from '../interfaces/Priority';
import { iTenant } from '../interfaces/auth';
import { iEmail } from '../interfaces/emailModel';
import { getSessionValue } from './localStore/calls';

export const initDraftMailValues:iEmail = {
    uId:"",
    cc: [],
    bcc: [],
    dateSent: "",
    incoming: false,
    priority: EPriority.NORMAL,
    subject: "",
    hasAttachments: "",
    unread: "",
    unsent: "",
    body:"",
    date: "",
    communicationId: 1010,
    messageId:121,
    attachments:[],
    inlineAttachments:[],
    type:"",
    maildraftId:0,
    enquiryId:0,
    from:{name:"", address:""},
    to: [],
  } 
