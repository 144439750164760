import { Bs0SquareFill, BsMagic, BsPeople, BsRecycle, BsSquareHalf, BsPersonAdd, BsSticky, BsTrash3 } from "react-icons/bs"
import { IconMenuItems } from "."
import { ActivateWizardModule } from "../../Logic/Enquiries/activateWizard"
import { Dispatch, useContext, useEffect, useState } from "react"
import { CreateNoteContext } from "../../contexts/create-note.context"
import { EnquiryMenuProps } from "../../interfaces/menu"
import { ChangeEnquiryOwnershipContext } from "../../contexts/changeEnquiryOwnership.context"
import { RemoveEnquiriesContext } from "../../contexts/removeEnquiries.context"
import { ChangeEnquiryPriorityContext } from "../../contexts/changeEnquiryPriority.context"
import Priority from "../../assets/img/priority.svg";
import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../state/store"
import { getEnquiryById, refreshList } from "../../interfaces/enquiryList"
import { GetMailboxPermissions, Permissions as iPermissions } from "../../interfaces/Permissions"
import { iEnquiry } from "../../interfaces/iEnquiry"
import { setActiveEnquiry } from "../../state/activeEnquiry"
import { Post } from "../../utilities/apiCall"
import Statuses from "../../interfaces/Statuses"

interface  EnquiryMenuPropswithHide extends EnquiryMenuProps{
    onHide:()=> void
}
export function EnquiryMenuItems({ enquiryId, menuStyle,onHide }: EnquiryMenuPropswithHide) {

    const createNote = useContext(CreateNoteContext)
    const changeEnquiryOwnership = useContext(ChangeEnquiryOwnershipContext)
    const removeEnquiries = useContext(RemoveEnquiriesContext)
    const changeEnquiryPriority = useContext(ChangeEnquiryPriorityContext)
    const permissions = useSelector((state: RootState) => state.permissions);
    const [enquiry,setEnquiry] =useState<iEnquiry>();
    const userID = useSelector((state: RootState) => state.UserInformation).userID;
    const dispatch: Dispatch<any> = useAppDispatch();

    useEffect(()=>{
        setEnquiry(getEnquiryById(enquiryId));
    },[enquiryId])

    function isTakeOwnershipDisabled(): boolean {
        return !GetMailboxPermissions(permissions, enquiry!.mailbox).TakeEnquiryOwnership ||
                enquiry!.owners.length > 0;
    }

    return (
       !enquiry? <></>:<IconMenuItems options={[
            {
                icon: <i><BsMagic /></i>,
                label: "Activate Wizard",
                onClick: () => new ActivateWizardModule().ActivateWizard(enquiryId),
                disabled: !(new ActivateWizardModule().wizardIdPermitted(enquiryId))
            },
            {
                icon: <i><BsSticky /></i>,
                label: "Create Note",
                onClick: () => createNote(enquiryId),
                disabled: false
            },
            {
                icon: <i><BsPersonAdd /></i>,
                label: "Take Ownership",
                onClick: () => {
                    takeEnquiryOwnership(enquiryId, enquiry.mailbox).then(() => {
                        refreshList(userID).then(() => {
                            dispatch(setActiveEnquiry(0));
                        });
                    });
                    onHide();
                },
                disabled: isTakeOwnershipDisabled()
            },
            {
                icon: <i><BsPeople /></i>,
                label: "Change Enquiry Ownership",
                onClick: () => {
                    changeEnquiryOwnership([enquiry!])
                    onHide()
                },
                disabled: !GetMailboxPermissions(permissions, enquiry!.mailbox).ModifyEnquiryOwnership,
            },
            {
                icon: <img src={Priority} width={15} height={20} style={{marginLeft:"-3px"}}/>,
                label: "Change Enquiry Priority",
                onClick: () => {
                    changeEnquiryPriority([enquiry!])
                    onHide()
                },
                disabled: !GetMailboxPermissions(permissions, enquiry!.mailbox).CloseEnquiry
            }, 
            { 
                icon: <i><BsTrash3/></i>,
                label: "Remove Enquiry",
                onClick: () => {
                    removeEnquiries([enquiry!])
                    onHide()
                },
                hidden: !GetMailboxPermissions(permissions, enquiry!.mailbox).CloseEnquiry,
                disabled: !(
                    enquiry.status === Statuses.Received.ID && (
                        enquiry.owners.length === 0 || (
                            enquiry.owners.length === 1 &&
                            enquiry.owners[0].id === userID
                        )
                    )
                )
            }
        ]}
        menuStyle={menuStyle ?? "context-menu"}
        />
    )
}

const takeEnquiryOwnership = async (enquiryId: number, mailboxID: number) => {
    await Post(`Mailboxes/${mailboxID}/Enquiries/${enquiryId}/TakeOwnership`, null);
}