import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import './email.scss';
import { BsExclamationLg, BsArrowDown, BsDownload, BsChevronDoubleUp } from "react-icons/bs";
import MainAttachment from './mainAttachmentItem';
import { iGenericComm } from '../../interfaces/iGenericComm';
import CommIcon from '../Enquiries/communication/commIcon';
import { CommToView, getAllAttachments } from '../../Logic/GenericComms/CommParts';
import { getEnquiryById, getEnquiryLists } from '../../interfaces/enquiryList';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../state/store';
import { setEmailVisible } from '../../state/secondColSlice';
import { iEnquiry } from '../../interfaces/iEnquiry';
import { getLastCommunicationPosition, setLastCommunication, setLastCommunicationScrollPosition } from '../../Logic/LastTouched/comm';
import { dateTimeFormat222 } from '../Enquiries/enquiry/enquiry';


type previewProps = {
    comm: iGenericComm,
    FullWidth: boolean,
    enquirerName: string,
    activeEnquiryId:number,
 
}

function Preview(props: previewProps) {
    const frameRef = React.useRef<HTMLIFrameElement>(null)
    const [frameHeight, setFrameHeight] = useState("57px") 
    const [showAll, setShowAll] = useState(false)
    const [comm, setComm] = useState<iGenericComm>(props.comm);
    const dispatch: React.Dispatch<any> = useAppDispatch();
   // const comm: iGenericComm = props.comm;
 
    //const activeComm = useSelector((state: RootState) => state.activeComm);

    
    useEffect(()=>{
        if(props.activeEnquiryId >0 && (props.comm.id!=undefined)){
            let enquiry: iEnquiry = getEnquiryById(props.activeEnquiryId);
            let commDTO = enquiry?.communications.find(x=> x.id==props.comm.id)!     ;
            CommToView(commDTO).then((comm) => {
                setComm(comm);
            dispatch(setEmailVisible(true))
            });
        }
    },[props.comm])

    let MainAttachmentList;
    let sumValues: number = 0;
    const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    // on update
    useEffect(() => {
        return function cleanup() {
            setShowAll(false)
        }
    }, [comm.attachments])


    useEffect(() => {
		const enquiryH= parseInt(getComputedStyle(document.getElementById("enquiryHolder")!, '').height);
        const commH= parseInt(getComputedStyle(document.getElementById("previewMetaElements")!, '').height);
        let comPreview = document.getElementById("bodyRow")!;
        //let frmPreview = document.getElementById("emailBodyiFrame")!;
        comPreview.style.height= window.innerHeight - (enquiryH + commH +169) +"px";
        window.setTimeout(()=>{
            comPreview.scrollTop = getLastCommunicationPosition(props.activeEnquiryId? props.activeEnquiryId:0) //+ 200;
            comPreview.scrollLeft = 0;
        },50)
       // frmPreview.style.height= window.innerHeight - (enquiryH + commH +70  ) +"px";
       // frmPreview.style.overflow = "auto";
        //comPreview.style.height= "1500px";
    })
    



    if (comm.attachments && comm?.attachments?.length > 0) {
        comm.attachments.forEach((item) => { sumValues = sumValues + item.size });


        MainAttachmentList = comm.attachments.map(
            (item) =>
                <MainAttachment
                    id={item.id}
                    name={item.name}
                    mimeType={item.mimeType}
                    size={item.size}
                    key={"mainAttachment11_" + item.id}
                />

        );
    }


    sumValues = (sumValues / 1000)
    let attachmentSizeIndicater: string = "KB"
    if (sumValues > 999.99) {
        sumValues = (sumValues / 1000);
        attachmentSizeIndicater = "MB";
    }
    function closePops() {
        closeAttach();
    }
    function closeAttach() {
        var allElements = document.querySelectorAll('*[id^="mAttach-"]');
        allElements.forEach((item) => { item.className = "MainAttachmentOptionsContainer invisible" });
    }

    function resizeIframe() {
        let ifrm = frameRef.current!;
        
        if (frameRef != null) {
           // let ifrm1 = document.getElementById('bodyRow') as HTMLElement;
            ifrm.style.height = ifrm.contentDocument?.body.clientHeight! + 50 + "px";
            if(ifrm.contentDocument?.body.scrollWidth!<600){
                ifrm.style.width="100%"
            }else{
                ifrm.style.width=ifrm.contentDocument?.body.scrollWidth! + 50 + "px"
            }
        }
    }
    let timer:any = null;
function saveScrollPos(){
    if(timer !== null) {
        clearTimeout(timer);        
    }
    timer = setTimeout(function() { 
        setLastCommunication( (props.activeEnquiryId? props.activeEnquiryId:0),(props.comm? props.comm.id:0), document.getElementById("bodyRow")?.scrollTop!)
    }, 150);
}
    return (
        <Fragment >
            <div className='email-preview' style={{ marginLeft: props.FullWidth ? "4px" : "5px" }} onClick={() => { closePops() }}>
                <div className="email-preview--details">
                    <div id="previewMetaElements">
                            <div className="email-preview--details--subject-row">
                                <div className='email-preview--details--subject-row--subject' data-testid="testSubject">{comm.subject}</div>
                                <CommIcon className='icon' type={comm.type} unread={comm.unread} inBound={comm.incoming} />
                            </div>
                            <div className='email-preview--details--addresses-row'>
                                <div className="profile" title={"From: " + comm.from}>
                                    {userInitials(comm.from)}
                                </div>
                                <div className="from-to-cc">
                                    <div className='from' data-testid="testSenderName">{comm.from}
                                        <div className='email-preview--details--subject-row--date' style={{ fontSize: "7pt", float: 'right' }}>{weekday[new Date(comm.date).getDay()]} {dateTimeFormat222(comm.date)}</div>
                                    </div>
                                    <div className="to-cc">
                                        <div className="to">
                                            <div className='label to-label'>To:</div>
                                            <div className='to-addr' data-testid="testtoAddress">{comm.to}</div>
                                        </div>
                                        {comm.cc && <div className="cc">
                                            <div className='label cc-label'>Cc:</div>
                                            <div className='cc-addr' data-testid="testccAddress">{comm.cc}</div>
                                        </div>}

                                    </div>
                                </div>
                            </div>
                            <div className="email-preview--details--priority-row"> {
                                comm.priority === 1 ? <BsExclamationLg className="exclamation" /> :
                                comm.priority === 2 ? <BsExclamationLg className="exclamation" /> :
                                comm.priority === 4 ? <BsArrowDown className="arrow" /> :
                                comm.priority === 5 ? <BsArrowDown className="arrow" /> :
                                <Fragment/>
                            }
                                <div className='label'> {
                                    comm.priority === 1 ? "Highest Importance" :
                                    comm.priority === 2 ? "High Importance" :
                                    comm.priority === 4 ? "Low Importance" :
                                    comm.priority === 5 ? "Lowest Importance" :
                                    <Fragment/>
                                }
                                </div>
                            
                            </div>
                            {comm?.attachments?.length > 0 && <div className="email-preview--details--attachments-row">
                                <div className="attachments">
                                    {showAll ? MainAttachmentList : MainAttachmentList?.slice(0, 4)}
                                </div>
                                {/* <br style={{ clear: "both", height: "auto" }} className={e.attachments.length > 1 ? "" : "invisible"}></br> */}
                                {comm?.attachments?.length > 1 && comm?.attachments?.length <= 4 ?
                                    <div className="attachment-actions less-4">
                                        <div className="total">
                                            {`${comm?.attachments?.length} attachments (${(sumValues).toFixed(2)} ${attachmentSizeIndicater})`}
                                        </div>
                                        <div
                                            onClick={() => {
                                                getAllAttachments(comm.attachments, "multiple");
                                            }}
                                            className="download-all"
                                        >
                                            <i><BsDownload></BsDownload></i>
                                            <div className="download-label">Download All</div>
                                        </div>
                                    </div> : (
                                        comm?.attachments?.length > 4 ?
                                            <div className="attachment-actions more-4">
                                                <div className="total" onClick={() => setShowAll(!showAll)}>
                                                    <i><BsChevronDoubleUp className={`double-chevron${showAll ? " flipped" : ""}`} /></i>
                                                    {`${!showAll ? "Show all " : ""}${comm?.attachments?.length} attachments (${(sumValues).toFixed(2)} ${attachmentSizeIndicater})`}
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        getAllAttachments(comm.attachments, "multiple");
                                                    }}
                                                    className="download-all"
                                                >
                                                    <i><BsDownload></BsDownload></i>
                                                    <div className="download-label">Download All</div>
                                                </div>
                                            </div> : []
                                    )}
                            </div>}

                            {/* <div className="email-preview--details--body-row" id="bodyRow">
                                <iframe className='iframe' srcDoc={e.body} id="emailBodyiFrame" onLoad={() => { resizeIframe() }}/>
                            </div> */}
                    </div>
                    <div className="email-preview--details--body-row" id="bodyRow" onScrollCapture={()=> {saveScrollPos()}}>
                        <iframe id="emailBodyiFrame" sandbox='allow-same-origin' srcDoc={comm.body} data-testid="emailBodyiFrame1" scrolling='no'  onLoad={() => { resizeIframe() }}  ref={frameRef}>  
                        </iframe>

                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export function removeParentheses(name: string): string {

    const openParenIndex = name? name.indexOf('(') : -1;
    if (openParenIndex == -1) {
        return name;
    }

    let closeParenIndex = openParenIndex;
    let parenthesesCount = 1;

    while (parenthesesCount > 0 && closeParenIndex < name.length - 1) {
        closeParenIndex++;

        if (name.charAt(closeParenIndex) == '(') {
            parenthesesCount++;
        } else if (name.charAt(closeParenIndex) == ')') {
            parenthesesCount--;
        }
    }

    if (parenthesesCount != 0) {
        return name;
    }

    const newName = name.slice(0, openParenIndex) + name.slice(closeParenIndex + 1);
    return removeParentheses(newName.trim()); // rec
}

export const userInitials = (name: string) => {
    const nameStripped = removeParentheses(name)

    const nameSur = nameStripped? nameStripped.split(" ") : "  ";
    return nameSur[0].charAt(0).toUpperCase() + nameSur[nameSur.length - 1].charAt(0).toUpperCase()
}

export default Preview;


