import { read } from "fs";

const pako = require('pako');
const zip = require('jszip')();
const canBeOpendInBrowser = "application/pdf,image/png,image/jpeg,image/gif,audio/mpeg,video/mp4";
export async function unzip(data:ArrayBuffer){
  try{
    const binData = new Uint8Array(data);
    var parsed = await pako.ungzip(binData,{ 'to': 'string' });
    var data1 =  await (pako.ungzip(new Uint8Array(data)).buffer);
    var sss = await pako.ungzip(data);
    const file = new File([sss], "21.jpeg", {type: "image/jpeg", lastModified: Date.now()});
    let myImage = new Image();
    const reader = new FileReader();
    reader.onloadend = (ev) => {
     const base64data = reader.result!;   
    }
    
    return  parsed;
    }
    catch (e){
        var a = e;
    }
}

 
export async function unzipFileAndBaseBase64(data:ArrayBuffer, mimeType: string):Promise<any>{
  return new Promise((resolve, reject) => {
  try{
 
    let fielName: string ="sFile";
    switch(mimeType.toLowerCase()){
      case "image/jpeg":
        fielName = fielName+".jpeg"
        break;
        case "image/png":
            fielName = fielName+".png"
            break;
    }
    const unZippedFIle = pako.ungzip(data);
    const file = new File([unZippedFIle], fielName, {type: mimeType, lastModified: Date.now()});
    const reader = new FileReader();
    
    reader.onloadend = (ev) => {
       
      resolve(reader.result);   
    }
    reader.readAsDataURL(file);
    
    }
    catch (e){
      reject(e);
    }
  });   
}

export async function unzipFileAndSave(data:ArrayBuffer, mimeType: string,fileName: string,downloadType:string,id :string):Promise<any>{
  return new Promise((resolve, reject) => {
  try{
        const unZippedFIle = pako.ungzip(data);
      
        const file = new File([unZippedFIle], fileName, {type: mimeType, lastModified: Date.now()});
        if(downloadType=="multiple"){
          resolve(file);
        }
        else if(downloadType=="tempStore"){
          const reader = new FileReader();
          reader.onloadend = (ev) => {
           if(reader.result!=null){
            // sessionStorage.setItem(fileName,reader.result.toString()); 
             alert("Downloaded to Temp Store")
             resolve(true);
           }
          
        
         }
         reader.readAsDataURL(file);
         
         
        }else{
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(file);
        if(downloadType=="download" || canBeOpendInBrowser.toLowerCase().indexOf(mimeType.toLowerCase())<0){
          a.download = fileName;
        }else if(downloadType=="open"){
          a.target="_blank"
        }
        a.click();
        resolve(true);
      }
      
    }
    catch (e){
      if(downloadType=="tempStore"){
        alert("an error has occured, The local storage quota may be exceeded ")
      }
      else{
        alert("an error has occured ")
      }
      reject(e);
    }
  });   
}


export async function unzipFileandSaveMulitple(data:ArrayBuffer, mimeType: string,fileName: string,downloadType:string):Promise<any>{
  return new Promise((resolve, reject) => {
  try{
         const unZippedFIle = pako.ungzip(data);
      
        const file = new File([unZippedFIle], fileName, {type: mimeType, lastModified: Date.now()});
        if(downloadType=="tempStore"){
          const reader = new FileReader();
          reader.onloadend = (ev) => {
           if(reader.result!=null){
             localStorage.setItem(fileName,reader.result.toString()); 
             alert("Downloaded to Temp Store")
             resolve(true);
           }
          
        
         }
         reader.readAsDataURL(file);
         
         
        }else{
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(file);
        if(downloadType=="download"){
           
          zip.file(fileName, file);
          zip.file("asdasd.jpg",file);
          // Generate the complete zip file
          zip.generateAsync({type:'blob'}).then((blobdata:any)=>{
             // create zip blob file
             let zipblob = new Blob([blobdata])
     
             // For development and testing purpose
             // Download the zipped file 
             var elem = window.document.createElement("a")
             elem.href = window.URL.createObjectURL(zipblob)
             elem.download = 'compressed.zip'
             elem.click()
         });

          a.download = fileName;
        }else if(downloadType=="open"){
          a.target="_blank"
        }
      //  a.click();
        resolve(true);
      }
      
    }
    catch (e){
      if(downloadType=="tempStore"){
        alert("an error has occured, The local storage quota may be exceeded ")
      }
      else{
        alert("an error has occured ")
      }
      reject(e);
    }
  });   
}

export async function unZipandReturnBlobUri(data:ArrayBuffer, mimeType: string,fileName: string):Promise<any>{

    return new Promise((resolve, reject) => {
        try{
               const unZippedFIle = pako.ungzip(data);
            
              const file = new File([unZippedFIle], fileName, {type: mimeType, lastModified: Date.now()});
              resolve(window.URL.createObjectURL(file));
              
            
          }
          catch (e){
            reject(e);
          }
        });   
}

export async function unZipandSavetoLocalStorage(data:ArrayBuffer, mimeType: string,fileName: string):Promise<any>{
  return new Promise((resolve, reject) => {
  try{
         const unZippedFIle = pako.ungzip(data);
      
        const file = new File([unZippedFIle], fileName, {type: mimeType, lastModified: Date.now()});
        const reader = new FileReader();
         reader.onloadend = (ev) => {
          if(reader.result!=null){
            localStorage.setItem(fileName,reader.result.toString()); 
          }
         
       
        }
        reader.readAsDataURL(file);
      
    }
    catch (e){
      reject(e);
    }
  });   
}


export async function unzipText(data:ArrayBuffer):Promise<any>{
   return new Promise((resolve, reject) => {
          try{
             var text =   pako.ungzip(data,{ 'to': 'string' });
             
             resolve(text);
            }
            catch (e){
              reject(e);
            }
        });   
}

export async function zipText(data:ArrayBuffer):Promise<any>{
  return new Promise((resolve, reject) => {
         try{
            var text =   pako.gzip(data, {to: 'string'});
            
            resolve(text);
           }
           catch (e){
             reject(e);
           }
       });   
}

export async function zipFile(data:ArrayBuffer, mimeType: string ):Promise<any>{
  return new Promise((resolve, reject) => {
  try{
         const ZippedFIle = pako.gzip(data);
         resolve(ZippedFIle);
    }
    catch (e){
      reject(e);
    }
  });   
}

export function base64ToArrayBuffer(base64:string) {
    var binaryString = window.atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}