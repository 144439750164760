import { useState } from 'react';
import '../form.scss';
import React from 'react';

export interface dateboxProps{
    id:string,
    label?: string,
    value: string,
    onChange : (newValue:string) =>any,
    setFocus?: boolean,
    maxLength:number,
    firstCol?:number
}

const getDefaultTime = () => {
    const date = new Date();
    date.setSeconds(0, 0);
    let result = "";
    const hours = date.getHours();
    const minutes = date.getMinutes();
      if (hours < 10)
            result += "0";
           result += hours.toString();
    result += ":";
    if (minutes < 10)
        result += "0";
    result += minutes.toString();
    return result;
}

function Time(props: dateboxProps) {
    const [fieldValue, setFieldValue] = useState("")

    React.useEffect(()=>{
            if (props.value == "") {
                let defaultVal = getDefaultTime();
                setFieldValue(defaultVal);
                props.onChange(defaultVal);
            } else {
                setFieldValue(props.value);
            }
      },[props.value]);

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        setFieldValue(e.currentTarget.value);
        props.onChange(e.currentTarget.value);
    }

    return (
        <div className="row rowMarginControl" key={"Main" + props.id}>
           {props.label && <div className={props.firstCol == undefined ? "col-sm-3" : "col-sm-" + props.firstCol}>
                <label  className='g-label'>
                     {props.label} 
                </label>
            </div>}
            <div className={props.firstCol == undefined ? "col-sm-9" : "col-sm-" + (12 - props.firstCol)}>
                <input
                    type="time"
                    className="g-TextBox-default"
                    onChange={handleInputChange}
                    value={fieldValue}
                    step={60}
                />
           
            </div>
        </div>
    );
}
export default Time;

