import { useEffect, useRef, useState } from 'react';
import ConfirmDialog from '../ConfirmDialog';
import './styles.scss';

export interface FindOption {
    label: string,
    value: string,
}

export interface FindDialogProps {
    onConfirm: (selectedValue: string) => void,
    onCancel: Function,
    values: Array<FindOption>
}

function FindDialog(props: FindDialogProps) {
    const [selectedOption, setSelectedOption] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(props.values);

    const selectedOptionRef = useRef("");

    useEffect(() => {
        selectedOptionRef.current = selectedOption;
    }, [selectedOption])

    function OnFilter(filterValue: string) {
        setFilteredOptions(props.values.filter(x => x.label.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0))
    }


    const firstTabElement = useRef<HTMLInputElement>(null);
    const parentList = useRef<HTMLInputElement>(null);
    const keydownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key != "ArrowUp" && event.key != "ArrowDown")
            return;
        let currentSelectedIndex=  (filteredOptions.findIndex(x=>x.value==selectedOption));
        if (event.key == "ArrowUp") {
            if (currentSelectedIndex>0){
                setSelectedOption(filteredOptions[currentSelectedIndex -1].value);
                (parentList.current?.children[currentSelectedIndex-1] as HTMLElement).focus();
            }
            else{
                setSelectedOption(filteredOptions[currentSelectedIndex].value);
                (parentList.current?.children[currentSelectedIndex] as HTMLElement).focus();
            }
        }
        else{
            if (currentSelectedIndex<filteredOptions.length){
                setSelectedOption(filteredOptions[currentSelectedIndex + 1].value);
                (parentList.current?.children[currentSelectedIndex + 1] as HTMLElement).focus();
            }
            else{
                setSelectedOption(filteredOptions[currentSelectedIndex].value);
                (parentList.current?.children[currentSelectedIndex] as HTMLElement).focus();
            }
        } 
    }
    return (
        <>
            <ConfirmDialog title="Quick Find" onConfirm={() => props.onConfirm(selectedOptionRef.current)} onCancel={props.onCancel}>
                <div style={{width:"450px"}}>
                <span tabIndex={0} onFocus={() => firstTabElement.current?.focus()} />
                <div className='row align-items-center formRowMargin'>
                    <div className='col-sm-2'>
                        <label>Filter:</label>
                    </div>
                    <div className='col-sm-10'>
                        <input
                            type='text'
                            className='form-control form-control-sm'
                            onChange={e => OnFilter(e.currentTarget.value)}
                            autoFocus={true}
                            autoComplete="off"
                            aria-autocomplete='none'
                            list="autocompleteOff"
                            ref={firstTabElement}
                        />
                    </div>
                </div>
                <div className='find-dialog-options' ref={parentList}>
                    {
                        filteredOptions.map((value) =>
                            <div
                                tabIndex={0}
                                key={"find-dialog-option-" + value.value}
                                className={`find-dialog-option ${value.value === selectedOption ? "find-dialog-option-selected" : ""}`}
                                onFocus={() => setSelectedOption(value.value)}
                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>): void => keydownHandler!(e)}
                                onDoubleClick={() => props.onConfirm(value.value)}
                            >
                                {value.label}
                            </div>)
                    }
                </div></div>
            </ConfirmDialog>
            <span tabIndex={0} onFocus={() => firstTabElement.current?.focus()} />
        </>
    );
}
export default FindDialog;

