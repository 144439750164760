import { useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import '../form.scss';
import FindDialog, { FindOption } from '../../FindDialog';

export interface dropdownProps{
    options:Array<{text:string, value:string}>|undefined
    returnFunc : (newValue: string) => any,
    Valid:boolean ,
    label:string;
    value:string;
    id:string;
    firstCol?:number
}
function DropdownWithQuickFind(props: dropdownProps) {

    const [showFindDialog, setShowFindDialog] = useState(false);
    const [fieldValue, setFieldValue] = useState(props.value)
    let validField = props.Valid

    const handleSelectChange = (e: React.FormEvent<HTMLSelectElement>) => {
        updateValues(e.currentTarget.value);
    }

    const updateValues = (value: string) => {
        setFieldValue(value);
        if (value == "") {
            validField = false;
        } else {
            validField = true;
        }
        const sendToParent = props.returnFunc;
        sendToParent(value);
    }

    return (
        <div className="row" style={{flex: "6 1 0"}} key={"Main" + props.id}>
            <div className={props.firstCol == undefined ? "d-flex col-sm-3" : "d-flex col-sm-" + props.firstCol}><label className='g-label limit-text'>{props.label} </label><small className="asterisk">*</small></div>
            <div className={props.firstCol == undefined ? "d-flex col-sm-9" : "d-flex col-sm-" + (12 - props.firstCol)}>
                <div style={{flex: "2 1 0"}} className="controlWithButton">
                    <div>
                        <select style={{ float: "left" }} className={(validField) ? "g-Select-default" : "g-Select-default validationFailed"} id={"mis-select" + props.id!.toString()}
                            key={"Select" + props.id} onChange={handleSelectChange} value={fieldValue}  >
                            <option value="">Please select...</option>
                            {
                                props.options?.map((item: {text:string, value:string}) =>
                                <option value={item.value} key={item.value} >{item.text}</option>
                                )
                            }
                        </select>
                    </div>
                    <div >
                        <button onClick={() => setShowFindDialog(true)} type='button'><BsSearch /></button>
                    </div>
                </div>
            </div>

            {showFindDialog &&
                <FindDialog
                    onCancel={() => setShowFindDialog(false)}
                    onConfirm={(selectedValue) => {
                        if (selectedValue !== "")
                            updateValues(selectedValue);

                        setShowFindDialog(false);
                    }}
                    values={props.options!.map<FindOption>(x => {
                        return {
                            label: x.text,
                            value: x.value.toString()
                        };
                    })}
                />
            }
        </div>
    );
}
export default DropdownWithQuickFind;