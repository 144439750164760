import { useContext, useEffect, useState } from "react";
import { WizardContext } from "../../../contexts/wizard-context";
import { EStepKey } from "../../../Logic/Wokflow/stepLogic";
import { WizardStepProps } from "../stepType";
import Step from '../template'
import DropdownWithQuickFind from "../../Generic/Form/DropDownWithQuickFind";
import { iUser } from "../../../interfaces/iUser";
import { getEnquiryById } from "../../../interfaces/enquiryList";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { EEnquiryAction } from "../../../interfaces/wizard";
import { GetMailboxUsers } from "../../../Logic/Enquiries/Queue";

export function AssignOtherUser(props: WizardStepProps) {

    const { wizard, setWizard, onNext, onBack } = useContext(WizardContext)!;
    const [mailboxUsers, setMailboxUsers] = useState<iUser[]>([]);
    const UserInfo = useSelector((state: RootState) => state.UserInformation);

    const userOptions = mailboxUsers
        .filter(u => u.id !== UserInfo.userID)
        .map(user => ({
            text: `${user.firstname} ${user.lastname}`,
            value: user.id.toString()
        }))

    useEffect(() => {
        (async () => {
            const enquiry = getEnquiryById(props.EnquiryId);
            const users: iUser[] = await GetMailboxUsers(enquiry.mailbox + "");
            setMailboxUsers(users);
        })();

    }, [props.EnquiryId])

    const nextFunc = () => {
        if (wizard.otherUserControl === undefined || wizard.otherUserControl.id <= 0) {
            alert("Please choose a user to assign to the enquiry.");
            return;
        }
        setOtherUserData(wizard.otherUserControl.id);
        setWizard(wiz => ({
            ...wiz,
            workflow: {
                ...wiz.workflow,
                data: {
                    ...wiz.workflow.data,
                    NewUserID: wizard.otherUserControl?.id,
                    EnquiryAction: EEnquiryAction.AssignOtherUser
                }
            }
        }))
        onNext();
    }

    const setOtherUser = (newUser: number | undefined) => {
        setWizard(wiz => ({
            ...wiz,
            otherUserControl: mailboxUsers.find(u => u.id === newUser)
        }));
    }
    const setOtherUserData = (newUser: number | undefined) => {
        setWizard(wiz => ({
            ...wiz,
            workflow: {
                ...wiz.workflow,
                data: {
                    ...wiz.workflow.data,
                    NewUserID: newUser
                }
            }
        }))
    }

    const backFunc = () => {
        setOtherUserData(undefined);
        onBack();
    }

    return (
        <Step
            step={EStepKey.ASSIGN_OTHER_USER}
            title={"Assign Enquiry to another mailbox user"}
            EnquiryId={props.EnquiryId}
            Popped={false}
            backF={backFunc}
            nextF={nextFunc}
        >
            <DropdownWithQuickFind
                options={userOptions}
                returnFunc={(newValue: string) => {
                    setOtherUser(+newValue);
                }}
                Valid={wizard.otherUserControl ? true : false}
                label={"Assign to user"}
                value={wizard.otherUserControl ? `${wizard.otherUserControl.firstname} ${wizard.otherUserControl.lastname}` : ""}
                id={"assign-other-user"}
            />
        </Step>
    )
}
