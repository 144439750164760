import { iEnquiry } from '../interfaces/iEnquiry'
import { createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import { setActiveComm } from './activeComm';
import store from './store';
const initialState = {
    id: 0 
};

export const setActiveEnquiry = createAsyncThunk(
    "",
    async (enq:number ) => {
        
        store.dispatch(setActiveComm(undefined));
        store.dispatch(ChangeActiveEnquiry(enq));
        return enq;
    }
  );

export const activeEnquirySlice = createSlice({
    name: 'ChangeActiveEnquiry',
    initialState,
    reducers: {
        ChangeActiveEnquiry: (state, action) => {
            state.id = action.payload
            
          },
    },
    extraReducers: (builder) => {
        builder.addCase(setActiveEnquiry.fulfilled, (state, action) => {
           
        });
      }
});

// Action creators are generated for each case reducer function
export const { ChangeActiveEnquiry} = activeEnquirySlice.actions

export default activeEnquirySlice