import { iEnquiry } from "../../../interfaces/iEnquiry";
import { useContext } from "react";
import EnquirySummary from "./enquiry-summary";
import { CreateNoteContext } from "../../../contexts/create-note.context";
import { BsMagic, BsSticky } from "react-icons/bs";
import { ActivateWizardModule } from "../../../Logic/Enquiries/activateWizard";
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from '../../../error-boundary/errorFallback'
import { logErrorToService } from '../../../error-boundary/logerror'

export type enquiryListProps = {
    es: iEnquiry[],
    activeEnquiry: number,
    onEnquiryClick: (id: number) => void,
    onEnquiryDoubleClick: (id: number) => void,
    ownerHidden: boolean,
    hasActivateWiz: boolean
}

function EnquiryList(props: enquiryListProps) {

    const createNote = useContext(CreateNoteContext);

    return (
        <div className="enquiry-list"  >
            <ErrorBoundary FallbackComponent={ErrorFallback}>
            <div className="enquiry-list--list scroll-small" id="secList1">
                {props.es.map(item =>
                    <EnquirySummary
                        key={"key_" + item.id}
                        e={{ ...item }}
                        onClick={props.onEnquiryClick}
                        onDoubleClick={props.onEnquiryDoubleClick}
                        active={props.activeEnquiry == item.id}
                        createNote={() => createNote(props.activeEnquiry)}
                        ownerHidden={props.ownerHidden}
                        hasActivateWiz={props.hasActivateWiz}
                      
                    />
                )}
                </div></ErrorBoundary>
        </div>
    )
}

export default EnquiryList;