import { useState } from "react"
import { useEffect } from 'react';
import { setEmailVisible, setWorkflowVisible } from '../../state/secondColSlice'
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from '../../state/store'
import { iLastTouched } from "../../interfaces/lastTouched";
import './index.scss'
import { getActiveEnquiries, getEnquiryById } from "../../interfaces/enquiryList";
import { setTabVisibility } from "../../state/tabRow";
import { getResolvedEnquiriesGlobal, getUnresolvedEnquiriesGlobal } from "../../Logic/Enquiries/My";
import {  setActiveEnquiry } from "../../state/activeEnquiry";
import { setEnquiryAndWorkflowInvisible, setShowWizard } from "../../state/restrictedViewSlice";
import { getSessionValue } from "../../utilities/localStore/calls";
import { showHideLoader } from "../../utilities/loading";
import { resetSort } from "../../utilities/sort";
import DropdownBasic from "../Generic/DropdownBasic";
import { ActivateWizardModule } from "../../Logic/Enquiries/activateWizard";
import { EnquiryBurgerMenu } from "../Menu";
import { burgerMenuMatchLeftPanePos } from "../../Logic/BurgerMenu";
import { useWindowResize } from "../../hooks/useWindowResize";
import { BsArrowClockwise } from 'react-icons/bs';
import useInterval from "../../hooks/useInterval";
function Tabs({ FullWidth }: { FullWidth: boolean }) {
    const User = useSelector((state: RootState) => state.UserInformation);
    const secondCol = useSelector((state: RootState) => state.secondCol);
    const dispatch = useAppDispatch();
    const RestrictedWidthState = useSelector((state: RootState) => state.restrictedView)
    const tabState = useSelector((state: RootState) => state.tabRow.tabs).find(x => x.active == true);
    const activeEnquiry = useSelector((state: RootState) => state.activeEnquiry);
    let eNQ = getEnquiryById(tabState?.enquiryId!);
    const [DropdownLabel, setDropDownLabel] = useState("");
    const [enabled, setEnabled] = useState(true);

    async function getResolvedEnquiries() {
        showHideLoader("flex");
        resetSort()
        let iLastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
        getResolvedEnquiriesGlobal(User.userID, "resolvedTab").then((resp) => {
       
            let activeE = (
                iLastTouched.MyEnquiries.EnquiryLists.find(x => x.Name == "Resolved")!.EnquiryId == 0 ?
                    (resp.enquiries.length > 0 ? resp.enquiries.find((x: { communications: string | any[]; }) => x.communications.length > 0).id.toString() : 0) : iLastTouched.MyEnquiries.EnquiryLists.find(x => x.Name == "Resolved")!.EnquiryId
            ) ;
            if(getActiveEnquiries().enquiries.find(x => x.id==activeE)==undefined && getActiveEnquiries().enquiries.length>0){ // means active enquiry is not in the current list
                activeE = getActiveEnquiries().enquiries[0].id;
            }
           
            if(activeE>0 && secondCol.emailVisible==false){
                dispatch(setEmailVisible(true));
            }
            iLastTouched.MyEnquiries.ActiveList = "Resolved"
            iLastTouched.lastActiveListType = "Resolved"
            sessionStorage.setItem("LastTouched", JSON.stringify(iLastTouched));
            dispatch(setActiveEnquiry(activeE));
            showHideLoader("none");
        }).catch((err) => {
            showHideLoader("none");
        });

    }

    async function getUnresolvedEnquiries() {
        showHideLoader("flex");
        resetSort()
        let iLastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);

       
       await getUnresolvedEnquiriesGlobal(User.userID, "").then((resp) => {
     
                let activeE =  (iLastTouched.MyEnquiries.EnquiryLists.find(x => x.Name == "Unresolved")!.EnquiryId == 0 ?
                    (resp.enquiries.length > 0 ? resp.enquiries.find((x: { communications: string | any[]; }) => x.communications.length > 0).id.toString() : 0) : iLastTouched.MyEnquiries.EnquiryLists.find(x => x.Name == "Unresolved")!.EnquiryId
                ) 
                if(getActiveEnquiries().enquiries.find(x => x.id==activeE)==undefined && getActiveEnquiries().enquiries.length>0){ // means active enquiry is not in the current list
                    activeE = getActiveEnquiries().enquiries[0].id;
                }
             
            if(resp.enquiries.length==0){activeE=-1;}
            
            if(activeE>0 && secondCol.emailVisible==false){
                dispatch(setEmailVisible(true));
            }   dispatch(setActiveEnquiry(activeE));
            iLastTouched.MyEnquiries.ActiveList = "Unresolved"
            iLastTouched.lastActiveListType = "Unresolved"
            sessionStorage.setItem("LastTouched", JSON.stringify(iLastTouched));
          showHideLoader("none");
 
        }).catch((err) => {
            alert(err);
            showHideLoader("none");
        });

    }
    async function refreshInterface() {
        let iLastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
        iLastTouched.lastActiveListType == "Unresolved" ? getUnresolvedEnquiries() : getResolvedEnquiries();
        dispatch(setActiveEnquiry(0));           
    }
    function back() {
        dispatch(setTabVisibility(10.35));
            dispatch(setWorkflowVisible(false));
            dispatch(setEnquiryAndWorkflowInvisible(false))
    }
async function displayActiveEnquiry(){
   if(activeEnquiry.id>11){
        await new ActivateWizardModule().showEnquiry(activeEnquiry.id);
   }
    }
    useInterval(() => refreshInterface(), 60 * 1000);
    useWindowResize(burgerMenuMatchLeftPanePos)
    useEffect(burgerMenuMatchLeftPanePos, [])
    useEffect(() => {
        let iLastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
        let Lists:string[] =  JSON.parse(getSessionValue("ActiveListEnquiries")) ;
        (async function anyName() {
            const RetrieverDataProcess = async () => {
                if (iLastTouched.MyEnquiries.ActiveList == "Unresolved") {
                   
                    if(activeEnquiry.id==0){
                        await getUnresolvedEnquiries();
                    }
                    Lists = JSON.parse(getSessionValue("ActiveListEnquiries"));
                    setDropDownLabel(`Unresolved (${Lists!.length})`);
        
                }
                else if (iLastTouched.MyEnquiries.ActiveList == "Resolved") {
                     
                    if(activeEnquiry.id==0){
                       getResolvedEnquiries();
                    }
                    setDropDownLabel(`Completed (${Lists!.length})`);
                }
            }
            RetrieverDataProcess();
        })();
          
    },[activeEnquiry.id]) // need to add something here to check
    return (
        <>
            <div className="enquiries-navigation" id="MyEnquiriesHeader">
                {
                    ( (FullWidth == false && RestrictedWidthState.showEnquiry==true) && tabState?.enquiryId! < 11) &&
                            <>
                            <div className="col-xs-5">
                                <div className="enquiries-tabs">
                                    
                                
                                </div>
                            </div>
                            <div className="col-xs-7 d-flex align-items-center">
                                    <button className="g-tab g-default-tab-padding" onClick={()=>{back()}}>Enquiry List</button>
                                    <button className="g-tab-selected g-default-tab-padding" >Selected Enquiry</button>
                                    <EnquiryBurgerMenu
                                        key={"burger-menu-dropdown"}
                                        enquiryId={activeEnquiry.id}
                                    />
                                </div>
                            </>
                }
                {
                    ((RestrictedWidthState.showEnquiry  ) && FullWidth == false && tabState?.enquiryId! > 11) &&

                    <>
                    <div className="col-xs-5">
                        <div className="enquiries-tabs">
                            
                        Enquiry Wizard - {eNQ.enquiryReference + " - " + eNQ.subject}
                        </div>
                    </div>
                    <div className="col-xs-7">
                        {RestrictedWidthState.showWizard &&<>
                            <button className="g-tab g-default-tab-padding " onClick={()=>{dispatch(setShowWizard(false))}}>Selected Enquiry</button>
                            <button className="g-tab-selected g-default-tab-padding" >Active Wizard</button></>
                        }
                        {!RestrictedWidthState.showWizard &&<>
                            <button className="g-tab-selected g-default-tab-padding" >Selected Enquiry</button>
                            <button className="g-tab g-default-tab-padding " onClick={()=>{dispatch(setShowWizard(true))}}>Active Wizard</button></>
                        }
                        </div>
                    </>

      
                }

                {
                    (FullWidth == true || activeEnquiry.id < 11 || (FullWidth == false && !RestrictedWidthState.showEnquiry) ) &&
                 
                    <div className="col-xs-5">
                            <div className="enquiries-tabs">
                                <button className="enquiry-refresh-button"  title="Refresh" onClick={
                                    async () => {
                                        if (enabled) {
                                            setEnabled(false);
                                            refreshInterface();
                                            setTimeout(() => {
                                                setEnabled(true);

                                            }, 5000);
                                        }
                                        else {
                                            showHideLoader("flex");
                                            setTimeout(() => {
                                                showHideLoader("none");
                                            }, 1000);
                                        }
                                    }
                                } ><BsArrowClockwise style={{ marginTop: "-2px", cursor: "pointer", rotate: "90deg", width: "14px", height:"14px" }} ></BsArrowClockwise></button>
                                <DropdownBasic id={22} label={DropdownLabel} >
                                    <li><button onClick={() => getUnresolvedEnquiries()}>Unresolved</button></li>    
                                    <li><button onClick={() => getResolvedEnquiries()}>Completed</button></li>    
                                    </DropdownBasic> 
                        </div>
                    </div>
                    
                }
                { 
                     (FullWidth == false && (!RestrictedWidthState.showEnquiry && !RestrictedWidthState.showWizard))  &&
                       <div className="col-xs-7 d-flex align-items-center">
                            <button className="g-tab-selected g-default-tab-padding">Enquiry List</button>
                            <button className="g-tab g-default-tab-padding" onClick={()=>{displayActiveEnquiry()}}>Selected Enquiry</button>
                            <EnquiryBurgerMenu
                                key={"burger-menu-dropdown"}
                                enquiryId={activeEnquiry.id}
                            />
                        </div>
                      
                }
                {FullWidth == true && <div id="enquiry-drpdown" style={{ position: "absolute" }}>
                    <EnquiryBurgerMenu
                        key={"burger-menu-dropdown"}
                        enquiryId={activeEnquiry.id}
                    />
                </div>
            }
            </div>
        </>

    );
}

export default Tabs;