import Statuses from "../interfaces/Statuses";
import { iCommunication, iEnquiries, iEnquiry } from "../interfaces/iEnquiry";
import { iLastTouched } from "../interfaces/lastTouched";
import { saveSessionValue } from "./localStore/calls";

export type SortEnquiriesParams = {
    orderBy: keyof iEnquiry | null | string,
    desc?: boolean
}

export function sortUnresolved(enquiries: iEnquiry[]) {
    return enquiries.sort( // first being new > priority > expiry
        (a: iEnquiry, b: iEnquiry) => {
            if (b.hasNew && !a.hasNew) {
                return 1;
            }
            if (!b.hasNew && a.hasNew) {
                return -1;
            }
            if (b.priority < a.priority) {
                return 1;
            }
            if (b.priority > a.priority) {
                return -1;
            }
            const currentDate = new Date();
            if (new Date(b.activeSlaCycle.timeout).getTime() - (currentDate).getTime() < new Date(a.activeSlaCycle.timeout).getTime() - currentDate.getTime()) {
                return 1;
            }
            if (new Date(b.activeSlaCycle.timeout).getTime() - (currentDate).getTime() > new Date(a.activeSlaCycle.timeout).getTime() - currentDate.getTime()) {
                return -1;
            }

            return 0;
        }
    )
}

export function sortComms(comms: Array<iCommunication>) {
    return comms.sort((a: iCommunication, b: iCommunication) => {
        let dateA = new Date(a.dateStamp);
        let dateB = new Date(b.dateStamp);

        if (dateB > dateA) {
            return 1;
        } else if (dateB < dateA) {
            return -1;
        } else if (b.id > a.id) { // fallback on id/which record was inserted last
            return 1;
        } else if (b.id < a.id) {
            return -1;
        } else {
            return 0;
        }
    })
}

export function sortResolved(enquiries: iEnquiry[]) {
    return enquiries.sort( // first being latest completed
        (a: iEnquiry, b: iEnquiry) => {
            return new Date(b.dateCompleted) > new Date(a.dateCompleted) ? 1 :
                new Date(b.dateCompleted) < new Date(a.dateCompleted) ? -1 : 0
        }
    )
}

export function sortStatus(enquiries: iEnquiry[], desc: boolean = false) {
    return enquiries.slice().sort( // first being latest completed
        (a: iEnquiry, b: iEnquiry) => {
            const statusA = Statuses.Name(a.status).toLocaleLowerCase()
            const statusB = Statuses.Name(b.status).toLocaleLowerCase()
            return desc ? (statusB > statusA ? 1 :
                statusB < statusA ? -1 : 0) : (statusB < statusA ? 1 :
                    statusB > statusA ? -1 : 0)
        }
    )
}

export function sortOwner(enquiries: iEnquiry[], desc: boolean = false) {
    return enquiries.slice().sort( // first being latest completed
        (a: iEnquiry, b: iEnquiry) => {
            const ownerA = a.owners?.length ? (
                a.owners?.[0].lastname ? a.owners?.[0].lastname.toLocaleLowerCase() : (
                    a.owners?.[0].firstname ? a.owners?.[0].firstname.toLocaleLowerCase() : "")
            ) : ""
            const ownerB = b.owners?.length ? (
                b.owners?.[0].lastname ? b.owners?.[0].lastname.toLocaleLowerCase() : (b.owners?.[0].firstname ? b.owners?.[0].firstname.toLocaleLowerCase() : "")
            ) : ""
            return desc ? (ownerB > ownerA ? 1 :
                ownerB < ownerA ? -1 : 0) : (ownerB < ownerA ? 1 :
                    ownerB > ownerA ? -1 : 0)
        }
    )
}

export function sortQueues(enquiries: iEnquiry[]) {
    return enquiries.sort( // first being latest received
        (a: iEnquiry, b: iEnquiry) => {
            return new Date(b.dateReceived) > new Date(a.dateReceived) ? 1 :
                new Date(b.dateReceived) < new Date(a.dateReceived) ? -1 : 0
        }
    )
}

/**
     * Sorts an array of elements by field name.
     * This method copies the array and returns a reference to the copy array.
     * The elements are sorted in ascending, ASCII character order.
     * @param elements List of elements with the same type.
     * @param fieldName The key of the elements in list
     * @param desc Sort by descending order if true, ascending if false or not specified.
     * ```ts
     * interface Person {
     *  name: string,
     *  age: number
     * }
     * const people: Person[] = [
     *  { name: "Bob", age: 41 },
     *  { name: "Alice", age: 30 },
     *  { name: "Charlie", age: 25 },
     * ]
     * const sortedPeopleByName = sortByField(people, "name")
     * // or
     * const sortedPeopleByAgeDesc = sortByField(people, "age", true)
     * ```
     */
export function sortByField<T>(elements: T[], fieldName: keyof T | string, desc: boolean = false): T[] {
    return elements.slice().sort(
        (a, b) => {
            let fieldA = typeof fieldName === "string" ? getInnerFieldValue(a, fieldName) : a[fieldName];
            let fieldB = typeof fieldName === "string" ? getInnerFieldValue(b, fieldName) : b[fieldName];
            fieldA = typeof fieldA === "string" ? fieldA.toLocaleLowerCase() : fieldA;
            fieldB = typeof fieldB === "string" ? fieldB.toLocaleLowerCase() : fieldB;
            return desc ? (fieldB > fieldA ? 1 :
            fieldB < fieldA ? -1 : 0) :
            (fieldB < fieldA ? 1 :
                fieldB > fieldA ? -1 : 0)
        }
    )
}

export function getInnerFieldValue(obj: any, fieldName: string): any {

    const objKeys = fieldName.split('.');
    let result = obj;

    for (const key of objKeys) {
        if (result === undefined) {
            return undefined;
        }

        if (key.includes('[')) {
            // indexed field
            const [arrayField, index] = key.split('[');
            result = result[arrayField][parseInt(index)];
            
        } else {
            result = result[key];
        }
    }

    return result;
}

export const resetSort = () => {
    let LastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
    if (LastTouched) {
        if (LastTouched.lastActiveListType == "Unresolved" || LastTouched.lastActiveListType == "Resolved") {
            const activeList = LastTouched.MyEnquiries.ActiveList
            LastTouched.MyEnquiries.EnquiryLists.find(l => l.Name == activeList)!.orderBy = undefined
        }
        else if ( !isNaN(+LastTouched.lastActiveListType) ) {
            const activeList = LastTouched.Queue.ActiveList
            if (activeList != 0)
            LastTouched.Queue.EnquiryLists.find(l => l.Name == activeList)!.orderBy = undefined
        }
        saveSessionValue("LastTouched", LastTouched);
    }
}