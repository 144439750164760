import * as React from 'react';
import { useEffect } from 'react';
import { iEnquiry } from '../../../interfaces/iEnquiry';
import { useState } from 'react';
import "./enquiry.scss";
import { Bell } from 'react-feather';
import { BsPaperclip } from 'react-icons/bs';
import Statuses from '../../../interfaces/Statuses';
import { dateTimeFormat222 } from './enquiry';
import { EnquiryContextMenu } from '../../Menu';
import { useContextMenu } from '../../../hooks/useContextMenu';


type EnquirySummaryProp = {
	e: iEnquiry,
	onClick: Function,
	onDoubleClick: Function,
	active: boolean,
	ownerHidden: boolean,
	createNote?: () => void,
	hasActivateWiz: boolean
}
function EnquirySummary(props: EnquirySummaryProp) {
	let e = props.e;

	const {contextMenuState, setContextMenuState, closeContextMenu} = useContextMenu()

	const showContextMenu = (enquiryId: number) => (event: React.MouseEvent) => {
		// Disable the default context menu
		event.preventDefault();

		closeContextMenu();
		setContextMenuState({visible: true, x: event.pageX, y: event.pageY})
	};

  	let [currentDate, setCurrentDate] = useState(new Date());
	const getExpiryColour = () => {
		if (e.activeSlaCycle != null && !Statuses.IsCompleted(e.status)) {
			let reminderDate = new Date(e.activeSlaCycle.reminder)
			let alarmDate = new Date(e.activeSlaCycle.alarm)
			let timeoutDate = new Date(e.activeSlaCycle.timeout)
			if (currentDate < reminderDate) {
				return " expiry-green"
			}
			else if (currentDate >= reminderDate && currentDate < alarmDate) {
				return " expiry-orange"
			}
			else if (currentDate >= alarmDate && currentDate < timeoutDate) {
				return " expiry-red"
			}
			else if (currentDate > reminderDate) {
				return " expiry-purple"
			}
		} else {
			return "";
		}
	}
	let [expiryColour, setExpiryColour] = useState(getExpiryColour());



	useEffect(() => {
		var timer = setInterval(() => {
			setCurrentDate(new Date())
			const currentColour = getExpiryColour()
			if (currentColour != expiryColour) {
				setExpiryColour(currentColour)
			}
		}, 60000)
		return function cleanup() {
			clearInterval(timer)
		}
	});
	useEffect(() => {
		setExpiryColour(getExpiryColour())
	});

	useEffect(() => {
		if (props.active) {
			// scrollToView('enquiry-summary-' + e.id, "NewSecList");
			let enquiryContainer: HTMLElement = document.getElementById('NewSecList')!;
			let enquiryDiv: HTMLElement = document.getElementById('enquiry-summary-' + e.id)!;
			if (enquiryContainer != null && enquiryDiv != null) {
				if (enquiryDiv.offsetTop > 400) {
					enquiryContainer.scrollTop = enquiryDiv.offsetTop - 400;
				}
				else {
					enquiryContainer.scrollTop = 0;
				}
				//  enquiryDiv.scrollIntoView();
			}
		}
		//	}
	}, [])

	const barPercentage = (priority: number) => {
		return ((255 - priority) / 255.0) * 100.0;
	}

	return (
		<div
			key={"enquiry-summary-" + e.id}
			id={"enquiry-summary-" + e.id}
			className={"enquiry-summary" +
				(
					(!Statuses.IsCompleted(e.status)) && e.hasNew ? " has-new" : ""
				) + (
					props.active ? " active" : ""
				) + (
					expiryColour
				)
			}
			onClick={() => props.onClick(e.id)}
			onDoubleClick={() => { props.onDoubleClick(e.id)} }
			onContextMenu={showContextMenu(e.id)}
		>
			{(contextMenuState.visible && props.hasActivateWiz) && (
				<EnquiryContextMenu
					x={contextMenuState.x}
					y={contextMenuState.y}
					enquiryId={e.id}
					onHide={closeContextMenu}
				/>
			)}
			<div className="enquiry-summary--vertical-bar"></div>
			<div className="enquiry-summary--icons-col">
				<div className="sla-priority-bar">
					<div style={{ height: barPercentage(e.priority) + "%" }} className="sla-priority--value"></div>
				</div>
				{e.hasAlarm ? <i className='alarm-icon'><Bell className='alarm' /></i> : <div className='sla-bell-icon'></div>}
				{e.hasUnworkedAttachments ? <i><BsPaperclip /></i> : <div className='sla-att-icon'></div>}
			</div>
			<div className="enquiry-summary--enquiry-ref limit-text" title={`Enquiry reference: ${e.enquiryReference}`}>{e.enquiryReference}</div>
			{!props.ownerHidden && <div
				className="enquiry-summary--enquiry-owner limit-text"
				title={`Enquiry owner: ${e.owners.map((item) => { return `"${item.lastname}, ${item.firstname}"` }).join(', ')}`}
			>
				{e.owners.map((item) => { return `${item.lastname}, ${item.firstname}` }).join('; ')}
			</div>}
			<div className="enquiry-summary--enquirer limit-text" title={`Enquirer: ${e.enquirerName}`}>{e.enquirerName}</div>
			<div className="enquiry-summary--subject limit-text" title={`Enquiry subject: ${e.subject}`}>{e.subject}</div>
			<div className="enquiry-summary--status limit-text" title={`Enquiry status: ${Statuses.Name(e.status)}`}>{Statuses.Name(e.status)}</div>
			<div className="enquiry-summary--date-received limit-text" title={`Received date: ${dateTimeFormat222(e.dateReceived)}`}>{dateTimeFormat222(e.dateReceived)}</div>
			<div className="enquiry-summary--expiry limit-text" title={`Expiry date: ${dateTimeFormat222(e.activeSlaCycle.timeout)}`}>{dateTimeFormat222(e.activeSlaCycle.timeout)}</div>
			<div className="enquiry-summary--items limit-text" title={`Work items: ${e.workItems}`}>{e.workItems}</div>
		</div>
	)
}

export default EnquirySummary;