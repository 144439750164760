import { useState } from 'react';
import '../form.scss';
import React from 'react';
 

export interface dateboxProps{
    id:string,
    label?: string,
    value: string,
    onChange : (newValue:string) =>any,
    setFocus?: boolean,
    maxLength:number,
    firstCol?:number,
    noBorder?: boolean
}

const getDefaultDate = () => {
    return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, -14);
}

function DateControl(props: dateboxProps) {
    const [fieldValue, setFieldValue] = useState("");

    React.useEffect(()=>{
            if (props.value == "") {
                let defaultVal = getDefaultDate();
                setFieldValue(defaultVal);
                props.onChange(defaultVal);
            } else {
                setFieldValue(props.value);
            }
      },[props.value]);
    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        setFieldValue(e.currentTarget.value);
        props.onChange(e.currentTarget.value);
    }

    return (
        <div className="row rowMarginControl" key={"Main" + props.id}>
           {props.label && <div className={props.firstCol == undefined ? "col-sm-3" : "col-sm-" + props.firstCol}>
                <label  className='g-label'>
                    {props.label}
                </label>
            </div>}
            <div className={props.firstCol == undefined ? "col-sm-9" : "col-sm-" + (12 - props.firstCol)}>
                <input
                    type="date"
                    className="g-TextBox-default"
                    style={{borderWidth: props.noBorder ? "0px" : "1px"}}
                    onChange={handleInputChange}
                    value={fieldValue}
                    step={60}
                />
           
            </div>
        </div>
    );
}
export default DateControl;

