import React from "react";
import Confirm from "../Generic/ConfirmDialog";
import { iUser } from "../../interfaces/iUser";
import FindDialog, { FindOption } from '../Generic/FindDialog';
import { Post,Get } from "../../utilities/apiCall";
import {AllocateEnquiriesRequest} from "../../interfaces/iMailbox";
import { useEffect} from "react";
import { getEnquiryById } from '../../interfaces/enquiryList';
import { iEnquiry } from "../../interfaces/iEnquiry";
import { GetMailboxUsers } from "../../Logic/Enquiries/Queue";
export interface Props {
    enquiries: Array<iEnquiry>,
    onCancel: () => void,
    onCreated: () => void,
    onError: () => void,
    UserList?: iUser[] | undefined,
}
export interface iGenericOptions {
    id: String,
    userName: string
}
function ChangeEnquiryOwnership(props: Props) {
    const [options, setOptions] = React.useState(props.UserList);
     
    function getMailboxUsers(){
        GetMailboxUsers( props.enquiries[0].mailbox.toString()).then((users) => {
        setOptions(users);
        });
    }
    async function allocateNewUser(value:string){
        if (value != "") {
             
             let allocateEnquiriesDetails: AllocateEnquiriesRequest = {
                MailboxID: props.enquiries[0].mailbox,
                UserID: +value,
                Enquiries:props.enquiries.map(enq=>enq.id)
            }
           await ChangeEnquiryOwnershipResult(allocateEnquiriesDetails);
           props.onCreated();
        } 

    }
    useEffect(() => {
         getMailboxUsers();
    },[props.enquiries]);

    let genericOptions:Array<iGenericOptions>;
    genericOptions = options?.map(item=>({id:item.id.toString(),userName:item.firstname  + " "+ item.lastname}))!
    let RemoveOptions:iGenericOptions={ id:"zzz",userName:"<Remove all Ownership>"};
    genericOptions?.push(RemoveOptions);
    genericOptions?.sort((a,b) => a.userName>b.userName? 1:-1)
    
    return (<>        
  {genericOptions != undefined && genericOptions.length > 0 ?
<FindDialog  
                     
                    onCancel={() => props.onCancel()}
                    onConfirm={(selectedValue) => {
                        if (selectedValue !== ""){
                            allocateNewUser(selectedValue);
                        }
                    }}
                    values={genericOptions!.map<FindOption>(x => {
                        return {
                            label: x.userName,
                            value: x.id.toString(),
                        };
                    })}/>:
       
       <></>
}    
 </>
 )
}
export default ChangeEnquiryOwnership;

async function ChangeEnquiryOwnershipResult(allocationdetails: AllocateEnquiriesRequest) {

    var response = await Post("Mailboxes/" + allocationdetails.MailboxID + "/Enquiries/Allocate", allocationdetails);
    return response;
}