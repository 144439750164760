import SecondRow from './secondRow';
import CommPreview from '../../Email/switch'
import Workflow from '../../Workflow';
import { Col, Row } from 'react-bootstrap';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { RootState, useAppDispatch } from '../../../state/store';
import { setWorkflowPopped, setWorkflowVisible } from '../../../state/secondColSlice';
import { useSelector } from 'react-redux';
import { iEnquiry } from '../../../interfaces/iEnquiry';
import { removeTab } from '../../../state/tabRow';
import { iWizard, iWizards } from '../../../interfaces/wizard';
import { getSessionValue, saveSessionValue } from '../../../utilities/localStore/calls';
import { getEnquiryById, refreshList } from '../../../interfaces/enquiryList';
import { getPreference, iPreference, savePrefence } from '../../../interfaces/preference';
import { useEffect } from 'react';
import { closeConnection } from '../../../utilities/userProcessing';
import Statuses from '../../../interfaces/Statuses';
import { deleteEnquiryMailDrafts, deleteItem } from '../../../utilities/localStore/indexDBCalls';
import { setActiveEnquiry } from '../../../state/activeEnquiry';
import { startDrag } from '../../../Logic/Resize/horizontal';
import { setLastCommunication } from '../../../Logic/LastTouched/comm';
import { removeNewEnquiryId } from '../../../Logic/Enquiries/activateWizard';
import { removePopped } from '../../../Logic/Enquiries/activateWizard';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../../../error-boundary/errorFallback';
import { defaultReset } from '../../../error-boundary/logerror';
export function ConfirmExit(event:any){
    event.returnValue = "save first"
}

function WorkflowLayout(props: any) {
    const dispatch = useAppDispatch();
    const UserInfo = useSelector((state: RootState) => state.UserInformation);
    const internetConnection = useSelector((state: RootState) => state.Connection);
    let enQ: iEnquiry = props.Enquiry;
    const activeEnquiry = useSelector((state: RootState) => state.tabRow.tabs).find(i => i.active == true);

    if (enQ == undefined) {
        enQ = getEnquiryById(activeEnquiry?.enquiryId!)
    }
    if (enQ.status != Statuses.Processing.ID) {
        enQ.status = Statuses.Processing.ID;
        enQ.isCompleted = false
    }
    /*
    window.addEventListener("beforeunload", ConfirmExit);

*/
    window.addEventListener('message', (event) => {
        if (event.data?.msg && event.data.msg == 'cancel') {
            removeNewEnquiryId(event.data.EnquiryId)
              refreshList(UserInfo.userID).then(() => {
                    removePopped(event.data.EnquiryId);
                    dispatch(setActiveEnquiry(0));
                    dispatch(setWorkflowPopped(false));
                    dispatch(setWorkflowVisible(false));
                }).catch((err) => {
                    console.log(err)
                    removePopped(event.data.EnquiryId);
                    dispatch(setActiveEnquiry(0));
                    dispatch(setWorkflowPopped(false));
                    dispatch(setWorkflowVisible(false));
                })
          
        }
        else if (event.data?.msg && event.data.msg == 'afterAction') {
            removeNewEnquiryId(event.data.EnquiryId)
            setLastCommunication(event.data.EnquiryId, 0);
            removePopped(event.data.EnquiryId);
            dispatch(setActiveEnquiry(event.data.EnquiryId));
            dispatch(setWorkflowPopped(false));
            dispatch(setWorkflowVisible(false));
        }
    });

    async function popWorkflow() {
       await closeConnection(enQ.id);
        dispatch(setWorkflowPopped(true));
        dispatch(setWorkflowVisible(false));
        let allWizards: iWizards = JSON.parse(getSessionValue("poppedWizards"));
        let wizard: iWizard = {
            popped: true,
            id: enQ.id,
            enquiry: enQ,
        };
        if (allWizards == undefined) {
            allWizards = {
                wizards: [wizard]
            };
            saveSessionValue("poppedWizards", allWizards);
        } else {
            let enQ1 = allWizards.wizards.find(x => x.id == (enQ.id)) ? allWizards.wizards?.find(x => x.id == (enQ.id))!.enquiry! : undefined;
            if (enQ1 == undefined) {
                allWizards.wizards.push(wizard);
            }
            saveSessionValue("poppedWizards", allWizards);
        }

        var windowA = window.open(window.location.href + "/Workflow/" + enQ.id, "popped-wizard-" + enQ.id, "location=no;toolbar=yes,scrollbars=yes,resizable=yes,top=10,left=10,width=700,height=600");
        dispatch(removeTab(enQ.id));
        window.setTimeout(() => {
            windowA!.onbeforeunload = function (e) {
                dispatch(setWorkflowPopped(false));
                dispatch(setWorkflowVisible(true));
                e.preventDefault();
                //e.returnValue="";
                windowA?.close();
            };
        }, 1000)
    }
  
    useEffect(() => {
        let preference: iPreference = getPreference();
        if (preference.interface.LeftPane == undefined || preference.interface.RightPane == undefined) {
            preference.interface.LeftPane = 50;
            preference.interface.RightPane = 50
            savePrefence(preference);
        }
        document.getElementById("leftPane")!.style.width = preference.interface.LeftPane + "%";
        document.getElementById("rightPane")!.style.width = preference.interface.RightPane + "%";
    }, []);

    useEffect(() => {
        window.addEventListener("beforeunload", function (event) {
            dispatch(setWorkflowPopped(false));
            dispatch(setWorkflowVisible(true));
            event.preventDefault();
        })
    }, []);
    return (
        <>
            <Row className="secondRowWorkflow">
                <div>
                    Enquiry Wizard - {enQ.enquiryReference + " - " + enQ.subject}
                </div>
                <div>
                    {props.FullWidth ? <div style={{ display: enQ ? "block" : "none", textAlign: "right", paddingBottom: "4px", paddingRight:"11px" }}    >

                        <i style={{ fontSize: "10pt" }} id="popOutWorkflowIcon" onClick={() => { popWorkflow() }} ><BsBoxArrowUpRight></BsBoxArrowUpRight></i>
                    </div> : null}
                </div>
            </Row>

            <div className='MainFlexRow' id="mainPane">
                <div id="leftPane" className='firstColFullWidth ' style={{ overflowY: "auto",paddingLeft:"10px" }}>
                    <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={defaultReset} 
                    >
                      <CommPreview FullWidth={true} enquiry={enQ} hasActivateWiz={false}/>
                    </ErrorBoundary>
                </div>
                <div id="rightPane" className='secondCol ' style={{paddingRight:"10px", display:"flex"}}>
                    <div id="resize" onMouseDown={(event) => startDrag(event)}  ></div>
                    <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={defaultReset} 
                    >
                        <Workflow Popped={false} FullWidth={true} Enquiry={enQ} ></Workflow>
                    </ErrorBoundary>
                </div>
            </div>
        </>
    )

};

export default WorkflowLayout;