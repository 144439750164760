import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { iAuth, iTenant } from "../../interfaces/auth";
import { useEffect } from "react";
import { getSessionValue } from "../../utilities/localStore/calls";

interface props {
    children: JSX.Element[] | JSX.Element | undefined,
}

function Index(props: props) {
    const User = useSelector((state: RootState) => state.UserInformation);
    let crypto: iAuth = JSON.parse(localStorage.getItem("auth")!);

    useEffect(() => {
        const checkCrypto = async () => {
            var urlParts = window.location.href.substring((window.location.href.indexOf("://")+3)).split("/");
            let redirectURL = ""
            if(urlParts.length>1 && urlParts[1]!=''){
                redirectURL = "?_referer="+ urlParts[1];
            }
            if(crypto == null){
                window.location.href="/auth/callback/" + redirectURL;
            }
            else if(crypto.code==""){
                window.location.href="/auth/callback/" + redirectURL;
            }else if(User.userID==0){
                window.location.href="/" + redirectURL;
            }
            else if(User.userID>0){
                let tenant:iTenant = JSON.parse(getSessionValue("tenant"));
                if(tenant==null){
                    window.location.href="/auth/callback/" + redirectURL;
                }
                else if(urlParts[1].toLowerCase()!=tenant.key.toLowerCase()){
                    sessionStorage.clear(); //session is cleared because if you choose another tenant from the list then the previous selection must be cleare
                    window.location.href="/auth/callback/" + redirectURL;
                }
            }
        }
        checkCrypto();
    });

    return (
        <>
            {(
                sessionStorage.getItem("tenant") != null ? props.children : null
            )}
        </>
    );
}

export default Index;