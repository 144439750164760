import { useState } from 'react';
import '../form.scss';
import React from 'react';

export interface numericBoxProps{
    id:string,
    label: string,
    value: number|string,
    onChange : (newValue:string) =>any,
    setFocus?: boolean,
    maxLength:number,
    min?:number,
    max?:number,
    firstCol?: number,
    isNotValid?: boolean
    requiredIdentifier?:boolean
}
function Numeric(props: numericBoxProps) {
 
    const [fieldValue, setFieldValue] = useState(props.value)

    React.useEffect(()=>{
            setFieldValue(props.value)
      },[props.value]);

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        setFieldValue(e.currentTarget.value);
        props.onChange(e.currentTarget.value);
    }
   
    const minVal = props.min ?? -32768;
    const maxVal = props.max ?? 32767;

 
    return (
        <div className="row rowMarginControl" key={"Main" + props.id}>
            {props.label && <div className={props.firstCol == undefined ? "col-sm-3" : "col-sm-" + props.firstCol}>
                <label  className='g-label'>
                    {props.label}
                </label><small className="asterisk">{(props.requiredIdentifier ? "*" : "")}</small>
            </div>}
            <div className={props.firstCol == undefined ? "col-sm-9" : "col-sm-" + (12 - props.firstCol)}>
                <input
                    type="number"
                    className={!(props.isNotValid) ? "g-TextBox-default" : "g-TextBox-default validationFailed"} 
                    onChange={handleInputChange}
                    value={fieldValue}
                    step={1}
                    min={minVal}
                    max={maxVal}
                />
           
            </div>
        </div>
    );
}
export default Numeric;

