import { BsChevronDown, BsChevronUp, BsPaperclip, BsThermometerHalf } from 'react-icons/bs';
import { iEnquiry } from '../../interfaces/iEnquiry';
import './enquiry.scss'
import EnquiryList from './enquiry/enquiry-list';
import { Bell } from 'react-feather';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { SortEnquiriesParams } from '../../utilities/sort';
import { useEffect } from 'react';
import { scrollToView } from '../../interfaces/enquiryList';

export type enquiriesProps = {
    es: iEnquiry[]
    onSortButton: (fieldName: string) => void,
    sortBy?: SortEnquiriesParams,
    activeEnquiry: number,
    onEnquiryClick: (id: number) => void,
    onEnquiryDoubleClick: (id: number) => void,
    ownerHidden: boolean,
    hasActivateWiz: boolean
}

function Enquiries(props: enquiriesProps) {
    const activeEnquiry = useSelector((state: RootState) => state.activeEnquiry);
    const SiteInformation = useSelector((state: RootState) => state.SiteInformation);

    useEffect(() => {
        return () => {
            scrollToView('enquiry-summary-' + activeEnquiry.id, "NewSecList");
        }
    }, [props.sortBy?.orderBy, props.sortBy?.desc])

    useEffect(() => {
        return () => {
            scrollToView('enquiry-summary-' + activeEnquiry.id, "NewSecList");
        }
    }, [])

    return (

        <>
            <div className="enquiryContainer" id="EnquiryContainer" >
            <div className="enquiry-list--header"  >
                <div id='hasNew' onClick={() => props.onSortButton("hasNew")} className="enquiry-list--header--bar enquiry-list-header"></div>

                <div className="enquiry-list--header--icons">
                    <i id='priority' onClick={() => props.onSortButton("priority")} className='enquiry-list-header thermometer'>
                        <BsThermometerHalf />
                    </i>

                    <i id='hasAlarm' onClick={() => props.onSortButton("hasAlarm")} className="alarm-icon enquiry-list-header">
                        <Bell className="alarm" />
                    </i>

                    <i id='hasUnworkedAttachments' onClick={() => props.onSortButton("hasUnworkedAttachments")} className='enquiry-list-header atatchment'>
                        <BsPaperclip />
                    </i>
                </div>

                <div id='enquiryReference' onClick={() => props.onSortButton("enquiryReference")} className="enquiry-list--header--ref enquiry-list-header">
                    <div className="limit-text">Reference</div>
                    {props.sortBy?.orderBy == "enquiryReference" && <i className="sort-icon">
                        {props.sortBy.desc ? <BsChevronDown className="sort-arrow" /> : <BsChevronUp className="sort-arrow" />}
                    </i> }
                </div>

                {!props.ownerHidden && <div onClick={() => props.onSortButton("owner")} className="enquiry-list--header--owner enquiry-list-header">
                    <div className="limit-text">Owner</div>
                    {props.sortBy?.orderBy == "owner" && <i className="sort-icon">
                        {props.sortBy.desc ? <BsChevronDown className="sort-arrow" /> : <BsChevronUp className="sort-arrow" />}
                    </i>}
                </div>}

                <div id='enquirerName' onClick={() => props.onSortButton("enquirerName")} className="enquiry-list--header--enquirer enquiry-list-header">
                    <div className="limit-text">Enquirer</div>
                    {props.sortBy?.orderBy == "enquirerName" && <i className="sort-icon">
                        {props.sortBy.desc ? <BsChevronDown className="sort-arrow" /> : <BsChevronUp className="sort-arrow" />}
                    </i>}
                </div>

                <div id='subject' onClick={() => props.onSortButton("subject")} className="enquiry-list--header--subject enquiry-list-header">
                    <div className="limit-text">Subject</div>
                    {props.sortBy?.orderBy == "subject" && <i className="sort-icon">
                        {props.sortBy.desc ? <BsChevronDown className="sort-arrow" /> : <BsChevronUp className="sort-arrow" />}
                    </i>}
                </div>

                <div onClick={() => props.onSortButton("status")} className="enquiry-list--header--status enquiry-list-header">
                    <div className="limit-text">Status</div>
                    {props.sortBy?.orderBy == "status" && <i className="sort-icon">
                        {props.sortBy.desc ? <BsChevronDown className="sort-arrow" /> : <BsChevronUp className="sort-arrow" />}
                    </i>}
                </div>

                <div id='dateReceived' onClick={() => props.onSortButton("dateReceived")} className="enquiry-list--header--received enquiry-list-header">
                    <div className="enquiry-list--header--received--date">Received</div>
                    {(props.sortBy?.orderBy == "dateReceived" || (SiteInformation.queuesListVisible && (!props.sortBy || props.sortBy.orderBy == null))) && <i className="sort-icon">
                        {props.sortBy?.desc || (SiteInformation.queuesListVisible && props.sortBy?.orderBy == null) ? <BsChevronDown className="sort-arrow" /> : <BsChevronUp className="sort-arrow" />}
                    </i> }
                </div>

                <div id='activeSlaCycle.timeout' onClick={() => props.onSortButton("activeSlaCycle.timeout")} className="enquiry-list--header--expiry enquiry-list-header">
                    <div className="limit-text">Expiry</div>
                    {props.sortBy?.orderBy == "activeSlaCycle.timeout" && <i className="sort-icon">
                        {props.sortBy.desc ? <BsChevronDown className="sort-arrow" /> : <BsChevronUp className="sort-arrow" />}
                    </i> }
                </div>

                <div id='workItems' onClick={() => props.onSortButton("workItems")} className="enquiry-list--header--items limit-text enquiry-list-header">
                    <div className="limit-text">Items</div>
                    {props.sortBy?.orderBy == "workItems" && <i className="sort-icon">
                        {props.sortBy.desc ? <BsChevronDown className="sort-arrow" /> : <BsChevronUp className="sort-arrow" />}
                    </i>}
                </div>
            </div>
                {props.es.length>0 ? 
                    <EnquiryList
                        es={props.es}
                        activeEnquiry={props.activeEnquiry}
                        onEnquiryClick={props.onEnquiryClick}
                        onEnquiryDoubleClick={props.onEnquiryDoubleClick}
                        hasActivateWiz={props.hasActivateWiz}
                        ownerHidden={props.ownerHidden}
                    /> :


                      
                    <div className="row" id="noResults" style={{ display: props.es?.length > 0 ? 'none' : 'flex' }}>
                            <div className="col-sm-12" style={{ fontSize: '9pt', borderBottom: '1px Solid #ccc' }}>
                                No enquiries found
                            </div>
                    </div>
                }

            </div>
        </>

    );
}

export default Enquiries;