import { useState } from 'react';
import '../form.scss';
import React from 'react';

export interface dateboxProps{
    id:string,
    label: string,
    value: string,
    onChange : (newValue:string) =>any,
    setFocus?: boolean,
    maxLength:number,
    firstCol?:number
}

const getDefaultDateTime = () => {
    const defaultDate = new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)
    defaultDate.setSeconds(0, 0);
    return defaultDate.toISOString().slice(0, -5);
}

function DateTime(props: dateboxProps) {
    const [fieldValue, setFieldValue] = useState("");

    React.useEffect(() => {
            if (props.value == "") {
                let defaultVal = getDefaultDateTime();
                setFieldValue(defaultVal);
                props.onChange(defaultVal);
            } else {
                setFieldValue(props.value);
            }
      },[props.value]);

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        setFieldValue(e.currentTarget.value);
        props.onChange(e.currentTarget.value);
    }

    return (
        <div className="row rowMarginControl" key={"Main" + props.id}>
           <div className={props.firstCol == undefined ? "col-sm-3" : "col-sm-" + props.firstCol}>
                <label  className='g-label'>
                    {props.label} 
                </label> 
            </div>
            <div className={props.firstCol == undefined ? "col-sm-9" : "col-sm-" + (12 - props.firstCol)}>
                <input
                    type="datetime-local"
                    className="g-TextBox-default"
                    onChange={handleInputChange}
                    value={fieldValue}
                    step={60}
                />
           
            </div>
        </div>
    );
}
export default DateTime;

