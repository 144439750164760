import { saveEnquiryLists } from "../../interfaces/enquiryList";
import { iCommunication } from "../../interfaces/iEnquiry";
import { callApi, iRequest } from "../../utilities/apiCall";
import { setItemEnabled } from "../../utilities/loading";
import { GetMap,  SaveMap,  saveSessionValue } from "../../utilities/localStore/calls";
import { sortComms, sortResolved, sortUnresolved } from "../../utilities/sort";

export async function getUnresolvedEnquiriesGlobal(userId?: number, id: string = "", tenant?:string): Promise<any> {

    setItemEnabled(id, false)
    var promise = new Promise<any>((resolve, reject) => {

        if (document.getElementById("loader")) {
            (document.getElementById("loader") as HTMLElement)!.style.display = "block";
        }
        let request: iRequest = {
            url: "Enquiries/My",
            method: "GET",
            headers: [],
            body: "",
            responseType: "json"
        }
        callApi(request,tenant).then((resp) => {
            let enQuiryMap = GetMap("Enquiries");
            let ActiveList: string[] = [];
            let response = sortUnresolved(resp.enquiries).map(
                (enq: any) => {
                    sortComms(enq.communications);
                    enQuiryMap.set(enq.id.toString(),{"CachedAt": new Date(), "Enquiry":enq});
                    ActiveList.push(enq.id.toString());
                    return { ...enq, isCompleted: false }
                })
            
            SaveMap("Enquiries",enQuiryMap);
            saveSessionValue("ActiveListEnquiries",ActiveList);
            setItemEnabled(id, true)
            resolve(resp);
        }).catch((err) => {
            reject(err);
            setItemEnabled(id, true)
        });
    })
    return promise;

}

export async function getResolvedEnquiriesGlobal(userId?: number, id: string = ""): Promise<any> {
    // const UserInfo = useSelector((state: RootState) => state.UserInformation);
    setItemEnabled(id, false)
    var promise = new Promise<any>((resolve, reject) => {
        if (document.getElementById("loader")) {
            (document.getElementById("loader") as HTMLElement)!.style.display = "block";
        }
        let request: iRequest = {
            url: "Enquiries/My?getCompleted=true",
            method: "GET",
            headers: [],
            body: "",
            responseType: "json"
        }
        callApi(request).then((resp) => {
            let enQuiryMap = GetMap("Enquiries");
            let ActiveList: string[] = [];
            let response = sortResolved(resp.enquiries).map(
                (enq: any) => {
                    sortComms(enq.communications);
                    enQuiryMap.set(enq.id.toString(),{"CachedAt": new Date(), "Enquiry":enq});
                    ActiveList.push(enq.id.toString());
                    return { ...enq, isCompleted: true }
                }
            )
            SaveMap("Enquiries",enQuiryMap);
            saveSessionValue("ActiveListEnquiries",ActiveList);
            setItemEnabled(id, true)
            resolve(resp);
        }).catch((err) => {
            reject(err);
            setItemEnabled(id, true)
        });

    })
    return promise;
}