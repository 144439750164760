import { useEffect, useState } from "react";
import { iAuth, iTenant, iTenants } from "../../interfaces/auth";
import { useAppDispatch } from "../../state/store";
import { UpdateAuthTokens, UpdateConfiguration, getRecentTenants, getTenantAuth, getTenants, getToken, logout, refreshIDPToken, saveRecentTenant, setTenantContext } from '../../Logic/Auth/TokenManager';
import { useNavigate } from "react-router-dom";
import '../template/styles/generic-components.scss';
import { saveSessionValue } from "../../utilities/localStore/calls";

function Auth() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [tenants, setTenants] = useState<iTenants>({ tenants: [] });
  
  useEffect(() => {
    var urlParts = window.location.href.substring((window.location.href.indexOf("://")+3)).split("/");
    let redirectURL = ""
    if(urlParts.length>1 && urlParts[1]!=''){
        redirectURL = "?_referer="+ urlParts[1].replace("?_referer=","");
    }
    let crypto: iAuth = JSON.parse(localStorage.getItem("auth")!);
    if(crypto===null){
        navigate("/auth/callback/"+redirectURL)
    }else if
     (crypto!=null){
      if (crypto.code == '') {
      
        navigate("/auth/callback/"+redirectURL);
    } else {
      getTentantsAccess();
    }}
  }, [localStorage.getItem("auth")]);

  const getTentantsAccess = () => {
    getTenants().then(
      (tData) => {

        saveSessionValue("tenant",{
            name:"",
            key: "",
            token: "",
            accessToMultipleTenants:  tData.length>1?  true : false
        });


        if (window.location.href.indexOf("?_referer=") > 0 && tData.length > 0) {
          const tenant = tData.filter(x=> x.key.toLowerCase()== window.location.href.substring(window.location.href.indexOf("?_referer=") + 10).toLowerCase());
          if(tenant.length>0){
            getTenantAccess(tenant[0]);
          } else {
            getTenantA(tData);
          }
        }
        else {
          getTenantA(tData);
        }
      }
    ).catch(async (err) => {
      if( err.message=="401"){
        await refreshIDPToken();
        getTentantsAccess();
      }
    })

    function getTenantA(tData: any) {
      if (tData.length == 1) {
        getTenantAccess(tData[0]);

      }
      else if (tData.length == 0) {

      }
      else {
        setTenants({ tenants: [...tData] });
      }
    }

  }

  function getTenantAccess(tenant: iTenant) {
    if (document.getElementById("loader") != undefined) {
      (document.getElementById("loader") as HTMLElement).style.display = "flex";
    }

    let crypto: iAuth = JSON.parse(localStorage.getItem("auth")!);
      getTenantAuth(crypto, tenant.key).then((tenantContext) => {
        if (tenantContext.mailboxes?.length === 0)
            navigate("/unauthorised");

        setTenantContext(tenant, tenantContext);
        UpdateConfiguration(tenant.key);
        setTimeout(UpdateAuthTokens, 55 * 60 * 1000);
        navigate("/" + tenant.key);
   
    }).catch(async (err) => {
        if(err=="401"){
           await refreshIDPToken();
           getTenantAccess(tenant);
        }
        else  if(err=="403"){
            window.location.href=window.location.href;
        }
      navigate("/unauthorised")
    })
  }
 
  let lastRecentTenants: iTenant[] = getRecentTenants();
  const handleTenantSelection = (tenant: iTenant) => {
    saveRecentTenant(tenant)
    getTenantAccess(tenant)
  }
 
  return (
    <>
  
      {(localStorage.getItem("auth")!=null) && tenants.tenants.length > 1 && <>
        <div className="multi-tenant-wrap">
          <div className="multi-tenant-login">
            <div className="multi-tenant-login--head">
              <div className="multi-tenant-login--head--logo">

              </div>
              <div className="multi-tenant-login--head--ems-header">EMS Cloud</div>
            </div>
            <div className="multi-tenant-login--tenants">
              <div className="multi-tenant-login--tenants--header">Available tenants</div>
              <div className="multi-tenant-login--tenants--lists">
                {lastRecentTenants.length > 0 && <div className="tenant-select-recent">
                  <p className="multi-tenant-login--tenants--lists--list-header">Recent</p>
                  <div className="recent-tenants scroll-small">
                    {
                      lastRecentTenants.map(tenant => <div className="tenant-select--option" key={tenant.key} onClick={() => handleTenantSelection(tenant)} >
                        <div className="tenant-select--option--label">{tenant.name}</div>
                      </div>)
                    }
                  </div>
                </div>}
                <div className="all-list">
                  <p className="multi-tenant-login--tenants--lists--list-header">All</p>
                  <div className="tenant-select scroll-small">
                    {
                      tenants.tenants.map(tenant => <div className="tenant-select--option" key={tenant.key} onClick={() => handleTenantSelection(tenant)} >
                        <div className="tenant-select--option--label">{tenant.name}</div>
                      </div>)
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      
                } 
      
      <div style={{
        height: "100%", width: "100%", zIndex: 2147483647,
        position: "absolute", top: "0px", left: "0px",
        display: "none", cursor: "wait",
        justifyContent: "center"
      }} id="loader">
        <div className='notice loading'>Still busy. Please wait...</div>
      </div>
    </>
  )
}
export default Auth;