import * as React from 'react';
import { Fragment, useState } from 'react';
import './Workflow.scss';
import Step from './template'
import { Dispatch } from "redux";
import { useAppDispatch } from '../../state/store'
import RadioButtonRow from '../Generic/Form/RadioButtonRow';
import { EStepKey } from '../../Logic/Wokflow/stepLogic';
import { WizardStepProps } from './stepType';
import { WizardContext } from '../../contexts/wizard-context';

function Step1(props: WizardStepProps) {
  let {wizard, onNext, setWizard, onBack} = React.useContext(WizardContext)!;
  wizard = JSON.parse(JSON.stringify(wizard));
  const [step, setStep] = React.useState(wizard?.workflow.data.EnquiryTracker);
  const handleClick = (option: string) => {
    setStep(option);
  }
  const handleDoubleClick = (option: string) => {
    setStep(option);
    nextFunc();
  }

  const nextFunc = () => {

    if (step) {
    setWizard(currentState=>{
        currentState = JSON.parse(JSON.stringify(currentState));
        currentState.workflow.data.EnquiryTracker = step;
        if (step == 'link to existing enquiry') {
            currentState.workflow.data.ExistingEnquiryID = 0;
        }
        else {
            currentState.workflow.data.ExistingEnquiryID = undefined;
        }
      return currentState;
    }) ;
      onNext();
    }
    else {
      alert("please complete the required fields")
    }

  }
   
  
  return (
    <Fragment>

      <Step title="Enquiry Tracker" nextF={nextFunc} step={EStepKey.ENQUIRY_TRACKER_STEP}   EnquiryId={props.EnquiryId}  
                Popped = {props.Popped}
                FullWidth = {props.FullWidth}>
        <div className="enquiry-tracter--header">
          The incoming email message does not have any embedded reference number tags. Please review the email and select the appropriate action based on its content.
        </div>
        <div className='enquiry-tracter--options'>
          <RadioButtonRow
            value={'new enquiry'}
            key={'new enquiry'}
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
            label='This is a new Enquiry'
            selectedValue={step}
            tooltip='Double click to advance'
            infoTooltip='Select this if the Enquirer is asking a new question and is not referring to an existing enquiry'
          />
          <RadioButtonRow
            value={'link to existing enquiry'}
            key={'link to existing enquiry'}
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
            label={"Link to existing Enquiry"}
            selectedValue={step}
            tooltip='Double click to advance'
            infoTooltip='Select this option when the Enquirer refers to an existing enquiry.'
          />
          <RadioButtonRow
              value={'assign enquiry to another mailbox user'}
              key={'assign enquiry to another mailbox user'}
              onClick={handleClick}
              onDoubleClick={handleDoubleClick}
              label={"Assign Enquiry to another mailbox user"}
              selectedValue={step}
              tooltip='Double click to advance'
              infoTooltip='Select this to assign the popped enquiry to another mailbox user'
            />
        </div>
      </Step>

 
    </Fragment>
  );
}

export default Step1;
