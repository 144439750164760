 
import { AdvancedSearchResults } from "../../interfaces/AdvancedSearch/AdvancedSearchResults";
import Statuses, { Status } from "../../interfaces/Statuses";
import { setSearchResultsVisibility } from "../../state/siteSlice";
import { RootState, useAppDispatch } from "../../state/store";
import { useSelector } from "react-redux";
import { setShowEnquiry, setShowWizard } from "../../state/restrictedViewSlice";
import { getEnquiryById } from "../../interfaces/enquiryList";
import { EnquiryBurgerMenu } from "../Menu";
import { useEffect } from "react";
import { burgerMenuMatchLeftPanePos } from "../../Logic/BurgerMenu";
import { useWindowResize } from "../../hooks/useWindowResize";
import { BsArrowClockwise } from 'react-icons/bs';
import { useState } from "react"
import { showHideLoader } from "../../utilities/loading";
import { setActiveEnquiry } from '../../state/activeEnquiry';
function Query({ FullWidth }: { FullWidth: boolean }) {
    const dispatch = useAppDispatch();
    const RestrictedWidthState = useSelector((state: RootState) => state.restrictedView)
    let sValues: JSX.Element[] = [];
    const activeEnquiry = useSelector((state: RootState) => state.activeEnquiry);
    const searchResults: AdvancedSearchResults = JSON.parse(sessionStorage.getItem("advancedSearchResults")!);
    const tabState = useSelector((state: RootState) => state.tabRow.tabs).find(x=>x.active==true);
    const resultsCount = searchResults?.Results?.length ?? 0;
    let eNQ = getEnquiryById(tabState?.enquiryId!);
    const [enabled, setEnabled] = useState(true);
    searchResults !== null && Object.entries(searchResults.Query).filter(x => x[1].Enabled && x[1].Value !== undefined).forEach((item)=>{
        let displayValue: string | undefined = undefined;
        const value = item[1].Value;

        if (item[1].Label === "Enquiry Status") {
            const status = Object.entries(Statuses).map(x => x[1] as Status).find(x => x.ID === parseInt(value))
            displayValue = status!.Name;
        } else if (typeof value === "string" && value.length > 0) {
            displayValue = value;
        } else if (typeof value === "number") {
            displayValue = value.toString();
        } else if (value.id !== undefined && value.firstname !== undefined) {
            displayValue = value.firstname + " " + value.lastname;
        }

        if (displayValue !== undefined) {
            sValues.push(<div className="g-badge" key={item[1].Label.toString().replace(" ", "_")}>{item[1].Label}: {displayValue}</div>)
        }
    });
    function refreshInterface() {
        dispatch(setShowEnquiry(false));
        dispatch(setSearchResultsVisibility());
        dispatch(setActiveEnquiry(0));           
    }
    function back() {
        dispatch(setShowEnquiry(false));
        dispatch(setSearchResultsVisibility());
    }
    async function displayActiveEnquiry(){
        dispatch(setShowEnquiry(true));
    }

    useWindowResize(burgerMenuMatchLeftPanePos)
    useEffect(burgerMenuMatchLeftPanePos, [])
    return (
    <> <div className="enquiries-navigation" id="MyEnquiriesHeader">

                {
(activeEnquiry.id>11 &&  FullWidth==false && tabState?.enquiryId!>11) &&

      <>
      <div className="col-xs-5">
          <div className="enquiries-tabs">
              
          Enquiry Wizard - {eNQ.enquiryReference + " - " + eNQ.subject}
          </div>
      </div>
      <div className="col-xs-7">
          {RestrictedWidthState.showWizard &&<>
              <button className="g-tab g-default-tab-padding" onClick={()=>{dispatch(setShowWizard(false))}}>Selected Enquiry</button>
              <button className="g-tab-selected g-default-tab-padding" >Active Wizard</button></>
          }
          {!RestrictedWidthState.showWizard &&<>
              <button className="g-tab-selected g-default-tab-padding" >Selected Enquiry</button>
              <button className="g-tab g-default-tab-padding" onClick={()=>{dispatch(setShowWizard(true))}}>Active Wizard</button></>
          }
          </div>
      </>
   }
       {( FullWidth == false && !RestrictedWidthState.showEnquiry) &&<>
           <div className="col-xs-5">
                <div className="enquiries-tabs">
                <div style={{ float: "left", marginRight: "8px" }}>Showing [{resultsCount}] result(s)   </div>  
                </div>
            </div>
              <div className="col-xs-7 d-flex align-items-center">
                  <button className="g-tab-selected g-default-tab-padding" >Enquiry List</button>
                  {searchResults != null && searchResults.Results.length>0 &&
                  <button className="g-tab g-default-tab-padding" onClick={()=>{displayActiveEnquiry()}}>Selected Enquiry</button>
                }
                <EnquiryBurgerMenu
                    key={"burger-menu-dropdown"}
                    enquiryId={activeEnquiry.id}
                />
          </div></>
          
       }  
        {( FullWidth == false && RestrictedWidthState.showEnquiry && tabState?.enquiryId!<11) &&<>
           <div className="col-xs-5">
                <div className="enquiries-tabs">
                    
                <div style={{ float: "left", marginRight: "8px" }}>Showing [{resultsCount}] result(s)   </div>  
                </div>
            </div>
              <div className="col-xs-7 d-flex align-items-center">
                  <button className="g-tab g-default-tab-padding" onClick={()=>{back()}}>Enquiry List</button>
                  <button className="g-tab-selected g-default-tab-padding" >Selected Enquiry</button>
                <EnquiryBurgerMenu
                    key={"burger-menu-dropdown"}
                    enquiryId={activeEnquiry.id}
                />
          </div></>
          
       }        
   {( FullWidth == true) &&
                <div className="col-xs-5">
                    <div className="enquiries-tabs">
                        <button className="enquiry-refresh-button" title="Refresh" onClick={
                            async () => {
                                if (enabled) {
                                    setEnabled(false);
                                    refreshInterface();
                                    setTimeout(() => {
                                        setEnabled(true);
                                    }, 5000);
                                }
                                else {
                                    showHideLoader("flex");
                                    setTimeout(() => {
                                        showHideLoader("none");
                                    }, 1000);
                                }
                            }
                        } ><BsArrowClockwise onClick={refreshInterface} style={{ float: "left", cursor: "pointer", rotate: "90deg", width: "14px", height: "14px" }} > </BsArrowClockwise></button>
                        <div style={{ float: "left", marginRight: "8px", marginLeft:"4px" }}>Showing [{resultsCount}] result(s)   </div>  
                </div>
            </div>
       }
            {FullWidth == true && <div id="enquiry-drpdown" style={{ position: "absolute" }}>
                <EnquiryBurgerMenu
                    key={"burger-menu-dropdown"}
                    enquiryId={activeEnquiry.id}
                />
            </div>}
</div>
    </>
    
    );
}

export default Query;