import { useContext, useEffect, useRef, useState } from 'react';
import './topBar.scss';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../state/store';
import { iLastTouched } from "../../../interfaces/lastTouched";
import { callApi, iRequest } from '../../../utilities/apiCall';
import { setEnquiriesVisibility } from '../../../state/siteSlice';
import { setEmailVisible } from '../../../state/secondColSlice';
import AvancedSearchDialog from './AdvancedSearchDialog';
import { BsChevronDown, BsSearch } from 'react-icons/bs';
import ConfirmDialog from '../../Generic/ConfirmDialog';
import { setActiveEnquiry } from '../../../state/activeEnquiry';
import Confirm from '../../Generic/ConfirmDialog';
import { GetMailboxPermissions } from '../../../interfaces/Permissions';
import RadioButtonRow from '../../Generic/RadioButtonRow';
import { ActivateWizardModule, saveNewEnquiryId } from '../../../Logic/Enquiries/activateWizard';
import { GetMap, SaveMap, getSessionValue } from '../../../utilities/localStore/calls';
import { Wizard } from '../../../state/workflowSlice';
import { iTenant } from '../../../interfaces/auth';
import { getUnresolvedEnquiriesGlobal } from '../../../Logic/Enquiries/My';
import { setTabVisibility } from '../../../state/tabRow';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { CreateNoteContext } from '../../../contexts/create-note.context';
import { getEnquiryById } from '../../../interfaces/enquiryList';
import { getActiveTenantName } from '../../../Logic/Auth/TokenManager';

function Topbar(props: any) {
    const dispatch = useAppDispatch()
    const UserInfo = useSelector((state: RootState) => state.UserInformation);
    const activeEnquiry = useSelector((state: RootState) => state.activeEnquiry);
    const newRef = useRef<HTMLElement>(null);
    const profileRef = useRef<HTMLButtonElement>(null);
    const permissions = useSelector((state: RootState) => state.permissions);
    const [displayAdvancedSearch, setDisplayAdvancedSearch] = useState(false);
    const [showConfirm, setshowConfirm] = useState(false);
    const [showCreateEnquiry, setshowCreateEnquiry] = useState(false);
    const [newMenuShown, setNewMenuShown] = useState(false);
    const [profileMenuShown, setProfileMenuShown] = useState(false);
    const [selectedMailbox, setselectedMailbox] = useState(0);

    const [hasMultiTenantAccess, setHasMultiTenantAccess] = useState(false);
    const [showSwitchTenantsConfirm, setShowSwitchTenantsConfirm] = useState(false);

    const createNote = useContext(CreateNoteContext);

    useEffect(() => {
        const tenant: iTenant = JSON.parse(getSessionValue("tenant"));
        if (tenant !== null && tenant.accessToMultipleTenants ) {
            setHasMultiTenantAccess(true);
        }
    }, []);

    useOutsideClick(newRef, () => setNewMenuShown(false))
    useOutsideClick(profileRef, () => setProfileMenuShown(false))

    function getPopEnquiry() {
        (document.getElementById("loader") as HTMLElement).style.display = "block";
        let request: iRequest = {
            url: "Enquiry/Pop",
            method: "GET",
            headers: [],
            body: JSON.stringify({ "UserId": UserInfo.userID }),
            responseType: "json"
        }
        callApi(request).then((resp) => {
            if (resp == "No Content") {
                alert("There are no more unassigned enquiries waiting to be processed");
                (document.getElementById("loader") as HTMLElement).style.display = "none";
                return false;
            }

            else if (typeof (resp.status) == "number" && resp.status == 500) {
                alert("An error occured - " + resp.detail);
                (document.getElementById("loader") as HTMLElement).style.display = "none";
                return false;
            }
            try {

                let iLastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
                iLastTouched.MyEnquiries.EnquiryLists.find(x => x.Name == "Unresolved")!.EnquiryId = resp.id;
                iLastTouched.MyEnquiries.ActiveList = "Unresolved";
                iLastTouched.lastActiveListType = "Unresolved";
                sessionStorage.setItem("LastTouched", JSON.stringify(iLastTouched));
                dispatch(setActiveEnquiry(0));
                dispatch(setEnquiriesVisibility());
                dispatch(setEmailVisible(true));

            }
            catch (err) {

                alert(err);
            }


        }).catch((err) => {

            if (err.indexOf("User has unworked enquiries") > 0) {
                setshowConfirm(true);
            }
            else { alert("an error has occured") }
            (document.getElementById("loader") as HTMLElement).style.display = "none";

        });
    }

    const createEnquiry = async () => {
        if (selectedMailbox > 0) {
            const EnquiryId = await callApi({
                url: "Enquiry",
                method: "POST",
                headers: [{ key: "Content-Type", value: "application/json" }],
                body: '{"MailboxID":' + selectedMailbox.toString() + '}',
                responseType: "json"
            });
            saveNewEnquiryId(EnquiryId.enquiryID.toString())
            setTabVisibility(0);
            await getUnresolvedEnquiriesGlobal(UserInfo.userID)
            setshowCreateEnquiry(false);
            new ActivateWizardModule().ActivateWizard(EnquiryId.enquiryID);
        }
    }

    const onSwitchTenant = () => {
        const wizards: Array<Wizard> = JSON.parse(getSessionValue("Workflows"));
        if (wizards !== null && wizards.length > 0) {
            setShowSwitchTenantsConfirm(true);
            return;
        }
       // dispatch(setActiveEnquiry(0));
        sessionStorage.clear();
        window.location.pathname = "/auth/callback";
    };

    return (
        <>

            <div className="topBar topBarTheme">
                <div className="left-area">
                    <nav ref={newRef} className={"g-dropdown-nav" + (newMenuShown ? "-selected" : "")} >
                        <button
                            className="g-nav-button"
                            onClick={() => setNewMenuShown(s => !s)}
                        ><div className='limit-text'>New</div> <BsChevronDown style={{ fontSize: "10px", marginLeft: "10px" }}></BsChevronDown></button>
                        <ul
                        className="g-dropdown-nav-list"
                        onMouseLeave={()=>{setNewMenuShown(false) }}
                        >
                            <li ><button onClick={(e) => {   permissions.AnyMailbox.CreateEnquiry && setshowCreateEnquiry(true) }}  tabIndex={0} 
                            className={!permissions.AnyMailbox.CreateEnquiry ? "sg-dropdownmenu-disabled" : ""} disabled={!permissions.AnyMailbox.CreateEnquiry ? true : false}>
                                Create&nbsp;Enquiry</button></li>
                            <li><button onClick={() => { createNote(activeEnquiry.id) }}
                                className="sg-dropdownmenu">Create&nbsp;Note</button></li>
                        </ul>
                    </nav>
                    {permissions.AnyMailbox.PopEnquiry &&
                        <nav className="g-dropdown-nav" >
                            <button className="g-nav-button" onClick={() => { getPopEnquiry() }} >
                                Pop
                            </button>
                        </nav>
                    }
                    <nav className="g-dropdown-nav" >
                        
                    </nav>
                    {permissions.Tenant.Search && <div className="search-area">
                        <div className="search-icon-container"><BsSearch className="search-icon" /></div>
                        <input type="text" value="Search Enquiries" readOnly={true} onClick={() => setDisplayAdvancedSearch(true)} />
                        {displayAdvancedSearch && <AvancedSearchDialog onClose={() => setDisplayAdvancedSearch(false)} fullWidth={props.FullWidth} />}
                    </div>}
                </div>
                <div className="right-area">
                    <label className='limit-text'>{getActiveTenantName(false)}&nbsp;&nbsp;</label>
                    <nav ref={profileRef} className={"g-dropdown-nav" + (profileMenuShown ? "-selected" : "") + " menu-right"} > 
                        <button
                            className="g-nav-button"
                            onClick={()=>{setProfileMenuShown(s => !s) }}
                        ><div className='limit-text'>{UserInfo.firstname + ' ' + UserInfo.lastname}</div> <BsChevronDown style={{ marginLeft: "10px" }}></BsChevronDown>
                        </button>
                        <ul onMouseLeave={() => setProfileMenuShown(false)} className="g-dropdown-nav-list">
                            {UserInfo.jobTitle !== undefined && <li><label>{UserInfo.jobTitle.replace(/ /g, "\u00a0")}</label></li>}
                            <li><label>{UserInfo.serviceCentre.replace(/ /g, "\u00a0")}</label></li>
                            {hasMultiTenantAccess && <li ><button tabIndex={0} onClick={() => onSwitchTenant()} onBlur={() => { setProfileMenuShown(false) }}>Switch Tenant</button></li>}
                            <li><button className="sg-dropdownmenu-disabled" disabled={true}>My Profile</button></li>
                            <li><button className="sg-dropdownmenu-disabled" disabled={true}>Help</button></li>
                        </ul>
                 
                    </nav>
                </div>
            </div>
            {showConfirm &&
                <ConfirmDialog
                    title=''
                    onConfirm={() => {
                        setshowConfirm(false);
                    }}
                    onCancel={() => {
                        setshowConfirm(false);
                    }}
                >
                    <p>There already exists an unprocessed enquiry in your queue. Please action this enquiry before popping more work.</p>
                    <p> </p>
                </ConfirmDialog>
            }



            {
                showCreateEnquiry &&
                <Confirm
                    onCancel={() => { setshowCreateEnquiry(false) }}
                    onConfirm={() => { createEnquiry() }}
                    title='Link New Enquiry to Mailbox'
                >
                    <span></span>
                    <p>Select one mailbox to attach the new enquiry to from the mailboxes available to you.</p>
                    <div className='linkMailBoxSelector'>
                        {
                            UserInfo.mailboxes
                                .filter(x => GetMailboxPermissions(permissions, x.id).CreateEnquiry)
                                .map((item) => {
                                    return <RadioButtonRow
                                        key={item.id}
                                        disabled={false}
                                        comboKey={item.id.toString()}
                                        onClick={() => { setselectedMailbox(item.id) }}
                                        label={item.name}
                                        value={item.id}
                                        selectedValue={selectedMailbox} />
                                })
                        }

                    </div>
                </Confirm>
            }

            {showSwitchTenantsConfirm &&
            <Confirm title={"Warning"} onConfirm={async () => setShowSwitchTenantsConfirm(false)} messageType={"warn"}>
                <label>
                    Please complete or cancel any open wizard before switching tenants.
                </label>
            </Confirm>
            }
        </>
    )
}

export default Topbar;