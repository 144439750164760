import './sidebar.scss';
import { BarChart2, MessageSquare, Trello, User, ChevronsRight, Search } from 'react-feather';
import { RootState, useAppDispatch } from '../../../state/store'
import { setEnquiriesVisibility, setSearchResultsVisibility } from '../../../state/siteSlice';
import { useSelector } from "react-redux";
import { iLastTouched } from "../../../interfaces/lastTouched";
import { switchFirstTab } from '../../../state/tabRow';
import { setItemEnabled, showHideLoader } from '../../../utilities/loading';
import { AdvancedSearchResults } from '../../../interfaces/AdvancedSearch/AdvancedSearchResults';
import { getSessionValue, saveSessionValue } from '../../../utilities/localStore/calls';
import { getQueuesInfo } from '../../../Logic/Enquiries/Queue';
import { setActiveEnquiry } from '../../../state/activeEnquiry';
import { setShowEnquiry, setShowWizard } from '../../../state/restrictedViewSlice';
import { useState, useEffect } from 'react';
import { resetSort } from '../../../utilities/sort';
import { adjustHamburgerMenu } from '../../../Logic/BurgerMenu';
function Sidebar(props: any) {
    const [expanded, setExpanded] = useState(false);
    const user = useSelector((state: RootState) => state.UserInformation);
    const SiteInformation = useSelector((state: RootState) => state.SiteInformation);
    const permissions = useSelector((state: RootState) => state.permissions);

    const dispatch = useAppDispatch();
    useEffect(() => {
        adjustHamburgerMenu(expanded);
    }, [expanded])
    function showSearchResults() {
        showHideLoader("flex");
        resetSort()
        const searchResults: AdvancedSearchResults = JSON.parse(getSessionValue("advancedSearchResults")!);
        let iLastTouched: iLastTouched = JSON.parse(sessionStorage.getItem("LastTouched")!);
        dispatch(setSearchResultsVisibility());
        dispatch(switchFirstTab("Search Results"));
        dispatch(setShowEnquiry(false));
        if (searchResults !== null && searchResults.Results.length > 0) {
            dispatch(setActiveEnquiry(iLastTouched.SearchResults.EnquiryLists[0].EnquiryId));
            showHideLoader("none");
        } else {
            dispatch(setActiveEnquiry(-1));
            showHideLoader("none");
        }
        iLastTouched.lastActiveListType = "Results";
        saveSessionValue("LastTouched", iLastTouched);
    }
    function showQLists() {
        setItemEnabled("side-nav-link--mailboxes", false)
        showHideLoader("flex");
        resetSort()
        dispatch(setShowEnquiry(false));
        getQueuesInfo().then(() => {
            setItemEnabled("side-nav-link--mailboxes", true)
            showHideLoader("none");
        }).catch((err) => {
            setItemEnabled("side-nav-link--mailboxes", true)
            showHideLoader("none");
            console.error(err)
        });
    }

    async function showMyEnquiryLists() {
        resetSort()
        let LastTouched: iLastTouched = JSON.parse(getSessionValue("LastTouched"));
        LastTouched.lastActiveListType = LastTouched.MyEnquiries.ActiveList;
        saveSessionValue("LastTouched", LastTouched);
        dispatch(setActiveEnquiry(0));
        dispatch(setShowEnquiry(false));
        dispatch(setShowWizard(false));
        dispatch(switchFirstTab("My Enquiries"));
        dispatch(setEnquiriesVisibility());
    }

    function toggleSideNav() {
        setExpanded(current => !current);
    }

    return (
        <div className={"side-nav" + (expanded ? " expanded" : "")}>
            <div className="logo-row">
                <div className="logo-icon">
                    <div className="logo-bg">

                    </div>
                </div>
                <div className="logo-label text-line-1 label">&nbsp;</div>
                <div className="nav-btns">
                    {/*<X className='nav-btn collapse-nav' onClick={collapseSideNav} />
                    <BarChart2 className='nav-btn expand-nav' onClick={expandSideNav} />*/}
                </div>
            </div>
            <div className="side-nav--links">
                {
                    permissions.Tenant.ViewMyEnquiries &&
                    <div className={SiteInformation.enquiriesListVisible ? "side-nav--links--link active" : "side-nav--links--link"}>
                        <div className="side-nav--links--link-wrap" id='side-nav-link--my-enquiries' onClick={showMyEnquiryLists}>
                            <div className="side-nav--links--link--icon">
                                <MessageSquare className="nav-icon" />
                            </div>
                            <div className="side-nav--links--link--label text-line-1 label">My Enquiries</div>
                        </div>
                    </div>
                }
                {
                    permissions.AnyMailbox.ViewEnquiryList &&
                    <div className={SiteInformation.queuesListVisible ? "side-nav--links--link active" : "side-nav--links--link"}>
                        <div className="side-nav--links--link-wrap" id='side-nav-link--mailboxes' onClick={showQLists}>
                            <div className="side-nav--links--link--icon">
                                <Trello className="nav-icon" />
                            </div>
                            <div className="side-nav--links--link--label text-line-1 label">Mailboxes</div>
                        </div>
                    </div>
                }
                {
                    permissions.Tenant.Search &&
                    <div className={SiteInformation.searchResultsVisible ? "side-nav--links--link active" : "side-nav--links--link"}>
                        <div className="side-nav--links--link-wrap" id='side-nav-link--searchResults' onClick={showSearchResults}>
                            <div className="side-nav--links--link--icon">
                                <Search className="nav-icon" />
                            </div>
                            <div className="side-nav--links--link--label text-line-1 label">Search Results</div>
                        </div>
                    </div>
                }
                <div className="side-nav--links--link">
                    <div className="side-nav--links--link-wrap">
                        <div className="side-nav--links--link--icon">
                            <BarChart2 className="nav-icon" />
                        </div>
                        <div className="side-nav--links--link--label text-line-1 label">Reporting</div>
                    </div>
                </div>

                <div className="side-nav--links--link">
                    <div className="side-nav--links--link-wrap">
                        <div className="side-nav--links--link--icon">
                            <User className="nav-icon" />
                        </div>
                        <div className="side-nav--links--link--label text-line-1 label">Contacts</div>
                    </div>
                </div>


            </div>
            <div className="ExpandContractRow" onClick={toggleSideNav}> <ChevronsRight className="ExpandIcon" ></ChevronsRight></div>
            <div className="EMSLogo"> &nbsp; {expanded && <div className="EMS-Version">{process.env.REACT_APP_VERSION}</div>}</div>
        </div>
    );
}

export default Sidebar;


